import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Grid, Input } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { VodafoneCommissionStore } from "./VodafoneCommissionStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import OpenItem from "../../../components/OpenItem";
import { RootStore } from "../../../stores/RootStore";
import { toJS } from "mobx";
import NumberFormat from 'react-number-format';


interface IStores {
    VodafoneCommissionStore: VodafoneCommissionStore
}

interface IVodafoneCommissionPageProps {
    navigate?: NavigateFunction;
    RootStore?:RootStore
}

class VodafoneCommissionPage extends React.Component<IVodafoneCommissionPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { VodafoneCommissionStore: new VodafoneCommissionStore(this.props.RootStore)}
    }

    componentDidMount(): void {
        this.stores.VodafoneCommissionStore.getVodafoneCommissions()
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <div style={{ margin: 20, width: '95%' }}>
                        <Confirm open={this.stores.VodafoneCommissionStore.isItemOpen}	      
                            style={{width: 1100}}                      
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                            onCancel={() => {this.stores.VodafoneCommissionStore.setItemOpenFalse();}}	
                            cancelButton="Mégse"
                            confirmButton={false}								
                            content={
                                <div style={{ padding: 30 }}>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                        <div style={{marginRight: '20px'}}>
                                            <div>
                                                <label>Tarifacsomag</label>
                                            </div>
                                            <Input value={this.stores.VodafoneCommissionStore.selectedTarifaItem?.TariffPackageName} disabled/>
                                        </div>
                                        <div style={{marginRight: '20px'}}>
                                            <div>
                                                <label>[0-5eFt]</label>
                                            </div>
                                            {/* <Input value={this.stores.VodafoneCommissionStore.temporaryDiscount0_5Number}
                                            type="number" onChange={(event, data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(event, data, 1)}}/> */}
                                            <NumberFormat
                                                value={this.stores.VodafoneCommissionStore.temporaryDiscount0_5Number}                                                                                                
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(data, 1)}}                                                
                                                customInput={Input}/>
                                        </div>   
                                        <div style={{marginRight: '20px'}}>
                                            <div>
                                                <label>[5-25eFt]</label>
                                            </div>
                                            {/* <Input value={this.stores.VodafoneCommissionStore.temporaryDiscount5_25Number}
                                            type="number" onChange={(event, data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(event, data, 2)}}/> */}
                                            <NumberFormat
                                                value={this.stores.VodafoneCommissionStore.temporaryDiscount5_25Number}                                                                                                
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(data, 2)}}                                                
                                                customInput={Input}/>
                                        </div>   
                                        <div style={{marginRight: '20px'}}>
                                            <div>
                                                <label>[25-50eFt]</label>
                                            </div>
                                            {/* <Input value={this.stores.VodafoneCommissionStore.temporaryDiscount25_50Number}
                                            type="number" onChange={(event, data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(event, data, 3)}}/> */}
                                            <NumberFormat
                                                value={this.stores.VodafoneCommissionStore.temporaryDiscount25_50Number}                                                                                                
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(data, 3)}}                                                
                                                customInput={Input}/>
                                        </div>   
                                        <div style={{marginRight: '20px'}}>
                                            <div>
                                                <label>[50eFt-]</label>
                                            </div>
                                            {/* <Input value={this.stores.VodafoneCommissionStore.temporaryDiscount50_InfNumber}
                                            type="number" onChange={(event, data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(event, data, 4)}}/> */}
                                            <NumberFormat
                                                value={this.stores.VodafoneCommissionStore.temporaryDiscount50_InfNumber}                                                                                                
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.VodafoneCommissionStore.updateVodafoneCommissionItem(data, 4)}}                                                
                                                customInput={Input}/>
                                        </div>                                                                        
                                    </div>                                    
                                    <div style={{ textAlign: 'left' }}>
                                        <Button color="red" disabled={!this.stores.VodafoneCommissionStore.getVodafoneCommissionItemCanBeSaved} onClick={() => {this.stores.VodafoneCommissionStore.saveSelectedVodafoneCommissionItem()}}>Mentés</Button>
                                    </div>
                                </div>
                            }
                        />
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={10}>
                                    <div style={{marginBottom: 20, width: '91%'}}>
                                        <CustomHeader>One tarifacsomag szaktanácsadó jutalék paraméterezése</CustomHeader>
                                    </div>								
                                    <Grid>                                        
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>       
                                            <div style={{background: 'rgb(248, 248, 248)', maxWidth: '750px', height: '40px', textAlign: 'center', paddingTop: 10, fontWeight: 'bold', fontSize: '16px',
												border: '1px solid rgb(186, 191, 199)', borderBottom: 'none', marginLeft: '242px'}}>One csomag ajánlatban vásárolt készülék árából adott kedvezmény</div>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '992px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={toJS(this.stores.VodafoneCommissionStore.vodafoneCommissionItems)}											
                                                    columnDefs={this.stores.VodafoneCommissionStore.columnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    frameworkComponents={{ btnCellRendererOpenItem: OpenItem }}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>	                                                    																			
                                                </AgGridReact>
                                            </div>                                              
                                            </Grid.Column>                                        
                                        </Grid.Row>       
                                        {/* <Grid.Row centered>
                                            <Button color="red">Mentés</Button>
                                        </Grid.Row>                                                                                           */}
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(VodafoneCommissionPage)));