import { inject, observer } from 'mobx-react';
import React from 'react';
import { OfferStore } from '../pages/offer/OfferStore';
import { Button, Checkbox, Confirm, Dropdown, Grid, Input, Search, Image, Icon } from 'semantic-ui-react';
import { CustomHeader } from './CustomHeader';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import '../index.css'
import { toJS } from 'mobx';

interface IProps {
	OfferStore?: OfferStore;
}

class CustomerData extends React.Component<IProps> {
	addressTypeOptions: Array<any>;

	constructor(props: any) {
		super(props);


		this.addressTypeOptions = [
			{ value: 0, text: 'Utca' },
			{ value: 1, text: 'Út' }
		];
	}
	public render() {
		const { OfferStore } = this.props;

		return (
			<div>
			{/* <Confirm open={OfferStore.clientConsentConfirmIsOpen}
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
					onConfirm={(event) => { OfferStore.confirmClientConsent(event); }}
					onCancel={(event) => { OfferStore.cabcelClientConsent(event); }}
					cancelButton="Mégse"
					confirmButton="Tovább"
					content={
						<div style={{ padding: 30 }}>
							<p style={{textAlign: 'justify'}}>
							Ezúton hozzájárulok, hogy a Media Markt Magyarország Kft. mint adatkezelő az általam vásárolt telefonkészülékhez és az ahhoz igénybe vett szolgáltatáshoz (előfizetéshez) kapcsolódóan – a vonatkozó adatkezelési tájékoztatóban foglaltak szerint – kezelje az általam 
							a szerződéskötéskor megadott személyes adataimat abból a célból, hogy a kapcsolódó szolgáltatás időtartamának lejáratát megelőzően engem a szerződés meghosszabbításával vagy új szerződés kötésével kapcsolatban üzleti ajánlattal keressen meg.
							</p>
							

							<div style={{ textAlign: 'center' }}>
								<Checkbox style={{ marginRight: 20 }} label="Hozzájárul" checked={OfferStore.offer.ClientConsent == true} onClick={() => OfferStore.changeClientConsent(true)}></Checkbox>
								<Checkbox label="Nem járul hozzá" checked={OfferStore.offer.ClientConsent == false} onClick={() => OfferStore.changeClientConsent(false)}></Checkbox>
							</div>
							{OfferStore.clientConsentError && <div style={{color:'red',textAlign:'center',marginTop:'10px'}}>
								Továbblépés előtt kötelező választani!
							</div>}
						</div>
					}
				></Confirm> */}
				<Confirm open={OfferStore.ugyfelHozzajarulasaConfirmIsOpen}
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
					onConfirm={(event) => { OfferStore.confirmUgyfelHozzajarulasa(event); }}
					onCancel={(event) => { OfferStore.cancelUgyfelHozzajarulasa(event); }}
					cancelButton="Mégse"
					confirmButton="Tovább"
					content={
						<div style={{ padding: 30 }}>
							<p>
							A Media Markt Magyarország Kft., a One Magyarország Zrt és a Magyar Bankholding Zrt. együttes elhatározása,
							hogy a Media Markt áruházaiban történő készülékvásárlás esetén lehetőséget biztosítanak arra, hogy Ön a készülékvásárlással
							egyidejűleg, illetve azt követően Kapcsolódó szolgáltatásokat is igénybe tudjon venni.

							</p>
							<p>
							A Media Markt áruházaiban történő készülékvásárlás- és Kapcsolódó szolgáltatás igénylés esetén az Ön személyes adatai a Media
							Markt MMSConnect elnevezésű rendszerében kerülnek rögzítésre, majd innen a kiválasztott szolgáltatást nyújtó adatkezelő részére
							továbbításra, így Önnek nem szükséges több helyszínen, többször megadnia személyes adatait. Mindezekre tekintettel a Media Markt
							és az igénybe vett Kapcsolódó szolgáltatások nyújtói az MMSConnect rendszerben kezelt adatok tekintetében közös adatkezelőknek
							minősülnek. Fontos, hogy személyes adataihoz minden esetben csak azon adatkezelők férnek hozzá, akiknek Kapcsolódó szolgáltatását
							igénybe kívánja venni, és minden adatkezelô csak azokhoz a személyes adataihoz fér hozzá, amelyek az általa nyújtott szolgáltatás
							nyújtásához elengedhetetlenül szükségesek.

							</p>
							<p>
							Az egyes szolgáltatókkal történő szerződéskötés során a Szolgáltatók külön tájékoztatást nyújtanak önnek az MMSConnect rendszeren
							túli adatkezelésről, mely már kifejezetten a Szolgáltatóval fennálló jogviszonyára vonatkozó adatkezelésre tér ki. A teljeskörű tájékoztatást
							a közös adatkezelésről szóló tájékoztatónkban olvashatja, melyet kérésére kinyomtatva azonnal rendelkezésére bocsátunk.

							</p>

							<div style={{ textAlign: 'center' }}>
								<Checkbox style={{ marginRight: 20 }} label="Elfogadja" checked={OfferStore.offer.IsGDPRAccepted == true} onClick={() => OfferStore.changeIsGDRPAccepted(true)}></Checkbox>
								<Checkbox label="Nem fogadja el" checked={OfferStore.offer.IsGDPRAccepted == false} onClick={() => OfferStore.changeIsGDRPAccepted(false)}></Checkbox>
							</div>
						</div>
					}
				></Confirm>
				<Confirm open={OfferStore.showConfirmOfGDPRRejectionPop}
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
					onConfirm={(event) => { OfferStore.confirmGDRPRRejection(event); }}
					onCancel={(event) => { OfferStore.cancelGDPRRejection(event); }}
					cancelButton="Mégse"
					confirmButton="Folytatás"
					content={
						<div style={{ padding: 30 }}>
							<p>
								A nyilatkozat el nem fogadásával az ajánlat nem köthető meg.
							</p>
							<p>
								Biztosan folytatja?
							</p>
						</div>
					}
				></Confirm>
				<Confirm open={OfferStore.showCustomerDataPopUp}
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={(event) => { OfferStore.cancelCustomerDataPopup(event); }}
					cancelButton="OK"
					confirmButton={false}
					content={
						<div style={{ padding: 30 }}>
							<div style={{ textAlign: 'center' }}>
								<div style={{ lineHeight: '20px', marginBottom: '5px' }}>A személyes adatok rögzítéséhez az alábbi, <span >ÉRVÉNYES</span> igazolványok <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>valamelyike szükséges,</span> melyek <span style={{ fontWeight: 'bold', color: 'red' }}>CSAK A HELYSZÍNEN BEMUTATVA</span> fogadható el (tehát fényképen, másolatban NEM):</div>
								<div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Személyi igazolvány / új típusú jogosítvány / útlevél</div>
								<div style={{ fontWeight: 'bold', color: 'red', marginBottom: '5px' }}>ÉS</div>
								<div style={{ fontWeight: 'bold', marginBottom: '5px' }}>lakcímkártya (állandó bejelentett magyarországi lakcímmel)</div>
							</div>
						</div>
					}
				></Confirm>
				<Grid style={{ padding: 0 }}>
					<Grid.Row>
						<Grid.Column width={7} floated="left">
							<div style={{ marginBottom: 20 }}>
								<CustomHeader>Személyes adatok és okmányadatok</CustomHeader>
							</div>
							<Grid>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Titulus</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Dropdown
												selection
												fluid
												placeholder='Kérem, válasszon...'
												options={this.props.OfferStore.customerTitleOptions}
												disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.CustomerTitle}
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('CustomerTitle', data.value) }}
											/>
											{OfferStore.offer.CustomerTitle > 0 && (
												<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getCustomerTitleForClipboard() }}></Icon>
											)}
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Állampolgárság országa</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid readOnly value={this.props.OfferStore.offer.CountryOfNationality} disabled={true} className="copyToClipboardDiv"></Input>
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.CountryOfNationality) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Név / Vezetéknév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.LastName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('LastName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.LastName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.LastName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Név / Keresztnév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.FirstName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('FirstName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.FirstName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.FirstName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Anyja neve / Vezetéknév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.MothersLastName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('MothersLastName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.MothersLastName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.MothersLastName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Anyja neve / Keresztnév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.MothersFirstName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('MothersFirstName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.MothersFirstName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.MothersFirstName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Születési hely</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.BirthPlace} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('BirthPlace', data.value) }} error={!!this.props.OfferStore.getValidationErrors.BirthPlace} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.BirthPlace) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Születési dátum</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											{OfferStore.showDatePicker && (
												<SemanticDatepicker disabled={OfferStore.getIsDisabled}
													value={toJS(OfferStore.getBirthDateValue)}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('BirthDate', data.value) }} error={!!this.props.OfferStore.getValidationErrors.BirthDate || !!this.props.OfferStore.getValidationErrors.BirthDateUnderEighteen} />
											)}
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getCustomerdataBirthDateForClipboard() }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Születési név / Vezetéknév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.BirthLastName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('BirthLastName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.BirthLastName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.BirthLastName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Születési név / Keresztnév</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.BirthFirstName} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('BirthFirstName', data.value) }} error={!!this.props.OfferStore.getValidationErrors.BirthFirstName} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.BirthFirstName) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Neme - Szolgáltatói szerződéshez</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Dropdown
											selection
											fluid
											placeholder='Kérem, válasszon...'
											options={this.props.OfferStore.genderForVodafoneOptions}
											disabled={OfferStore.getIsDisabled}
											value={this.props.OfferStore.offer.GenderForVodafone}
											onChange={(event, data) => { this.props.OfferStore.updateOfferItem('GenderForVodafone', data.value) }} error={!!this.props.OfferStore.getValidationErrors.GenderForVodafone}
										/>
									</Grid.Column>
									{!OfferStore.RootStore.currentUser.VodafoneBO && (
										<Grid.Column width={8}>
											<div>
												<label>Neme</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Dropdown
												selection
												fluid
												placeholder='Kérem, válasszon...'
												options={this.props.OfferStore.genderForBBOptions}
												disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.Gender}
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('Gender', data.value) }} error={!!this.props.OfferStore.getValidationErrors.Gender}
											/>
										</Grid.Column>
									)}
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Azonosító okmány száma</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.IdentityNumber} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('IdentityNumber', data.value) }} error={!!this.props.OfferStore.getValidationErrors.IdentityNumber} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.IdentityNumber) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Azonosító okmány típusa</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Dropdown
											selection
											fluid
											placeholder='Kérem, válasszon...'
											options={this.props.OfferStore.identityTypeOptions}
											disabled={OfferStore.getIsDisabled}
											value={this.props.OfferStore.offer.IdentityType}
											onChange={(event, data) => { this.props.OfferStore.updateOfferItem('IdentityType', data.value) }} error={!!this.props.OfferStore.getValidationErrors.IdentityType}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Lakcímet igazoló hatósági igazolvány száma</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.AddressCardNumber} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('AddressCardNumber', data.value) }} error={!!this.props.OfferStore.getValidationErrors.AddressCardNumber || !!this.props.OfferStore.getValidationErrors.AddressCardNumberCharacterLength} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.AddressCardNumber) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Meglévő One ügyfél</label>
										</div>
										<Checkbox
											style={{ paddingLeft: 8, paddingTop: 10 }}
											disabled={OfferStore.getIsDisabled}
											checked={this.props.OfferStore.offer.NeedVodafoneCustomerSecurityCode === 1}
											value={this.props.OfferStore.offer.NeedVodafoneCustomerSecurityCode}
											onChange={(event, data) => { this.props.OfferStore.updateOfferItem('NeedVodafoneCustomerSecurityCode', data.value) }}
										/>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>One ügyfélbiztonsági kód</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.VodafoneCustomerSecurityCode} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('VodafoneCustomerSecurityCode', data.value) }} error={!!this.props.OfferStore.getValidationErrors.VodafoneCustomerSecurityCode} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.VodafoneCustomerSecurityCode.toString()) }}></Icon>
										</Grid.Row>
									</Grid.Column>

								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<div style={{ marginBottom: 20 }}>
											<CustomHeader>Elérhetőségi adatok</CustomHeader>
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										<Grid.Column width={4} style={{ marginBottom: 15 }}>
											<div>
												<label>Mobiltelefonszám</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>

											</div>
											<Grid.Row style={{ display: 'flex', }}>
												<Input
													fluid
													type="number"
													disabled={OfferStore.getIsDisabled}
													value={this.props.OfferStore.offer.MobileNumber} className="copyToClipboardDiv"
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('MobileNumber', data.value) }}
													error={(OfferStore.offer.MobileNumber && (OfferStore.offer.MobileNumber.length != 7)) && OfferStore.isSaved}
													label={
														<Dropdown
															// defaultValue={20}														
															options={this.props.OfferStore.mobileNumberPrefixOptions}
															labelposition="left"
															disabled={OfferStore.getIsDisabled}
															value={this.props.OfferStore.offer.MobileNumberPrefix}
															onChange={(event, data) => { this.props.OfferStore.updateOfferItem('MobileNumberPrefix', data.value) }}
														></Dropdown>
													}
												/>
												<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getCustomerDataMobileNumberForClipborad() }}></Icon>
											</Grid.Row>
										</Grid.Column>
										<Grid.Column width={4} style={{ marginBottom: 15 }}>
											<Button primary onClick={() => this.props.OfferStore.navigateToGDPR()}>GDPR-nyilatkozat megtekintése</Button>
										</Grid.Column>
									</Grid.Column>
									<Grid.Column width={8}>
										<Grid.Column width={4} style={{ marginBottom: 15 }}>
											<div>
												<label>E-mail cím</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Grid.Row style={{ display: 'flex', }}>
												<Input fluid disabled={OfferStore.getIsDisabled} maxLength='40'
													value={this.props.OfferStore.offer.Email} className="copyToClipboardDiv"
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('Email', data.value) }} error={(!!this.props.OfferStore.getValidationErrors.Email || (this.props.OfferStore.offer.Email?.indexOf('@') === -1)) && this.props.OfferStore.isSaved} />
												<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.Email) }}></Icon>
											</Grid.Row>
										</Grid.Column>
										{/* <div style={{ marginBottom: 20 }}>
											<CustomHeader>SIM- és készülék adatok</CustomHeader>
										</div>
										<Grid.Column width={4} style={{ marginBottom: 15 }}>
											<div>
												<label>SIM-kártya száma</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input type="number" fluid disabled={!OfferStore.offer.EditMode}
												value={this.props.OfferStore.offer.SIMNumber}
												onChange={(event, data) => {this.props.OfferStore.updateOfferItem('SIMNumber', data.value)}}/>
										</Grid.Column>
										<Grid.Column width={4} style={{ marginBottom: 15 }}>
											<div>
												<label>Készülék IMEI-száma</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input type="number" fluid disabled={!OfferStore.offer.EditMode}
												value={this.props.OfferStore.offer.IMEINumber}
												onChange={(event, data) => {this.props.OfferStore.updateOfferItem('IMEINumber', data.value)}}/>
										</Grid.Column> */}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column width={8} floated="right">
							<div style={{ marginBottom: 20 }}>
								<CustomHeader>Címadatok</CustomHeader>
							</div>
							<Grid>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Irányítószám</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Search placeholder='Keresés...' fluid className={(!!this.props.OfferStore.getValidationErrors.PermanentAddressZipCode || !!this.props.OfferStore.getValidationErrors.PermanentAddressZipCodeFourCharacterLength || !!this.props.OfferStore.getValidationErrors.PermanentAddressZipCodeNotValid) ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.props.OfferStore.updatePermanentAddressCity(data.result?.item) }}
												onSearchChange={(event, data) => {
													// this.props.OfferStore.selectedItem.ScreenProtectorId=null;												
													this.props.OfferStore.onAddressZipCodeChangeQueryText(event, data)
												}}
												results={this.props.OfferStore.settlementByQueryTextDDL} disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.settlementQueryText || this.props.OfferStore.offer.PermanentAddressZipCode?.toString()}
											/>
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressZipCode.toString()) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Város</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.PermanentAddressCity} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressCity', data.value) }} error={!!this.props.OfferStore.getValidationErrors.PermanentAddressCity} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressCity) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Közterület neve</label>
											{this.props.OfferStore.offer.IsHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											{this.props.OfferStore.hasStreetNamesPerma && (
												<React.Fragment>
													<Dropdown
														placeholder='Kérem, válasszon...'
														selection
														fluid
														search
														options={this.props.OfferStore.permanentAddressTypeOptions}
														disabled={OfferStore.getIsDisabled}
														value={this.props.OfferStore.offer.PermanentAddressName}
														onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressName', data.value, true) }} error={this.props.OfferStore.offer.IsHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.PermanentAddressName}
													/>
													{ !OfferStore.getIsDisabled && <Icon onClick={() => { OfferStore.setHasStreetNamesPermaFalse() }} color='green' name='edit' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }}></Icon>}
												</React.Fragment>
											)}
											{!this.props.OfferStore.hasStreetNamesPerma && (
												<Input fluid disabled={OfferStore.getIsDisabled} className="copyToClipboardDiv"
													value={this.props.OfferStore.offer.PermanentAddressName}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressName', data.value) }} error={this.props.OfferStore.offer.IsHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.PermanentAddressName} />
											)}
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getPermanentAddressStreetNameForClipboard() }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Közterület jellege</label>
											{this.props.OfferStore.offer.IsHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											{!this.props.OfferStore.hasStreetNamesPerma && (
												<Dropdown
													placeholder='Kérem, válasszon...'
													selection
													fluid
													options={this.props.OfferStore.permanentDefaultAddressTypeOptions}
													disabled={OfferStore.getIsDisabled}
													value={this.props.OfferStore.offer.PermanentAddressType}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressType', data.value) }} error={this.props.OfferStore.offer.IsHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.PermanentAddressType}
												/>
											)}
											{this.props.OfferStore.hasStreetNamesPerma && (
												<Input fluid disabled={OfferStore.getIsDisabled} className="copyToClipboardDiv"
													value={this.props.OfferStore.offer.PermanentAddressType}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressType', data.value) }} error={this.props.OfferStore.offer.IsHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.PermanentAddressType} />
											)}
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getPermanentAddressTypeForClipboard() }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Házszám</label>
											{this.props.OfferStore.offer.IsHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.PermanentAddressHouseNumber} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressHouseNumber', data.value) }} error={this.props.OfferStore.offer.IsHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.PermanentAddressHouseNumber} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressHouseNumber) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Épület</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.PermanentAddressBuilding} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressBuilding', data.value) }} maxLength="2" />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressBuilding) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Lépcsőház</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.PermanentAddressStaircase} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressStaircase', data.value) }} maxLength="2" />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressStaircase) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Emelet</label>
										</div>
										{/* <Input type="number" fluid disabled={!OfferStore.offer.EditMode}
											value={this.props.OfferStore.offer.PermanentAddressStorey}
											onChange={(event, data) => {this.props.OfferStore.updateOfferItem('PermanentAddressStorey', data.value)}}/> */}
										<Grid.Row style={{ display: 'flex', }}>
											<div style={{ display: 'flex', width: '100%' }} id="teszt">
												<Dropdown
													placeholder='Kérem, válasszon...'
													selection
													fluid
													options={this.props.OfferStore.addressStoreyOptions}
													disabled={OfferStore.getIsDisabled}
													value={this.props.OfferStore.offer.PermanentAddressStorey}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressStorey', data.value) }}
												/>
												<div>
													<Button color='grey' icon='delete' onClick={() => { OfferStore.updateOfferItem('PermanentAddressStorey', null) }}></Button>
												</div>
												<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getPermanentAddressStoreyForClipboard() }}></Icon>

											</div>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Állandó lakcím / Ajtó</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.PermanentAddressDoor} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('PermanentAddressDoor', data.value) }} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.PermanentAddressDoor) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={1}>
										<div>
											<label>HRSZ</label>
										</div>
										<Checkbox
											style={{ paddingLeft: 8, paddingTop: 10 }}
											disabled={OfferStore.getIsDisabled}
											checked={this.props.OfferStore.offer.IsHRSZ === 1}
											value={this.props.OfferStore.offer.IsHRSZ}
											onChange={(event, data) => { this.props.OfferStore.updateOfferItem('IsHRSZ', data.value) }}
										/>
									</Grid.Column>
									<Grid.Column width={7}>
										<div>
											<label>Állandó lakcím helyrajzi szám</label>
											{this.props.OfferStore.offer.IsHRSZ === 1 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={this.props.OfferStore.offer.IsHRSZ === 0 || OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.HRSZ} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('HRSZ', data.value) }} error={this.props.OfferStore.offer.IsHRSZ === 0 ? false : !!this.props.OfferStore.getValidationErrors.HRSZ} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText("HELYRAJZI SZÁM " + this.props.OfferStore.offer.HRSZ) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ paddingTop: 0 }}>
									<Grid.Column width={8}>
										<div style={{ paddingTop: 8 }}>
											<label>Állandó lakcím összesítő</label>
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										{/* {
											this.props.OfferStore.getPermanentAddressSum() ?
												this.props.OfferStore.getPermanentAddressSum()
												
										}
										<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => {
											this.props.OfferStore.offer.IsTemporaryAddressSameAsPermanent ?


												navigator.clipboard.writeText(this.props.OfferStore.getPermanentAddressSum())
												:
												navigator.clipboard.writeText(this.props.OfferStore.getTemporarryAddressSum())
										}}></Icon> */}
										{
											this.props.OfferStore.getPermanentAddressSum() &&
											<span>
												{this.props.OfferStore.getPermanentAddressSum()}
												< Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.getPermanentAddressSum()) }}></Icon>
											</span>
										}


									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ paddingTop: 0 }}>
									<Grid.Column width={8}>
										<div style={{ paddingTop: 8 }}>
											<label>Tartózkodási cím megegyezik az állandó lakcímmel?</label>
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										<Checkbox
											style={{ paddingLeft: 8, paddingTop: 0 }}
											label="Igen"
											disabled={!OfferStore.offer.EditMode}
											checked={OfferStore.offer.IsTemporaryAddressSameAsPermanent}
											onClick={() => OfferStore.setTemporaryDetailsLikePermanent(true)}
										/>
										<Checkbox
											style={{ paddingLeft: 8, paddingTop: 10 }}
											label="Nem"
											disabled={!OfferStore.offer.EditMode}
											checked={!OfferStore.offer.IsTemporaryAddressSameAsPermanent}
											onClick={() => OfferStore.setTemporaryDetailsLikePermanent(false)}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ paddingTop: 4 }}>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Irányítószám</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										{/* <Input type="number" fluid disabled={!OfferStore.offer.EditMode}
											value={this.props.OfferStore.offer.TemporaryAddressZipCode}
											onChange={(event, data) => {this.props.OfferStore.updateOfferItem('TemporaryAddressZipCode', data.value)}}/> */}
										<Grid.Row style={{ display: 'flex', }}>
											<Search placeholder='Keresés...' fluid className={(!!this.props.OfferStore.getValidationErrors.TemporaryAddressZipCode || !!this.props.OfferStore.getValidationErrors.TemporaryAddressZipCodeFourCharacterLength || !!this.props.OfferStore.getValidationErrors.TemporaryAddressZipCodeNotValid) ? "searchError" : "search"}
												onResultSelect={(e, data) => { this.props.OfferStore.updateTemporaryAddressCity(data.result?.item) }}
												onSearchChange={(event, data) => {
													this.props.OfferStore.onTemporaryAddressZipCodeChangeQueryText(event, data)
												}}
												results={this.props.OfferStore.temporarySettlementByQueryTextDDL} disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.temporarySettlementQueryText || this.props.OfferStore.offer.TemporaryAddressZipCode?.toString()}
											/>
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressZipCode.toString()) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Város</label>
											<span style={{ color: 'red', marginLeft: 2 }}>*</span>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressCity} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressCity', data.value) }} error={!!this.props.OfferStore.getValidationErrors.TemporaryAddressCity} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressCity) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Közterület neve</label>
											{this.props.OfferStore.offer.IsTemporaryHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											{this.props.OfferStore.hasStreetNamesTemporary && (
												<React.Fragment>
													<Dropdown
														placeholder='Kérem, válasszon...'
														selection
														fluid
														search
														options={this.props.OfferStore.temporaryAddressTypeOptions}
														disabled={OfferStore.getIsDisabled}
														value={this.props.OfferStore.offer.TemporaryAddressName}
														onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressName', data.value, true) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryAddressName}
													/>
													{!OfferStore.getIsDisabled && <Icon onClick={() => { OfferStore.setHasStreetNamesTemporaryFalse() }} color='green' name='edit' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }}></Icon>}
												</React.Fragment>
											)}
											{!this.props.OfferStore.hasStreetNamesTemporary && (
												<Input fluid disabled={OfferStore.getIsDisabled} className="copyToClipboardDiv"
													value={this.props.OfferStore.offer.TemporaryAddressName}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressName', data.value) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryAddressName} />
											)}
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getTemporaryAddressStreetNameForClipboard() }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Közterület jellege</label>
											{this.props.OfferStore.offer.IsTemporaryHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											{!this.props.OfferStore.hasStreetNamesTemporary && (
												<Dropdown
													placeholder='Kérem, válasszon...'
													selection
													fluid
													options={this.props.OfferStore.temporaryDefaultAddressTypeOptions}
													disabled={OfferStore.getIsDisabled}
													value={this.props.OfferStore.offer.TemporaryAddressType}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressType', data.value) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryAddressType}
												/>
											)}
											{this.props.OfferStore.hasStreetNamesTemporary && (
												<Input fluid disabled={OfferStore.getIsDisabled} className="copyToClipboardDiv"
													value={this.props.OfferStore.offer.TemporaryAddressType}
													onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressType', data.value) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryAddressType} />
											)}
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getTemporaryAddressTypeForClipboard() }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Házszám</label>
											{this.props.OfferStore.offer.IsTemporaryHRSZ === 0 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressHouseNumber} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressHouseNumber', data.value) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 1 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryAddressHouseNumber} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressHouseNumber) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Épület</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressBuilding} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressBuilding', data.value) }} maxLength="2" />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressBuilding) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Lépcsőház</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressStaircase} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressStaircase', data.value) }} maxLength="2" />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressStaircase) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Emelet</label>
										</div>
										{/* <Input type="number" fluid disabled={!OfferStore.offer.EditMode}
											value={this.props.OfferStore.offer.TemporaryAddressStorey}
											onChange={(event, data) => {this.props.OfferStore.updateOfferItem('TemporaryAddressStorey', data.value)}}/>		 */}
										<Grid.Row style={{ display: 'flex', }}>
											<div style={{ display: 'flex', width: '100%' }} id="teszt">	<Dropdown
												placeholder='Kérem, válasszon...'
												selection
												fluid
												options={this.props.OfferStore.addressStoreyOptions}
												disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressStorey}
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressStorey', data.value) }}
											/>
												<div>
													<Button color='grey' icon='delete' onClick={() => { OfferStore.updateOfferItem('TemporaryAddressStorey', null) }}></Button>
												</div>
												<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { OfferStore.getTemporaryAddressStoreyForClipboard() }}></Icon>
											</div>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<div>
											<label>Tartózkodási lakcím / Ajtó</label>
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryAddressDoor} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryAddressDoor', data.value) }} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(this.props.OfferStore.offer.TemporaryAddressDoor) }}></Icon>
										</Grid.Row>
									</Grid.Column>
									<Grid.Column width={1}>
										<div>
											<label>HRSZ</label>
										</div>
										<Checkbox
											style={{ paddingLeft: 8, paddingTop: 10 }}
											disabled={OfferStore.getIsDisabled}
											checked={this.props.OfferStore.offer.IsTemporaryHRSZ === 1}
											value={this.props.OfferStore.offer.IsTemporaryHRSZ}
											onChange={(event, data) => { this.props.OfferStore.updateOfferItem('IsTemporaryHRSZ', data.value) }}
										/>
									</Grid.Column>
									<Grid.Column width={7}>
										<div>
											<label>Tartózkódási lakcím helyrajzi szám</label>
											{this.props.OfferStore.offer.IsTemporaryHRSZ === 1 && (
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											)}
										</div>
										<Grid.Row style={{ display: 'flex', }}>
											<Input fluid disabled={this.props.OfferStore.offer.IsTemporaryHRSZ === 0 || OfferStore.getIsDisabled}
												value={this.props.OfferStore.offer.TemporaryHRSZ} className="copyToClipboardDiv"
												onChange={(event, data) => { this.props.OfferStore.updateOfferItem('TemporaryHRSZ', data.value) }} error={this.props.OfferStore.offer.IsTemporaryHRSZ === 0 ? false : !!this.props.OfferStore.getValidationErrors.TemporaryHRSZ} />
											<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText("HELYRAJZI SZÁM " + this.props.OfferStore.offer.TemporaryHRSZ) }}></Icon>
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ paddingTop: 0 }}>
									<Grid.Column width={8}>
										<div style={{ paddingTop: 8 }}>
											<label>Tartózkódási lakcím összesítő</label>
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										{
											this.props.OfferStore.offer.IsTemporaryAddressSameAsPermanent ?
												this.props.OfferStore.getPermanentAddressSum()
												: this.props.OfferStore.getTemporarryAddressSum()
										}
									{	(this.props.OfferStore.getPermanentAddressSum() || this.props.OfferStore.getTemporarryAddressSum()) && 
										<Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => {
											this.props.OfferStore.offer.IsTemporaryAddressSameAsPermanent ?


												navigator.clipboard.writeText(this.props.OfferStore.getPermanentAddressSum())
												:
												navigator.clipboard.writeText(this.props.OfferStore.getTemporarryAddressSum())
										}}></Icon>}

									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div >
		);
	}
}

export default inject('OfferStore')(observer(CustomerData));
