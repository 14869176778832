import { inject, observer } from 'mobx-react';
import React from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import logo from '../assets/logo.png';
import withLocation from '../hocs/withLocation';
import withNavigation from '../hocs/withNavigation';
import { RootStore } from '../stores/RootStore';

export const CustomMenuItem = (props: any) => (
	<Menu.Item>
		<div style={{ padding: '0px 8px' }}>
			<div style={{ marginBottom: 6 }}>
				<b>{props.title}</b>
			</div>
			<span>{props.children}</span>
		</div>
	</Menu.Item>
);

interface ILayoutProps {
	RootStore?: RootStore;
	navigate?: NavigateFunction;
	location?: Location;
}


class LayoutMenu extends React.Component<ILayoutProps> {

	public render() {
		const { RootStore } = this.props;

		const isInOfferPage = this.props.location.pathname.indexOf('/offer') > -1;

		return (
			<Menu className="menu">
				<Menu.Item className="logo-menu-item"
					onClick={() => { this.props.navigate('/home'); }}>
					<div>
						<Image src={logo} size="small" />
					</div>
				</Menu.Item>
				<Menu.Item
					onClick={() => { this.props.navigate('/home'); }}>
					<Icon name="home" />
					Főoldal
				</Menu.Item>
				{!isInOfferPage || RootStore.showFullMenu ? (
					<React.Fragment>
						{isInOfferPage && (
							<Menu.Item
								active
								onClick={() => { RootStore.setFullMenu(!RootStore.showFullMenu); }}>
								<Icon name="angle left" size="large" className="no-margin" />
							</Menu.Item>
						)}
						{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.IsConsultant || this.props.RootStore.currentUser.IsSeniorConsultant || this.props.RootStore.currentUser.StoreAdmin) && !this.props.RootStore.currentUser.IsNewOfferDisabledForSeniorOrConsultant &&
							<Menu.Item
								onClick={() => { this.props.navigate('/offer/0'); }}>
								Új ajánlat
							</Menu.Item>
						}
						{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.HoldingGlobalViewer || this.props.RootStore.currentUser.StoreAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.IsSeniorConsultant || this.props.RootStore.currentUser.IsConsultant) &&
							<Menu.Item onClick={() => { this.props.navigate('/riport') }}>Riportok</Menu.Item>
						}
						{/* <Menu.Item onClick={() => { this.props.navigate('/vodafonebo') }}>Vodafone BO</Menu.Item>
						<Menu.Item onClick={() => { this.props.navigate('/creditmanager') }}>Hitelügyintéző</Menu.Item>
						<Menu.Item onClick={() => { this.props.navigate('/onlinepickup') }}>Online pickup</Menu.Item> */}
						{ !(this.props.RootStore.currentUser.VodafoneBO || this.props.RootStore.currentUser.VodafoneSupervisor) && <Menu.Item
							onClick={() => { this.props.RootStore.getUserGuide() }}>
							Súgó
						</Menu.Item>}
						{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.StoreAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.HoldingGlobalViewer) && (
							<Dropdown item text="Adminisztráció">
								<Dropdown.Menu>
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Garancia+ csomagajánlatok rögzítése" onClick={() => { this.props.navigate('/administration/warranty-plus'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Gyári garancia paraméterezése" onClick={() => { this.props.navigate('/administration/manufacturer-warranty'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Áruházankénti jutalék beállítása" onClick={() => { this.props.navigate('/administration/store-commission'); }} />}
									{

										(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.StoreAdmin) &&
										<Dropdown.Item text="Felhasználók kezelése" onClick={() => { this.props.navigate('/administration/usermanagement'); }} />
									}
									<Dropdown.Item text="Áruházi adatok adminisztrációs felülete" onClick={() => { this.props.navigate('/administration/store-data'); }} />
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Tarifacsomagok jutalékosztályának beállítására szolgáló paraméterező felület" onClick={() => { this.props.navigate('/administration/rate-commission'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Tarifacsomag-portfólió adminisztrációs felülete" onClick={() => { this.props.navigate('/administration/rate-portfolio'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Tarifacsomag- és készülékkedvezmények paraméterezése" onClick={() => { this.props.navigate('/administration/rate-device-discount'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Szolgáltatói tarifacsomag szaktanácsadó jutalék paraméterezése" onClick={() => { this.props.navigate('/administration/vodafone-commission'); }} />}
									{/* <Dropdown.Item text="Generálandó vagy megjelenítendő dokumentumok" onClick={() => { this.props.navigate('/administration/documents'); }} /> */}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Címadatbázis betöltő felülete" onClick={() => { this.props.navigate('/administration/address-loader'); }} />}
									{
										(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.StoreAdmin) &&
										<Dropdown.Item text="Szerepkörök" onClick={() => { this.props.navigate('/administration/role'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Felhasználói kézikönyv (Súgó)" onClick={() => { this.props.navigate('/administration/user-guide'); }} />}
									{this.props.RootStore.currentUser.HoldingAdmin && <Dropdown.Item text="Checklist banner feltöltése" onClick={() => { this.props.navigate('/administration/banner'); }} />}
									{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.StoreAdmin) &&
										<Dropdown.Item text=" E-mail értesítések kezelése" onClick={() => { this.props.navigate('/administration/emailmanagement'); }} />}
									{(this.props.RootStore.currentUser.HoldingAdmin) &&
										<Dropdown.Item text="Tarifacsomagok pontértéke" onClick={() => { this.props.navigate('/administration/tariffpackagescore'); }} />}
										{/* {(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.HoldingGlobalViewer) &&
										<Dropdown.Item text="Ügyfélmegkeresési adatok lekérdezése" onClick={() => { this.props.navigate('/administration/clientreport'); }} />} */}
									{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor) &&
										<Dropdown.Item text="Tájékoztatási kötelezettség rögzítése" onClick={() => { this.props.navigate('/administration/information-obligation'); }} />}
									{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.HoldingGlobalViewer) &&
										<Dropdown.Item text="Képzési modul adminisztrációja" onClick={() => { this.props.navigate('/administration/training-module'); }} />}
									{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.StoreAdmin || this.props.RootStore.currentUser.VodafoneSupervisor || this.props.RootStore.currentUser.HoldingGlobalViewer) &&
										<Dropdown.Item text="Továbbképzés és jóváhagyás adminisztráció" onClick={() => { this.props.navigate('/administration/serviceprovider-permissionmanagement'); }} />}
								</Dropdown.Menu>
							</Dropdown>
						)}
						{/* <Menu.Item onClick={() => { RootStore.setIsInfoVideoLogin() }}>Infovideo login</Menu.Item>			 */}
						{(this.props.RootStore.currentUser.IsAnyNotCompletedModuleInProgress) &&
							<Menu.Item onClick={() => { this.props.navigate('/module-assignment') }}>Továbbképzés</Menu.Item>
						}
					</React.Fragment>
				) : (
					<React.Fragment>
						<Menu.Item
							active
							onClick={() => { RootStore.setFullMenu(!RootStore.showFullMenu); }}>
							<Icon name="bars" size="large" className="no-margin" />
						</Menu.Item>
					</React.Fragment>
				)}

				<div id="layout-top-menu-left"></div>

				<Menu.Item position="right">
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ paddingRight: 6, marginRight: 6, borderRight: '1px solid rgba(34, 36, 38, 0.1)' }}>
							{/* <div style={{ textAlign: 'center', marginBottom: '8px' }}>
								{window.location.href.indexOf('/offer') == -1 && <Dropdown

									selection
									value={this.props.RootStore.selectedStore}
									options={this.props.RootStore.stores}
									disabled={this.props.RootStore.getDisabledStoreDDL()}
									onChange={(event, data) => this.props.RootStore.onchangeSelectedStore('IsLoanDemand', data.value)}
								/>}


							</div> */}
							<div id="layout-top-menu-right">

							</div>
						</div>

						<div style={{}}>
							{window.location.href.indexOf('/offer') > -1 &&
								<span style={{ marginRight: '10px' }}>
									{this.props.RootStore.storeName}
								</span>

							}
							<Dropdown
								simple
								direction="left"
								icon=""
								onChange={(event, data) => {
									if (data.value == "loguot") {
										this.props.RootStore.logOut();
									}
									if (data.value == "changePassword") {
										this.props.RootStore.navigateToPasswordChange();
									}
								}}
								trigger={
									<span>
										<Icon name="user" size="large" />
										<span>{this.props.RootStore.currentUser.Name}</span>
										{!this.props.RootStore.currentUser.IsGlobal &&
											<span style={{ fontWeight: 'bold' }}> {this.props.RootStore.currentUser.StoreCode}</span>}
									</span>
								}
								options={[
									{
										value: 'user',
										text: (
											<span>
												Bejelentkezve mint: <strong>{this.props.RootStore.currentUser.Name}</strong>
											</span>
										),
										disabled: true
									},
									{ value: 'loguot', text: 'Kijelentkezés' },
									{ value: 'changePassword', text: 'Jelszó megváltoztatása' }
								]}
							/>
						</div>
					</div>
				</Menu.Item>
			</Menu>
		);
	}
}

export default withLocation(withNavigation(inject('RootStore')(observer(LayoutMenu))));
