import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "../node_modules/semantic-ui-css/semantic.css";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry, SetFilterModule } from "@ag-grid-enterprise/all-modules";
import { LicenseManager } from "@ag-grid-enterprise/core";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';


LicenseManager.setLicenseKey(

  "CompanyName=ShareLine Informatikai Szolgltat Korltolt Felelssg Tr,LicensedGroup=Shareline,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-024512,ExpiryDate=5_March_2023_[v2]_MTY3Nzk3NDQwMDAwMA==ebc757d4d7be09c71b2e72cdb2c2eb9e"

);

ModuleRegistry.registerModules([ClientSideRowModelModule, SetFilterModule, InfiniteRowModelModule]);

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
// window.onbeforeunload = async (event) => {
//   var message = 'Important: Please click on \'Save\' button to leave this page.';
//   if (performance.navigation.type == 1) {

//   } else {
//     if (typeof event == 'undefined') {
//       event = window.event;
//     }
//     if (event) {
//       event.returnValue = message;
//     }
//     let debug = navigator.sendBeacon(`/api/user/logOut`);
//     if (debug)
//       return message;
//   }
// };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
