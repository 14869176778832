import { action, computed, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import React from "react";
import { DropdownProps, TextAreaProps } from "semantic-ui-react";
import { Offer } from "../../models/offer";
import { CustomerDataStore } from "./CustomerDataStore";
import { OfferPackage } from "../../models/offerPackage";
import { OfferService } from "../../services/offer.service"
import { RootStore } from "../../stores/RootStore";
import { Device } from "../../models/device";
import { AdminService } from "../../services/admin.service";
import { TariffPackage } from "../../models/tariffPackage";
import { WarrantyPlusPackage } from "../../models/warrantyPlusPackage";
import { Settlement } from "../../models/settlement";
import { AccessoriesOfPackage } from "../../models/accessoriesOfPackage";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { ManufacturerWarranty } from "../../models/manufacturerWarranty";
import { DataSource } from "../../models/datasource";
import { RateCommission } from "../../models/rateCommission";
import { NavigateFunction } from "react-router-dom";
import { InfovideoAnswers } from '../../models/infovideoAnswers';
import { DeviceDiscount } from "../../models/deviceDiscount";
import { ObservableValue } from "mobx/dist/internal";
import { InformationObligation } from "../../models/informationObligation";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { timeStamp } from "console";

type TabType = 'keszulek' | 'ugyfel' | 'ajanlat';

export class OfferStore {

	RootStore: RootStore
	showFullCart: boolean = false;
	activeTab: TabType = 'keszulek';
	tariffPackageItems: Array<TariffPackage> = [];
	durationOptions: Array<any>;
	warrantyPlusItems: Array<WarrantyPlusPackage> = [];
	yesNoDatasource: Array<any>;
	manufacturerWarrantyExtensionDurationOptions: Array<any>;
	allPackage: boolean = false;
	phoneIndex: number = 0;
	clientConsentConfirmIsOpen: boolean = false;
	clientConsentError: boolean = false;
	ugyfelHozzajarulasaConfirmIsOpen: boolean = false;
	husegidoId: number = 12;
	hitelIgenylesItems: Array<any>;
	isHitelIgenyelve: boolean = false;
	historyItems: Array<any>;
	commentString: string = '';
	offer: Offer = new Offer();
	selectedItem: OfferPackage;
	selectedWfStep: any;
	saveConfirmIsOpen: boolean = false;
	gPlusconfirmOpen: boolean = false;
	queryText: string = "";
	screenProtectorQueryText: string = "";
	accessoryQueryText: string = "";
	settlementQueryText: string = "";
	temporarySettlementQueryText: string = "";
	devicesByQueryText: Array<Device> = [];
	screenProtectorsByQueryText: Array<Device> = [];
	accessoriesByQueryText: Array<Device> = [];
	settlementsByQueryText: Array<Settlement> = [];
	temporarySettlementsByQueryText: Array<Settlement> = [];
	offerId: number = 0;
	customerTitleOptions: Array<any>;
	genderForBBOptions: Array<any>;
	genderForVodafoneOptions: Array<any>;
	identityTypeOptions: Array<any>;
	isDeviceBasePriceOpen: boolean = false;
	isDevicePackageDiscountOpen: boolean = false;

	mobileNumberPrefixOptions: Array<any> = [];
	temporaryPayableAmount: number = 0;
	accessoriesColumnDef: Array<ColDef>;
	selectedAccessoryItem: AccessoriesOfPackage = new AccessoriesOfPackage();
	permanentAddressTypeOptions: Array<any> = [];
	temporaryAddressTypeOptions: Array<any> = [];
	permanentDefaultAddressTypeOptions: Array<any> = [];
	temporaryDefaultAddressTypeOptions: Array<any> = [];
	hasStreetNamesPerma: boolean = false;
	hasStreetNamesTemporary: boolean = false;
	manufacturerWarrantyOptions: Array<ManufacturerWarranty>
	showUseManufacturerWarrantyExtensionPopup: boolean = false;
	showYouOptionPopup: boolean = false;
	showFamilyPopup: boolean = false;
	showGPlusInfo: boolean = false;

	showNumberPortabilityPopup: boolean = false;
	showPreToPostPopup: boolean = false;
	addressStoreyOptions: Array<DataSource> = [];
	latestRateCommission: RateCommission = new RateCommission();
	showAccessorySearch: boolean = true;
	offerIdForClone: number = 0;
	showConfirmOfGDPRRejectionPop: boolean = false;
	customerDataStore: CustomerDataStore
	OfferService: OfferService
	AdminService: AdminService
	navigate?: NavigateFunction;
	saveLandingPage: boolean = false;
	showDatePicker: boolean = true;
	simNumberChangedByOnlinePickup: boolean = false;
	isSendOfferToStartPopupOpen: boolean = false;
	showSuccessfulDocumentValidation: boolean = true;
	DocumentValidationDate: Date;
	DocumentDateString: string = "";
	showSelectStoreConfirm: boolean = false;
	showQuantityWarning: boolean = false;
	gdprnumber = 1;
	showAccessoryPriceEdit: boolean = false;
	isUploadConfirmOpen: boolean = false;
	uploadIdString: string = "uploadId";
	file: any;
	selectedDocumentTypeNumber: number = 0;
	documentTypeOptions: Array<any> = [];
	infoVideoAnswers: Array<InfovideoAnswers> = [];
	isFilePdf: boolean;
	bannerByteContent1: { content: string };
	bannerByteContent2: { content: string };
	bannerByteContent3: { content: string };
	bannerByteContent4: { content: string };
	isBanner: boolean = false;
	showBanner: boolean = true;
	showCustomerDataPopUp: boolean = false;
	showZeroDurationPopup: boolean = false;
	showOverwritingPopup: boolean = false;
	showSeniorConsultantPopup: boolean = false;
	bankLoanPopup: boolean = false;
	pickupPopUp: boolean = false;
	isBankLoanPopupConfirmed: boolean = false;
	showCancelOfferPopup: boolean = false;

	isSaved: boolean = false;
	documents: Array<any> = [];
	pickupPopUpSeconds: number;
	confirmSendBack: boolean = false;
	informationObligationItems: Array<InformationObligation> = [];
	informationObligationItemOptions: Array<DataSource> = [];
	selectedInformationObligationId: number = 0;
	selectedInformationObligation: InformationObligation = new InformationObligation();
	editorStateInformationObligationMM = EditorState.createEmpty();
	isInformationObligationMMEditable: boolean = false;
	showInformationObligationPop: boolean = false;
	informationObligationItemForPopup: InformationObligation = new InformationObligation();


	constructor(navigate: NavigateFunction, RootStore?: RootStore, offerId?: number) {
		this.RootStore = RootStore;
		this.navigate = navigate
		makeAutoObservable(this, {
			showFullCart: observable,
			isSaved: observable,
			activeTab: observable,
			tariffPackageItems: observable,
			warrantyPlusItems: observable,
			allPackage: observable,
			phoneIndex: observable,
			durationOptions: observable,
			ugyfelHozzajarulasaConfirmIsOpen: observable,
			clientConsentConfirmIsOpen: observable,
			clientConsentError: observable,
			husegidoId: observable,
			hitelIgenylesItems: observable,
			isHitelIgenyelve: observable,
			historyItems: observable,
			commentString: observable,
			offer: observable,
			selectedItem: observable,
			selectedWfStep: observable,
			saveConfirmIsOpen: observable,
			queryText: observable,
			screenProtectorQueryText: observable,
			accessoryQueryText: observable,
			devicesByQueryText: observable,
			screenProtectorsByQueryText: observable,
			accessoriesByQueryText: observable,
			offerId: observable,
			customerTitleOptions: observable,
			genderForBBOptions: observable,
			genderForVodafoneOptions: observable,
			identityTypeOptions: observable,
			settlementQueryText: observable,
			temporarySettlementQueryText: observable,
			settlementsByQueryText: observable,
			temporarySettlementsByQueryText: observable,
			manufacturerWarrantyExtensionDurationOptions: observable,
			isDeviceBasePriceOpen: observable,
			isDevicePackageDiscountOpen: observable,

			mobileNumberPrefixOptions: observable,
			temporaryPayableAmount: observable,
			selectedAccessoryItem: observable,
			accessoriesColumnDef: observable,
			permanentAddressTypeOptions: observable,
			temporaryAddressTypeOptions: observable,
			permanentDefaultAddressTypeOptions: observable,
			temporaryDefaultAddressTypeOptions: observable,
			hasStreetNamesPerma: observable,
			hasStreetNamesTemporary: observable,
			manufacturerWarrantyOptions: observable,
			showUseManufacturerWarrantyExtensionPopup: observable,
			addressStoreyOptions: observable,
			latestRateCommission: observable,
			showAccessorySearch: observable,
			showSelectStoreConfirm: observable,
			showQuantityWarning: observable,
			confirmSendBack: observable,
			showCancelOfferPopup: observable,
			informationObligationItemOptions: observable,
			selectedInformationObligationId: observable,
			selectedInformationObligation: observable,
			editorStateInformationObligationMM: observable,
			isInformationObligationMMEditable: observable,
			showInformationObligationPop: observable,
			informationObligationItemForPopup: observable,
			setTariffPackageIsHighest: action,
			changeCartView: action,
			handleActiveTab: action,
			addPackageItem: action,
			removePackageItem: action,
			setSelectedItemActive: action,
			setGaranciaItemActive: action,
			setTarifaItemActive: flow,
			confirmUgyfelHozzajarulasa: action,
			cancelUgyfelHozzajarulasa: action,
			handleHusegidoIdChange: action,
			changeIsGDRPAccepted: action,
			setIsHitelIgenyleve: action,
			setCommentString: action,
			updateOfferItem: action,
			updateOfferPackageItem: action,
			updateDevice: flow,
			updateScreenProtector: action,
			initializeSelectedItem: action,
			setNewOfferPackage: action,
			confirmSaveOffer: action,
			cancelSave: action,
			openSaveConfirm: action,
			setTemporaryDetailsLikePermanent: action,
			onChangeQueryText: action,
			onScreenProtectorChangeQueryText: action,
			onAccessoryChangeQueryText: action,
			setDefaultValue: action,
			updatePermanentAddressCity: action,
			onAddressZipCodeChangeQueryText: action,
			onTemporaryAddressZipCodeChangeQueryText: action,
			navigateToGDPR: action,
			openDeviceBasePriceEdit: action,
			setIsDeviceBasePriceOpenFalse: action,
			openDevicePackageDiscountEdit: action,
			setIsDevicePackageDiscountFalse: action,
			setOfferPayableAmount: action,
			setTemporaryOfferPayableAmount: action,
			setOfferContractAmount: action,
			updateAccessory: action,
			deleteAccessoryItemById: action,
			addSelectedAccessoryToSelectedItemAccessories: action,
			updateTemporaryAddressCity: action,
			setTariffPackagesCalculatedMonthyPrices: action,
			setPermanentAddressType: action,
			setTemporaryAddressType: action,
			setOfferPackagesManufacturerWarranties: action,
			cancelUseManufacturerWarrantyExtensionPopup: action,
			setOfferPackageItemWarrantyPackages: action,
			setOfferPackagesWarrantyPackages: action,
			getWarrantyPackageMaxDuration: action,
			getWarrantyPackageMaxDurationForSavedOffer: action,
			updateSelectedInformationObligation: action,
			onEditorStateChangeInformationObligationMM: action,
			setInformationObligationMM: action,
			setIsInformationObligationMMEditable: action,
			cancelInformationObligationPop: action,
			nullSelectedItemScreenProtector: action,
			nullSelectedItemDevice: action,
			nullSelectedItemAccessory: action,
			setDocumentCheckDate: flow,
			getDeviceByQueryText: flow,
			getScreenProtectorByQueryText: flow,
			getAccessoryByQueryText: flow,
			saveOffer: flow,
			sendDocumentToSigning: flow,
			getsignedDocument: flow,
			getTariffPackages: flow,
			getWarrantyPlusPackeges: flow,
			getSettlementNameByQueryText: flow,
			getTemporarySettlementNameByQueryText: flow,
			getDeviceDiscountForOfferPackageDevice: flow,
			getHighestDeviceDiscount: flow,
			getStreetNamesAfterAddressZipCodeSelect: flow,
			setAddressType: flow,
			setTemporaryAddressTypeSuffix: flow,
			getManufacturerWarranties: flow,
			getInformationObligationItems: flow,
			getTariffPackagesLnumber: flow,
			saveOfferWithNoSelectedStep: flow,
			confirmUpload: flow,
			GetInfoVideoAnswers: flow,
			getPackageDiscount: flow,
			getPercentegaDiscount: flow,
			getByteContent: flow,
			leftPanelWidth: computed,
			isLoanDemandable: computed,
			userByQueryTextDDL: computed,
			screenProtectorByQueryTextDDL: computed,
			accessoryByQueryTextDDL: computed,
			settlementByQueryTextDDL: computed,
			temporarySettlementByQueryTextDDL: computed,
			allPackageTariffpackagePrice: computed,
			allPackageAdministrationFeeSum: computed,
			allPackageDevicePriceSum: computed,
			allPackageDeviceDiscountSum: computed,
			allPackageAdministrationFeeForDeviceSum: computed,
			deviceInstallment: computed,
			allPackageWarrantyAndScreenProtectorSum: computed,
			accessoriesSum: computed,
			allPackageWarrantyAndScreenProtectorSumMonthly: computed,
			accessoriesSumMonthly: computed,
			GetWfSteps: flow,
			documents: observable,
			GetDocumentsOfObject: flow,
			GetDocumentByObjectId: flow,
			GetWorkFlowHistoryByOfferId: flow,
			OfferBonding: flow,
			GetBPWfStepsDownpayment: flow,
			SendOfferToStart: flow,
			RemoveOfferDocumentByTypeAndOfferId: flow,
			getInformationObligationItemById: flow,
			getInformationTypeDisabled: computed,
		})

		this.OfferService = new OfferService()
		this.AdminService = new AdminService()

		this.offerId = offerId;

		this.yesNoDatasource = [
			{ value: 0, text: 'Nem' },
			{ value: 1, text: 'Igen' },
		]

		this.hitelIgenylesItems = [
			{ value: true, text: 'Igen' },
			{ value: false, text: 'Nem' }
		]
		this.historyItems = [];

		this.documentTypeOptions = [
			{ value: 2, text: 'Előfizetői szerződés' },
			{ value: 3, text: 'Egyéb dokumentum belső' },
			{ value: 7, text: 'Egyéb dokumentum ügyfélnek' },

		]
		// this.historyItems = [
		// 	{ Date: '2022.03.09.', ModifierUserName: 'Teszt User', ProcessName: 'Vodafone', PrevStatus: 'Új ajánlat (1000)', NewStatus: 'Infovideó folyamatban (1200)' },
		// 	{ Date: '2022.03.09.', ModifierUserName: 'Teszt Béla', ProcessName: 'Budapest Bank', PrevStatus: 'Új ajánlat(1000)', NewStatus: 'Bírálat folyamatban (2300)' },
		// 	{ Date: '2022.03.10.', ModifierUserName: 'Teszt User', ProcessName: 'Aegon', PrevStatus: 'Új hiteligénylés (3100)', NewStatus: 'Bírálat folyamatban (3200)' },
		// ]

		// this.accessoriesColumnDef = [
		// 	{ field: 'AccessoryName', headerName: 'Megnevezés', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
		// 	{ field: 'AccessoryArticleNumber', headerName: 'Cikkszám', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
		// 	{ field: 'AccessoryPrice', headerName: 'Ár', filter: 'agSetColumnFilter', valueFormatter: params => this.numberFormatter(params), sortable: true, maxWidth: 150, minWidth: 150, },
		// 	{
		// 		field: 'edit', headerName: 'Eltávolítás', maxWidth: 150, minWidth: 150,
		// 		cellRenderer: 'btnCellRenderer',
		// 		cellRendererParams: {
		// 			clicked: (data: any) => { this.deleteAccessoryItemById(data.AccessoryId); },
		// 		}
		// 	},
		// ]

		this.customerDataStore = new CustomerDataStore();
	}

	numberFormatter = (params: any) => {
		return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	deleteAccessoryItemById(accessoryItemId: number) {
		if (this.offer.MainStatus === 1000 || this.offer.MainStatus === 1100) {
			this.selectedItem.Accessories = this.selectedItem.Accessories.filter(x => x.AccessoryId !== accessoryItemId);
			console.log('this.selectedItem.Accessories:', toJS(this.selectedItem.Accessories))
			console.log(accessoryItemId)
			this.setOfferPayableAmount();
		}
	}

	setIsDocumentValidationSucceeded = (isSuccessful: boolean) => {
		if (isSuccessful) {
			this.setDocumentCheckDate(true);
			this.showSuccessfulDocumentValidation = false;
			this.DocumentValidationDate = new Date();
			this.DocumentDateString = this.DocumentValidationDate.toLocaleString();
		}
		else {
			this.setDocumentCheckDate(false);
			this.showSuccessfulDocumentValidation = true;
		}
	}

	addSelectedAccessoryToSelectedItemAccessories = () => {
		this.selectedItem.Accessories.push(this.selectedAccessoryItem);
		this.selectedAccessoryItem = new AccessoriesOfPackage();
		this.accessoryQueryText = null;
		this.showAccessorySearch = false;
		setTimeout(() => { this.showAccessorySearch = true }, 50)
		console.log(toJS(this.selectedItem.Accessories))
		this.setOfferPayableAmount();
	}

	changeCartView = () => {
		this.showFullCart = !this.showFullCart;
	};

	handleActiveTab = (tab: TabType) => {
		this.activeTab = tab;
		if ((tab === "ugyfel") && this.offer.IsGDPRAccepted != true) {
			this.ugyfelHozzajarulasaConfirmIsOpen = true;
			console.log(this.ugyfelHozzajarulasaConfirmIsOpen)
		}
		// else if ((tab === "ugyfel") && this.offer.ClientConsent != true) {

		// 	this.clientConsentConfirmIsOpen = true;
		// }
	};

	addPackageItem = () => {
		this.offer.Packages.push(
			{
				Id: this.offer.Packages.length + 1,
				PackageId: 0,
				DurationId: 1,
				MyProperty: 0,
				YouOption: 0,
				Family: 0,
				NumberPortability: 0,
				VFCSCustomer: 0,
				NewPhoneNumber: '',
				SimNumber: '',
				IMEINumber: '',
				DeviceId: 0,
				DeviceArticleNumber: 0,
				IsSelected: false,
				DeviceName: '',
				Manufacturer: '',
				Price: 0,
				DiscountPrice: 0,
				DeviceDescription: '',
				PictureUrl: '',
				FactoryWarrantyDuration: null,
				UseManufacturerWarrantyExtension: 0,
				ManufacturerWarrantyExtensionDuration: 6,
				HasScreenProtector: 0,
				ScreenProtectorId: 0,
				WarrantyPackageId: 0,
				WarrantyPackageName: '',
				WarrantyPackageOneTimeFee: 0,
				WarrantyPackageMonthlyInstallment: 0,
				WarrantyPackageArticleNumber: 0,
				ScreenProtectorArticleNumber: 0,
				ScreenProtectorName: '',
				ScreenProtectorManufacturer: '',
				ScreenProtectorPrice: 0,
				ScreenProtectorDiscountPrice: 0,
				Accessories: [],
				DeviceDiscount: 0,
				ManufacturerWarranties: [],
				WarrantyPackageItems: [],
				SimNumberOP: '',
				PackageArticleNumber: null,
				PackageAdministrativeName: null,
				WarrantyPackageTypeString: null,
				PackageAdministrationFee: 0,
				ActualDeviceDiscount: 0,
				DeviceStockQuantity: null,
				NewPhoneNumberWithoutPrefix: '',
				PackagePrice: null,
				ContractIdentifier: '',
				PreToPost: 0,
				CurrentPrepaidPhoneNumber: '',
				CurrentPrepaidPhoneNumberWithourPrefix: '',
				PhoneNumberToBeCarried: '',
				PhoneNumberToBeCarriedWithourPrefix: '',
				DevicePackageDiscount: null,
				TariffPackageDeviceDiscount: null,
				IsMergeable: null,
				IsMothlyFeeGPlus: null,
				PercentageDiscount: null,
				PercentageDiscountPrice: null
			}
		)
		console.log(this.offer.Packages)
	}


	removePackageItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		this.offer.Packages.splice(index, 1);
		e.preventDefault();
		e.stopPropagation();
		this.setOfferPayableAmount();
	};

	setSelectedItemActive = (index: number) => {
		for (let j = 0; j < this.tariffPackageItems.length; j++) {
			this.tariffPackageItems[j].IsActivePackageItem = false;
		}
		for (let i = 0; i < this.offer.Packages.length; i++) {
			if (i === index) {
				this.offer.Packages[index].IsSelected = true
				this.selectedItem = this.offer.Packages[index];
				if (this.selectedItem.PackageId != 0) {
					this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).IsActivePackageItem = true;
				}
			}
			else {
				this.offer.Packages[i].IsSelected = false;
			}
		}
	}

	setGaranciaItemActive = (index: number) => {
		if (this.offer.EditMode && (this.offer.MainStatus === 1000 || this.offer.MainStatus === 1100) && (this.selectedItem.HasScreenProtector === 0 || (this.selectedItem.HasScreenProtector === 1 && this.selectedItem.ScreenProtectorId > 0))) {
			for (let i = 0; i < this.selectedItem.WarrantyPackageItems.length; i++) {
				if (i === index) {
					if (this.selectedItem.WarrantyPackageItems[index].IsSelected) {
						this.selectedItem.WarrantyPackageItems[index].IsSelected = false;
						this.selectedItem.WarrantyPackageId = 0;
						this.selectedItem.WarrantyPackageOneTimeFee = 0;
						this.selectedItem.WarrantyPackageArticleNumber = 0;
						this.selectedItem.WarrantyPackageTypeString = "";
					}
					else {
						this.selectedItem.WarrantyPackageItems[index].IsSelected = true
						this.selectedItem.WarrantyPackageId = this.selectedItem.WarrantyPackageItems[index].Id
						this.selectedItem.WarrantyPackageArticleNumber = Number(this.selectedItem.WarrantyPackageItems[index].PackageNumber);
						this.selectedItem.WarrantyPackageOneTimeFee = this.selectedItem.WarrantyPackageItems[index].Price
						this.selectedItem.WarrantyPackageTypeString = this.selectedItem.WarrantyPackageItems[index].Type === 1 ? "G+TEL" : this.selectedItem.WarrantyPackageItems[index].Type === 2 ? "G+TEL&GL" : "SMART";
					}
				}
				else {
					this.selectedItem.WarrantyPackageItems[i].IsSelected = false;
				}
			}
			console.log('garanciacsomag id: ', this.selectedItem.WarrantyPackageId)
			this.setOfferPayableAmount();
		}
	}

	setSelectedWfStep = (index: number, type: number) => {
		if (type == 1) {
			if (this.offer.MainWfSteps[index]) {
				this.selectedWfStep = this.offer.MainWfSteps[index];
				if (this.selectedWfStep.Id == 1000 && this.selectedWfStep.ToStatusCode == 5200) {
					this.showCancelOfferPopup = true;
				}
			}
		}
		if (type == 2) {
			if (this.offer.VodafoneWfSteps[index]) {
				this.selectedWfStep = this.offer.VodafoneWfSteps[index];
				if (this.selectedWfStep.Id == 1000 && this.selectedWfStep.ToStatusCode == 5200) {
					this.showCancelOfferPopup = true;
				}
			}
		}
		if (type == 3) {
			if (this.offer.BPBankWfSteps[index]) {
				this.selectedWfStep = this.offer.BPBankWfSteps[index];
			}
		}
	}
	naiveRound(num: any, decimalPlaces = 0) {
		var p = Math.pow(10, decimalPlaces);
		return Math.round(num * p) / p;
	}
	*setTarifaItemActive(index: number) {
		if (this.offer.EditMode && (this.offer.MainStatus === 1000 || this.offer.MainStatus === 1100)) {
			for (let i = 0; i < this.tariffPackageItems.length; i++) {
				if (i === index) {
					this.tariffPackageItems[index].IsActivePackageItem = true
					this.selectedItem.PackageId = this.tariffPackageItems[index].Id
					this.selectedItem.PackageAdministrationFee = this.tariffPackageItems[index].AdminsitrationFee;
					this.selectedItem.PackagePrice = this.tariffPackageItems[index].PackagePrice;
					if (this.latestRateCommission.Lnumber === 1) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL1
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL1;
					}
					if (this.latestRateCommission.Lnumber === 2) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL2
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL2;
					}
					if (this.latestRateCommission.Lnumber === 3) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL3
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL3;
					}
					if (this.latestRateCommission.Lnumber === 4) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL4
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL4;
					}
					if (this.latestRateCommission.Lnumber === 5) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL5
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL5;
					}
					if (this.latestRateCommission.Lnumber === 6) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL6
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL6;
					}
					if (this.latestRateCommission.Lnumber === 7) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL7
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL7;
					}
					if (this.latestRateCommission.Lnumber === 8) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL8
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL8;
					}
					if (this.latestRateCommission.Lnumber === 9) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL9
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL9;
					}
					if (this.latestRateCommission.Lnumber === 10) {
						this.selectedItem.PackageArticleNumber = this.tariffPackageItems[index].ArticleNumberL10
						this.selectedItem.PackageAdministrativeName = this.tariffPackageItems[index].AdministrativeNameL10;
					}
					console.log('this.selectedItem.PackageArticleNumber: ', this.selectedItem.PackageArticleNumber)
				}
				else {
					this.tariffPackageItems[i].IsActivePackageItem = false;
				}
			}
			console.log('tarifacsomag id: ', this.selectedItem.PackageId)
			if (this.selectedItem?.DeviceId) {
				let packageDiscount: DeviceDiscount = null;
				packageDiscount = yield this.getPackageDiscount();
				let percentageDiscount: DeviceDiscount = null;
				percentageDiscount = yield this.getPercentegaDiscount();
				this.selectedItem.DevicePackageDiscount = null;
				let tariffPackage = this.tariffPackageItems.find(a => a.Id == this.selectedItem.PackageId);
				if (tariffPackage) {
					this.selectedItem.TariffPackageDeviceDiscount = tariffPackage.DeviceDiscount;
				} if (packageDiscount) {
					yield this.getDeviceDiscountForOfferPackageDevice();
					this.selectedItem.DevicePackageDiscount = packageDiscount.DiscountPackagePrice;
					this.selectedItem.IsMergeable = packageDiscount.IsMergeable;

					if (packageDiscount.IsMergeable)
						this.selectedItem.DevicePackageDiscount = this.selectedItem.DevicePackageDiscount - this.selectedItem.DeviceDiscount
					else {
						let discountPrice = this.selectedItem.Price - this.selectedItem.DeviceDiscount;
						if (discountPrice < this.selectedItem.DevicePackageDiscount) {
							this.selectedItem.DevicePackageDiscount = null;
							yield this.getHighestDeviceDiscount();

						}
					}
					this.selectedItem.ManufacturerWarranties = [];
					let discountedPrice = 0
					if (this.selectedItem.DeviceDiscount != 0) {
						discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
					} else {
						discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

					}
					var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
					if (filterdArray) {
						for (let i = 0; i < filterdArray.length; i++) {
							this.selectedItem.ManufacturerWarranties.push(
								{
									value: filterdArray[i].Id,
									text: filterdArray[i].WarrantyPeriod + ' hónap',
								}
							)
						}
						console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
					}
					this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value

				} else if (percentageDiscount) {
					yield this.getDeviceDiscountForOfferPackageDevice();
					this.selectedItem.PercentageDiscount = percentageDiscount.PercentageDiscount;
					this.selectedItem.IsMergeable = percentageDiscount.IsMergeable;

					if (percentageDiscount.IsMergeable)
						this.selectedItem.PercentageDiscountPrice = Math.round((this.selectedItem.Price * (1 - percentageDiscount.PercentageDiscount))) - this.selectedItem.DeviceDiscount
					else {
						this.selectedItem.PercentageDiscountPrice = Math.round((this.selectedItem.Price * (1 - percentageDiscount.PercentageDiscount)));
					}
					this.selectedItem.ManufacturerWarranties = [];
					let discountedPrice = 0
					if (this.selectedItem.PercentageDiscount != 0) {
						discountedPrice = this.selectedItem.PercentageDiscount ? this.selectedItem.PercentageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
					} else {
						discountedPrice = this.selectedItem.PercentageDiscount ? this.selectedItem.PercentageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

					}
					var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
					if (filterdArray) {
						for (let i = 0; i < filterdArray.length; i++) {
							this.selectedItem.ManufacturerWarranties.push(
								{
									value: filterdArray[i].Id,
									text: filterdArray[i].WarrantyPeriod + ' hónap',
								}
							)
						}
						console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
					}
					this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value

				}


				else {
					yield this.getDeviceDiscountForOfferPackageDevice();
					yield this.getHighestDeviceDiscount();
					let packageDiscount: DeviceDiscount = null;
					packageDiscount = yield this.getPackageDiscount();
					let tariffPackage = this.tariffPackageItems.find(a => a.Id == this.selectedItem.PackageId);
					if (tariffPackage) {
						this.selectedItem.TariffPackageDeviceDiscount = tariffPackage.DeviceDiscount;
					} if (packageDiscount) {
						this.selectedItem.IsMergeable = packageDiscount.IsMergeable;

					}
					this.selectedItem.ManufacturerWarranties = [];
					let discountedPrice = 0
					if (this.selectedItem.DeviceDiscount != 0) {
						discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
					} else {
						discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

					}
					var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
					if (filterdArray) {
						for (let i = 0; i < filterdArray.length; i++) {
							this.selectedItem.ManufacturerWarranties.push(
								{
									value: filterdArray[i].Id,
									text: filterdArray[i].WarrantyPeriod + ' hónap',
								}
							)
						}
						console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
					}
					this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value
				}
				// this.getDeviceDiscountForOfferPackageDevice();
				// this.getHighestDeviceDiscount();
				this.setOfferPackageItemWarrantyPackages();
				this.selectedItem.WarrantyPackageId = 0
				this.selectedItem.WarrantyPackageArticleNumber = null;
				this.selectedItem.WarrantyPackageMonthlyInstallment = 0;
				this.selectedItem.WarrantyPackageName = null;
				this.selectedItem.WarrantyPackageOneTimeFee = 0;



				// let discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount;

			}
			this.setOfferPayableAmount();
		}
	}
	confirmClientConsent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (this.offer.ClientConsent == true || this.offer.ClientConsent == false) {
			this.clientConsentError = false;
			this.clientConsentConfirmIsOpen = false;
		} else {
			this.clientConsentError = true;
		}
	}

	cabcelClientConsent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (this.offer.ClientConsent == true || this.offer.ClientConsent == false) {
			this.clientConsentError = false;
			this.clientConsentConfirmIsOpen = false;
		} else {
			this.clientConsentError = true;
		}
	}

	confirmUgyfelHozzajarulasa = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (this.offer.IsGDPRAccepted === true) {
			this.ugyfelHozzajarulasaConfirmIsOpen = false;
		}
		if (this.offer.IsGDPRAccepted === false) {
			this.showConfirmOfGDPRRejectionPop = true;
		}
		if (this.offer.MainStatus === 1000 || this.offer.MainStatus === 1100) {
			this.showCustomerDataPopUp = true;
		}
	}

	cancelUgyfelHozzajarulasa = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.ugyfelHozzajarulasaConfirmIsOpen = false;
		this.offer.IsGDPRAccepted = null;
		if (this.offer.MainStatus === 1000 || this.offer.MainStatus === 1100) {
			this.showCustomerDataPopUp = true;
		}
	}

	confirmGDRPRRejection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showConfirmOfGDPRRejectionPop = false;
		this.saveOfferWithNoSelectedStep();
		this.navigate('/home');
	}

	confirmStoreSelection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showSelectStoreConfirm = false;
	}

	cancelStoreSelection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showSelectStoreConfirm = false;
		this.navigate('/home');
	}

	confirmbankLoanPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.bankLoanPopup = false;
		this.isBankLoanPopupConfirmed = true;
	}
	confirmpickupPopUp = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.pickupPopUp = false;

	}

	confirmSeniorConsultantPoup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showSeniorConsultantPopup = false;
	}

	*saveOfferWithNoSelectedStep() {
		this.offer.StoreSAPCode = this.RootStore.selectedStore;
		this.offer.Id = yield this.OfferService.SaveOffer(this.offer);
	}

	cancelGDPRRejection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showConfirmOfGDPRRejectionPop = false;
	}

	cancelOfferPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showCancelOfferPopup = false;
	}

	cancelCustomerDataPopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.showCustomerDataPopUp = false;

		// if (this.offer.IsGDPRAccepted === true && !this.offer.ClientConsent) {
		// 	this.clientConsentConfirmIsOpen = true;
		// }
	}

	openSaveConfirm = () => {
		if (this.offer.MainStatus != 5200) {
			this.saveConfirmIsOpen = true;
			console.log(this.saveConfirmIsOpen)
			console.log(this.offer)
		}
	}

	confirmSaveOffer = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (this.offer.AEGONStatus == 4150 && this.offer.MainStatus == 1500) {
			this.gPlusconfirmOpen = true;
		} else {
			this.saveLandingPage = true;
			this.saveOffer();
		}



	}
	saveGplusConfirm = () => {

		this.offer.HasGPlus = true;
		this.saveLandingPage = true;
		this.saveOffer();




	}

	saveGplusNotConfirm = () => {

		this.offer.HasGPlus = false;
		for (let index = 0; index < this.offer.Packages.length; index++) {

			this.offer.Packages[index].WarrantyPackageId = 0;
			this.offer.Packages[index].WarrantyPackageOneTimeFee = 0;
			this.offer.Packages[index].WarrantyPackageArticleNumber = 0;
			this.offer.Packages[index].WarrantyPackageTypeString = "";
		}


		this.saveLandingPage = true;
		this.saveOffer();

	}
	cancelSave = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.saveConfirmIsOpen = false;
	}

	handleHusegidoIdChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		this.selectedItem.DurationId = Number(data.value);
		console.log("husegidoId:", data.value)
		this.setOfferPayableAmount();
		if (this.selectedItem.DurationId === 3) {
			this.showZeroDurationPopup = true;
		}
		if (this.selectedItem.DurationId === 1) {
			var selectedItemClone = this.selectedItem;
			this.selectedItem = {
				Id: selectedItemClone.Id,
				PackageId: selectedItemClone.PackageId,
				DurationId: 1,
				MyProperty: selectedItemClone.MyProperty,
				YouOption: selectedItemClone.YouOption,
				Family: selectedItemClone.Family,
				NumberPortability: selectedItemClone.NumberPortability,
				VFCSCustomer: selectedItemClone.VFCSCustomer,
				NewPhoneNumber: selectedItemClone.NewPhoneNumber,
				SimNumber: selectedItemClone.SimNumber,
				IMEINumber: selectedItemClone.IMEINumber,
				DeviceId: 0,
				DeviceArticleNumber: 0,
				IsSelected: true,
				DeviceName: '',
				Manufacturer: '',
				Price: 0,
				DiscountPrice: 0,
				DeviceDescription: '',
				PictureUrl: '',
				FactoryWarrantyDuration: null,
				UseManufacturerWarrantyExtension: 0,
				ManufacturerWarrantyExtensionDuration: 6,
				HasScreenProtector: 0,
				ScreenProtectorId: 0,
				WarrantyPackageId: 0,
				WarrantyPackageName: '',
				WarrantyPackageOneTimeFee: 0,
				WarrantyPackageMonthlyInstallment: 0,
				WarrantyPackageArticleNumber: 0,
				ScreenProtectorArticleNumber: 0,
				ScreenProtectorName: '',
				ScreenProtectorManufacturer: '',
				ScreenProtectorPrice: 0,
				ScreenProtectorDiscountPrice: 0,
				Accessories: [],
				DeviceDiscount: 0,
				ManufacturerWarranties: [],
				WarrantyPackageItems: [],
				SimNumberOP: selectedItemClone.SimNumberOP,
				PackageArticleNumber: null,
				PackageAdministrativeName: null,
				WarrantyPackageTypeString: null,
				PackageAdministrationFee: 0,
				ActualDeviceDiscount: 0,
				DeviceStockQuantity: null,
				NewPhoneNumberWithoutPrefix: selectedItemClone.NewPhoneNumberWithoutPrefix,
				PackagePrice: null,
				ContractIdentifier: selectedItemClone.ContractIdentifier,
				PreToPost: selectedItemClone.PreToPost,
				CurrentPrepaidPhoneNumber: selectedItemClone.CurrentPrepaidPhoneNumber,
				CurrentPrepaidPhoneNumberWithourPrefix: selectedItemClone.CurrentPrepaidPhoneNumberWithourPrefix,
				PhoneNumberToBeCarried: selectedItemClone.PhoneNumberToBeCarried,
				PhoneNumberToBeCarriedWithourPrefix: selectedItemClone.PhoneNumberToBeCarriedWithourPrefix,
				DevicePackageDiscount: selectedItemClone.DevicePackageDiscount,
				TariffPackageDeviceDiscount: selectedItemClone.TariffPackageDeviceDiscount,
				IsMergeable: selectedItemClone.IsMergeable,
				IsMothlyFeeGPlus: selectedItemClone.IsMothlyFeeGPlus,
				PercentageDiscountPrice: selectedItemClone.PercentageDiscountPrice,
				PercentageDiscount: selectedItemClone.PercentageDiscount
			}
			this.offer.IsLoanDemand = false;
			var index = this.offer.Packages.findIndex(x => x.IsSelected);
			this.offer.Packages[index] = this.selectedItem;
			this.setOfferPayableAmount();
			console.log("packages:", toJS(this.offer.Packages))
		}
	}

	changeIsGDRPAccepted = (isAccepted: boolean) => {
		this.offer.IsGDPRAccepted = isAccepted
	}

	changeClientConsent = (data: boolean) => {
		this.offer.ClientConsent = data;
	}


	setIsHitelIgenyleve = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		this.isHitelIgenyelve = !!data.value;
	}

	setCommentString = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
		this.offer.Comment = String(data.value);
		console.log(this.offer.Comment);
	}
	getPermanentAddressSum() {
		let retVal = "";
		if (this.offer.IsHRSZ) {
			retVal = this.offer.PermanentAddressZipCode ? this.offer.PermanentAddressZipCode?.toString() : "";
			retVal += " " + this.offer.PermanentAddressCity;
			retVal += " HRSZ: " + this.offer.HRSZ;
		} else {
			retVal = this.offer.PermanentAddressZipCode ? this.offer.PermanentAddressZipCode?.toString() : "";
			retVal += this.offer.PermanentAddressCity != null ? " " + this.offer.PermanentAddressCity?.toString() : "";//" " + this.offer.PermanentAddressCity;
			if (this.hasStreetNamesPerma && this.offer.PermanentAddressName) {
				retVal += " " + this.permanentAddressTypeOptions.find(x => x.value == Number(this.offer.PermanentAddressName))?.text

			}
			if (!this.hasStreetNamesPerma) {
				retVal += this.offer.PermanentAddressName != null ? " " + this.offer.PermanentAddressName?.toString() : "";// " " + this.offer.PermanentAddressName;
			}

			//jellege :
			if (!this.hasStreetNamesPerma && this.offer.PermanentAddressType) {
				retVal += this.permanentDefaultAddressTypeOptions.find(x => x.value == Number(this.offer.PermanentAddressType))?.text

			}
			if (this.hasStreetNamesPerma) {
				retVal += this.offer.PermanentAddressType != null ? " " + this.offer.PermanentAddressType?.toString() : "";//" " + this.offer.PermanentAddressType;
			}



			retVal += this.offer.PermanentAddressHouseNumber != null ? " " + this.offer.PermanentAddressHouseNumber?.toString() : "";//+ this.offer.PermanentAddressHouseNumber;
			this.offer.PermanentAddressBuilding ? retVal += " Épület: " + this.offer.PermanentAddressBuilding : retVal += "";
			this.offer.PermanentAddressStaircase ? retVal += " Lépcsőház: " + this.offer.PermanentAddressStaircase : retVal += "";
			let storey = this.addressStoreyOptions.find(x => x.value == this.offer.PermanentAddressStorey)?.text
			storey ? retVal += " Emelet: " + storey : retVal += "";
			this.offer.PermanentAddressDoor ? retVal += " Ajtó: " + this.offer.PermanentAddressDoor : retVal += "";

		}

		return retVal;
	}
	getTemporarryAddressSum() {
		let retVal = "";
		if (this.offer.IsTemporaryHRSZ) {
			retVal = this.offer.TemporaryAddressZipCode ? this.offer.TemporaryAddressZipCode?.toString() : "";
			retVal += " " + this.offer.TemporaryAddressCity;
			retVal += " HRSZ: " + this.offer.TemporaryHRSZ;
		} else {
			retVal = this.offer.TemporaryAddressZipCode ? this.offer.TemporaryAddressZipCode?.toString() : "";
			retVal += this.offer.TemporaryAddressCity != null ? " " + this.offer.TemporaryAddressCity?.toString() : "";// " " + this.offer.TemporaryAddressCity;
			if (this.hasStreetNamesTemporary && this.offer.TemporaryAddressName) {
				retVal += " " + this.temporaryAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressName))?.text

			}
			if (!this.hasStreetNamesTemporary) {
				retVal += this.offer.TemporaryAddressName != null ? " " + this.offer.TemporaryAddressName?.toString() : "";//" " + this.offer.TemporaryAddressName;
			}

			//jellege :
			// if (this.hasStreetNamesTemporary && this.offer.TemporaryAddressName) {
			// 	retVal+= " "+ this.temporaryAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressName))?.text
			// }
			// if (!this.hasStreetNamesTemporary) {
			// 	retVal+= " "+this.offer.TemporaryAddressName;
			// }
			if (!this.hasStreetNamesTemporary && this.offer.TemporaryAddressType) {
				retVal += " " + this.temporaryDefaultAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressType)).text
			}
			if (this.hasStreetNamesTemporary) {
				retVal += this.offer.TemporaryAddressType != null ? " " + this.offer.TemporaryAddressType?.toString() : "";// " " + this.offer.TemporaryAddressType;
			}
			// if (!this.hasStreetNamesTemporary && this.offer.TemporaryAddressType) {
			// 	retVal+= " "+ this.temporaryAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressType))?.text

			// }
			// if (this.hasStreetNamesTemporary) {
			// 	retVal+= " "+this.offer.TemporaryAddressType;
			// }



			retVal += this.offer.TemporaryAddressHouseNumber != null ? " " + this.offer.TemporaryAddressHouseNumber?.toString() : "";//" " + this.offer.TemporaryAddressHouseNumber;
			this.offer.TemporaryAddressBuilding ? retVal += " Épület: " + this.offer.TemporaryAddressBuilding : retVal += "";
			this.offer.TemporaryAddressStaircase ? retVal += " Lépcsőház: " + this.offer.TemporaryAddressStaircase : retVal += "";
			let storey = this.addressStoreyOptions.find(x => x.value == this.offer.TemporaryAddressStorey)?.text
			storey ? retVal += " Emelet: " + storey : retVal += "";
			this.offer.TemporaryAddressDoor ? retVal += " Ajtó: " + this.offer.TemporaryAddressDoor : retVal += "";

		}

		return retVal;
	}
	updateOfferItem(key: keyof Offer, data: any, permaDropdown?: boolean) {
		let downaymantZero;
		if (key === 'PermanentAddressHouseNumber' || key === 'TemporaryAddressHouseNumber') {
			var regexp = new RegExp('^[0-9-]*$'); ////regexp
			var regexpRes = regexp.test(data.toString());
			if (regexpRes) {
				this.offer[key] = data
			}
		}
		if (key === 'HRSZ') {
			var regexp = new RegExp('^[A-Z0-9\/]*$'); ////regexp
			var regExpRes = regexp.test(data);
			if (regExpRes) {
				this.offer[key] = data;
			}
		}
		if (key === 'TemporaryHRSZ') {
			var regexp = new RegExp('^[A-Z0-9\/]*$'); ////regexp
			var regExpRes = regexp.test(data);
			if (regExpRes) {
				this.offer[key] = data;
			}
		}
		if (key === 'AddressCardNumber') {
			var regexp = new RegExp('^[A-Z0-9\/]*$'); ////regexp
			var regExpRes = regexp.test(data);
			if (regExpRes) {
				this.offer[key] = data;
				if (data.toString().length > 8) {
					this.offer[key] = data.toString().slice(0, 8)
				}
			}
		}
		if (key === 'BirthDate') {
			if (this.offer[key] != data) {
				(this.offer[key] as any) = data;
			}

			// this.showDatePicker = false;
			// setTimeout(() => {this.showDatePicker = true}, 50);	
			// (this.offer[key] as any) = data;		
		}
		if (key !== 'NeedVodafoneCustomerSecurityCode' && key !== 'PermanentAddressHouseNumber' && key !== 'TemporaryAddressHouseNumber' && key !== 'HRSZ' && key !== 'TemporaryHRSZ' && key !== 'AddressCardNumber' && key !== 'BirthDate') {
			downaymantZero = this.offer[key] == 0;
			(this.offer[key] as any) = data;
		}

		if (key === 'NeedVodafoneCustomerSecurityCode') {
			if (this.offer[key] === 0) {
				this.offer[key] = 1
			}
			else {
				this.offer[key] = 0;
			}
		}
		if (key === 'IsHRSZ') {
			if (this.offer[key] === 0) {
				this.offer[key] = 1
			}
			else {
				this.offer[key] = 0;
			}
		}

		if (key === 'IsTemporaryHRSZ') {
			if (this.offer[key] === 0) {
				this.offer[key] = 1
			}
			else {
				this.offer[key] = 0;
			}
		}

		if (key === 'MobileNumber') { // format?
			if (data.toString().length > 7) {
				this.offer[key] = data.toString().slice(0, 7)
			}
		}

		if (key === 'LastName' || key === 'MothersLastName' || key === 'BirthLastName') {
			if (data.toString().length > 30) {
				this.offer[key] = data.toString().slice(0, 30)
			}
		}

		if (key === 'FirstName' || key === 'MothersFirstName' || key === 'BirthFirstName') {
			if (data.toString().length > 29) {
				this.offer[key] = data.toString().slice(0, 29)
			}
		}

		if (key === 'BirthPlace') {
			if (data.toString().length > 26) {
				this.offer[key] = data.toString().slice(0, 26)
			}
		}

		if (key === 'Email') {
			if (data.toString().length > 40) {
				this.offer[key] = data.toString().slice(0, 40)
			}
		}

		if (key === 'PermanentAddressCity' || key === 'TemporaryAddressCity') {
			if (data.toString().length > 100) {
				this.offer[key] = data.toString().slice(0, 100)
			}
		}

		if (key === 'PermanentAddressHouseNumber') {
			if (data.toString().length > 7) {
				this.offer[key] = data.toString().slice(0, 7)
			}
		}

		if (key === 'TemporaryAddressHouseNumber') {
			if (data.toString().length > 7) {
				this.offer[key] = data.toString().slice(0, 7)
			}
		}

		if (key === 'PermanentAddressDoor') {
			if (data.toString().length > 3) {
				this.offer[key] = data.toString().slice(0, 3)
			}
		}

		if (key === 'TemporaryAddressDoor') {
			if (data.toString().length > 3) {
				this.offer[key] = data.toString().slice(0, 3)
			}
		}

		if (key === 'PermanentAddressName') {
			if (data.toString().length > 30) {
				this.offer[key] = data.toString().slice(0, 30)
			}
			if (permaDropdown) {
				this.setAddressType();
			}
		}

		if (key === 'TemporaryAddressName') {
			if (data.toString().length > 30) {
				this.offer[key] = data.toString().slice(0, 30)
			}
			if (permaDropdown) {
				this.setTemporaryAddressTypeSuffix();
			}
		}

		if (key === 'MKBTransactionNumber') {
			if (data.toString().length > 12) {
				this.offer[key] = data.toString().slice(0, 12)
			}
		}

		console.log(this.offer[key], 'változott:', data)

		if (key === 'DownPayment') {
			if (downaymantZero && this.offer.BPBankStatus >= 3300) {
				this.GetBPWfStepsDownpayment()
			}

			this.setTemporaryOfferPayableAmount();
			this.offer.PayableAmount = this.temporaryPayableAmount - this.offer.DownPayment;
			this.offer.PayableAmountMonthly = Math.round(this.offer.PayableAmount / 22)
			if (data === '' || data === 0) {
				this.setOfferPayableAmount();
			}
			this.offer.ContractAmount = data
			this.offer.Instalment = Math.round(this.offer.PayableAmount / 22)
		}
		if (key === 'IsLoanDemand') {
			if (data == false) {
				this.setTemporaryOfferPayableAmount();
				this.offer.PayableAmount = this.temporaryPayableAmount
				this.offer.PayableAmountMonthly = Math.round(this.offer.PayableAmount / 22)
				this.offer.MKBTransactionNumber = "";
				this.setOfferContractAmount(true);
				// this.offer.ContractAmount = this.offer.PayableAmount
				// this.offer.Instalment = Math.round(this.offer.PayableAmount / 22)
				this.offer.DownPayment = 0;
			}
			if (data == true) {
				this.offer.ContractAmount = this.offer.DownPayment;
			}
		}


	}
	*updateDevice(value: any) {
		if (value.StockQuantity < 1) {
			this.showQuantityWarning = true;
		}
		this.selectedItem.WarrantyPackageId = null;
		this.selectedItem.DeviceId = value.Id;
		this.selectedItem.DeviceArticleNumber = value.ArticleNumber;
		this.selectedItem.DeviceName = value.Name;
		this.selectedItem.DeviceDescription = value.Description;
		this.selectedItem.PictureUrl = value.PictureUrl;
		this.selectedItem.DeviceStockQuantity = value.StockQuantity;

		this.selectedItem.Manufacturer = value.Manufacturer;
		this.selectedItem.Price = value.Price;
		let packageDiscount: DeviceDiscount = null;
		packageDiscount = yield this.getPackageDiscount();
		let percentageDiscount: DeviceDiscount = null;
		percentageDiscount = yield this.getPercentegaDiscount();
		this.selectedItem.DevicePackageDiscount = null;
		let tariffPackage = this.tariffPackageItems.find(a => a.Id == this.selectedItem.PackageId);
		if (tariffPackage) {
			this.selectedItem.TariffPackageDeviceDiscount = tariffPackage.DeviceDiscount;
		} if (packageDiscount) {
			yield this.getDeviceDiscountForOfferPackageDevice();
			this.selectedItem.DevicePackageDiscount = packageDiscount.DiscountPackagePrice;
			this.selectedItem.IsMergeable = packageDiscount.IsMergeable;

			if (packageDiscount.IsMergeable)
				this.selectedItem.DevicePackageDiscount = this.selectedItem.DevicePackageDiscount - this.selectedItem.DeviceDiscount
			else {
				let discountPrice = this.selectedItem.Price - this.selectedItem.DeviceDiscount;
				if (discountPrice < this.selectedItem.DevicePackageDiscount) {
					this.selectedItem.DevicePackageDiscount = null;
					yield this.getHighestDeviceDiscount();

				}
			}



		} else if (percentageDiscount) {
			yield this.getDeviceDiscountForOfferPackageDevice();
			this.selectedItem.PercentageDiscount = percentageDiscount.PercentageDiscount;
			this.selectedItem.IsMergeable = percentageDiscount.IsMergeable;

			if (percentageDiscount.IsMergeable)
				this.selectedItem.PercentageDiscountPrice = Math.round(this.selectedItem.Price * (1 - percentageDiscount.PercentageDiscount)) - this.selectedItem.DeviceDiscount
			else {
				this.selectedItem.PercentageDiscountPrice = Math.round(this.selectedItem.Price * (1 - percentageDiscount.PercentageDiscount));
			}
			this.selectedItem.ManufacturerWarranties = [];
			let discountedPrice = 0
			if (this.selectedItem.PercentageDiscount != 0) {
				discountedPrice = this.selectedItem.PercentageDiscount ? this.selectedItem.PercentageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
			} else {
				discountedPrice = this.selectedItem.PercentageDiscount ? this.selectedItem.PercentageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

			}
			var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
			if (filterdArray) {
				for (let i = 0; i < filterdArray.length; i++) {
					this.selectedItem.ManufacturerWarranties.push(
						{
							value: filterdArray[i].Id,
							text: filterdArray[i].WarrantyPeriod + ' hónap',
						}
					)
				}
				console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
			}
			this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value

		}

		else {
			yield this.getDeviceDiscountForOfferPackageDevice();
			yield this.getHighestDeviceDiscount();
			let packageDiscount: DeviceDiscount = null;
			packageDiscount = yield this.getPackageDiscount();
			let tariffPackage = this.tariffPackageItems.find(a => a.Id == this.selectedItem.PackageId);
			if (tariffPackage) {
				this.selectedItem.TariffPackageDeviceDiscount = tariffPackage.DeviceDiscount;
			}
			if (packageDiscount) {
				this.selectedItem.IsMergeable = packageDiscount.IsMergeable;

			}
		}



		this.queryText = null;
		this.setOfferPayableAmount();

		this.selectedItem.ManufacturerWarranties = [];

		// let discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount;
		let discountedPrice = 0
		if (this.selectedItem.DeviceDiscount != 0) {
			discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
		} else {
			discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

		}
		var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
		if (filterdArray) {
			for (let i = 0; i < filterdArray.length; i++) {
				this.selectedItem.ManufacturerWarranties.push(
					{
						value: filterdArray[i].Id,
						text: filterdArray[i].WarrantyPeriod + ' hónap',
					}
				)
			}
			console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
		}
		this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value
		var debug = 0;

		this.setOfferPackageItemWarrantyPackages();

	}

	updateScreenProtector(value: any) {
		this.selectedItem.ScreenProtectorId = value.Id;
		this.selectedItem.ScreenProtectorArticleNumber = value.ArticleNumber;
		this.selectedItem.ScreenProtectorName = value.Name;
		// this.selectedItem.DeviceDescription=value.Description;
		// this.selectedItem.PictureUrl=value.PictureUrl;

		this.selectedItem.ScreenProtectorManufacturer = value.Manufacturer;
		this.selectedItem.ScreenProtectorPrice = value.Price;
		this.selectedItem.ScreenProtectorDiscountPrice = value.DiscountPrice;

		this.screenProtectorQueryText = null;
		this.setOfferPayableAmount();

		this.setOfferPackageItemWarrantyPackages();
	}

	updateAccessory(value: any) {
		this.selectedAccessoryItem.AccessoryId = value.Id;
		this.selectedAccessoryItem.PackageId = this.selectedItem?.Id
		this.selectedAccessoryItem.AccessoryName = value.Name
		this.selectedAccessoryItem.AccessoryArticleNumber = value.ArticleNumber
		this.selectedAccessoryItem.AccessoryDescription = value.Description
		this.selectedAccessoryItem.AccessoryPrice = value.Price
		this.selectedAccessoryItem.AccessoryManufacturer = value.Manufacturer

		this.accessoryQueryText = null;
		this.setOfferPayableAmount();
	}

	updatePermanentAddressCity(value: any) {
		this.offer.PermanentAddressZipCode = value.ZipCode
		this.offer.PermanentAddressCity = value.Name

		this.getStreetNamesAfterAddressZipCodeSelect(true, true);

		this.settlementQueryText = null;
		this.settlementsByQueryText = [];
	}

	updateTemporaryAddressCity(value: any) {
		this.offer.TemporaryAddressZipCode = value.ZipCode
		this.offer.TemporaryAddressCity = value.Name

		this.getStreetNamesAfterAddressZipCodeSelect(false, true);

		this.temporarySettlementQueryText = null;
		this.temporarySettlementsByQueryText = [];
	}

	updateSelectedItemActiveAccessoryItemPrice(value: any) {
		var item = this.selectedItem?.Accessories?.find(x => x.IsActive)
		if (item) {
			item.AccessoryPrice = value;
			this.setOfferPayableAmount();
		}


	}

	updateOfferPackageItem(key: keyof OfferPackage, value: any) {
		if (key === 'YouOption' || key === 'Family' || key === 'NumberPortability' || key === 'VFCSCustomer' || key === 'PreToPost' || key === 'IsMothlyFeeGPlus') {
			if (this.selectedItem[key] === 0 || !this.selectedItem[key]) {
				this.selectedItem[key] = 1
				if (key === 'YouOption') {
					// this.showYouOptionPopup = true;
				}
				if (key === 'Family') {
					this.showFamilyPopup = true;
				}
				if (key === 'NumberPortability') {
					this.showNumberPortabilityPopup = true;
				}
				if (key === 'PreToPost') {
					this.showPreToPostPopup = true;
				}
			}
			else {
				this.selectedItem[key] = 0;
			}
		}

		else {
			(this.selectedItem[key] as any) = value;
		}
		this.queryText = null;

		if (key === 'NewPhoneNumberWithoutPrefix') {
			if (value.toString().length > 9) {
				this.selectedItem[key] = value.toString().slice(0, 9)
				this.selectedItem.NewPhoneNumber = "+36" + this.selectedItem.NewPhoneNumberWithoutPrefix
				console.log('this.selectedItem.NewPhoneNumber:', this.selectedItem.NewPhoneNumber)
			}
			this.selectedItem.NewPhoneNumber = "+36" + this.selectedItem.NewPhoneNumberWithoutPrefix
			console.log('this.selectedItem.NewPhoneNumber:', this.selectedItem.NewPhoneNumber)
		}

		if (key === 'CurrentPrepaidPhoneNumberWithourPrefix') {
			if (value.toString().length > 9) {
				this.selectedItem[key] = value.toString().slice(0, 9)
				this.selectedItem.CurrentPrepaidPhoneNumber = "+36" + this.selectedItem.CurrentPrepaidPhoneNumberWithourPrefix
				console.log('this.selectedItem.CurrentPrepaidPhoneNumber:', this.selectedItem.CurrentPrepaidPhoneNumber)
			}
			this.selectedItem.CurrentPrepaidPhoneNumber = "+36" + this.selectedItem.CurrentPrepaidPhoneNumberWithourPrefix
			console.log('this.selectedItem.CurrentPrepaidPhoneNumber:', this.selectedItem.CurrentPrepaidPhoneNumber)
		}

		if (key === 'PhoneNumberToBeCarriedWithourPrefix') {
			if (value.toString().length > 9) {
				this.selectedItem[key] = value.toString().slice(0, 9)
				this.selectedItem.PhoneNumberToBeCarried = "+36" + this.selectedItem.PhoneNumberToBeCarriedWithourPrefix
				console.log('this.selectedItem.PhoneNumberToBeCarried:', this.selectedItem.PhoneNumberToBeCarried)
			}
			this.selectedItem.PhoneNumberToBeCarried = "+36" + this.selectedItem.PhoneNumberToBeCarriedWithourPrefix
			console.log('this.selectedItem.PhoneNumberToBeCarried:', this.selectedItem.PhoneNumberToBeCarried)
		}

		if (key === 'SimNumber') {
			if (value.toString().length > 20) {
				this.selectedItem[key] = value.toString().slice(0, 20)
			}
			if ((this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.StoreAdmin || this.RootStore.currentUser.OnlinePickup) && this.offer.MainStatus === 1500) {
				this.simNumberChangedByOnlinePickup = true;
			}
		}

		if (key === 'SimNumberOP') {
			if (value.toString().length > 20) {
				this.selectedItem[key] = value.toString().slice(0, 20)
			}
			console.log('sim kartya online pickup valtozott:', this.offer.Packages)
		}

		if (key === 'IMEINumber') {
			if (value.toString().length > 15) {
				this.selectedItem[key] = value.toString().slice(0, 15)
			}
		}

		if (key === 'UseManufacturerWarrantyExtension') {
			if (value === 1) {
				this.showUseManufacturerWarrantyExtensionPopup = true;
			}
			if (value === 0) {
				if (this.selectedItem.WarrantyPackageItems) {
					for (let i = 0; i < this.selectedItem.WarrantyPackageItems.length; i++) {
						this.selectedItem.WarrantyPackageItems[i].IsSelected = false;
					}
					this.selectedItem.WarrantyPackageId = 0
					this.selectedItem.WarrantyPackageArticleNumber = null;
					this.selectedItem.WarrantyPackageMonthlyInstallment = 0;
					this.selectedItem.WarrantyPackageName = null;
					// this.selectedItem.WarrantyPackageOneTimeFee = 0;
					this.setOfferPayableAmount();
				}
			}
		}

		if (key === 'HasScreenProtector') {
			if (value === 0) {
				this.setOfferPackageItemWarrantyPackages(true)
			}
			if (value === 1) {
				this.setOfferPackageItemWarrantyPackages();
			}
			this.nullSelectedItemScreenProtector();
		}

		if (key === 'ManufacturerWarrantyExtensionDuration' || key === 'FactoryWarrantyDuration') {
			this.setOfferPackageItemWarrantyPackages();
		}

		console.log(this.selectedItem[key], 'változott:', value)
		this.setOfferPayableAmount();

	}

	openShowOverwritingPopup = () => {
		this.showOverwritingPopup = true;
	}

	cancelOverWritingPopup = () => {
		this.showOverwritingPopup = false;
	}

	setSimNumberValueToSimNumberOPValue() {
		this.selectedItem.SimNumber = this.selectedItem.SimNumberOP;
		this.simNumberChangedByOnlinePickup = true;
		this.showOverwritingPopup = false;
		this.OfferService.SendSimChangeEmailToVodaBO(this.offer.Id, this.selectedItem.SimNumber);
	}

	initializeSelectedItem() {
		this.offer.Packages[0].IsSelected = true;
		this.selectedItem = this.offer.Packages[0];
		if (this.selectedItem.PackageId != 0) {
			this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).IsActivePackageItem = true;
		}
		this.setOfferPackageItemWarrantyPackages();

	}

	updateDocumentTypeNumber(value: any) {
		this.selectedDocumentTypeNumber = value;
	}

	setDefaultValue() {
		if (this.RootStore.currentUser.HoldingAdmin) {
			this.showSelectStoreConfirm = true;
		}
		this.offer.EditMode = true;
		this.offer.Created = new Date(new Date().toLocaleString())
		// this.offer.Created.setHours(this.offer.Created.getHours() + 2)
		this.offer.CreatedString = new Date().toLocaleString();
		this.offer.CustomerTitle = null;
		this.offer.GenderForVodafone = null;
		this.offer.Gender = null;
		this.offer.IdentityType = null;
		this.offer.HRSZ = '';
		this.offer.TemporaryHRSZ = '';
		this.offer.AddressCardNumber = '';
		this.offer.MobileNumberPrefix = 1;
		this.offer.CountryOfNationality = "Magyarország";
		this.offer.MainStatus = 1000;
		this.offer.MainStatusString = 'Új Ajánlat (1000)';

		this.offer.VodafoneStatus = 2000;
		this.offer.VodafoneStatusString = 'Indulásra vár (2000)';

		this.offer.BPBankStatus = 3000;
		this.offer.BPBankStatusString = 'Indulásra vár (3000)';

		this.offer.AEGONStatus = 4000;
		this.offer.AEGONStatusString = 'Indulásra vár (4000)';

		this.GetWfSteps();
		this.getByteContent();
	}

	openSendOfferToStartPopup = () => {
		this.isSendOfferToStartPopupOpen = true;
	}

	closeSendOfferToStartPopup = () => {
		this.isSendOfferToStartPopupOpen = false;
	}

	saveOfferAndNavigateToPrintableOfferPage = () => {
		this.selectedWfStep = this.offer.MainWfSteps[0];
		this.selectedWfStep.Id = 0;
		this.saveOffer(true);
	}

	setShowBanner = () => {
		this.showBanner = !this.showBanner;
	}

	*getByteContent() {
		if (this.RootStore.currentUser.IsConsultant || this.RootStore.currentUser.IsSeniorConsultant || this.RootStore.currentUser.StoreAdmin || this.RootStore.currentUser.HoldingAdmin) {
			this.bannerByteContent1 = yield this.AdminService.GetBannerByContent(1)
			this.isBanner = true;
		}
		if (this.RootStore.currentUser.VodafoneBO || this.RootStore.currentUser.VodafoneSupervisor || this.RootStore.currentUser.StoreAdmin || this.RootStore.currentUser.HoldingAdmin) {
			this.bannerByteContent2 = yield this.AdminService.GetBannerByContent(2)
			this.isBanner = true;
		}
		// if (this.offer.VodafoneStatus == 2600) {
		// 	this.bannerByteContent = yield this.AdminService.GetBannerByContent(3)
		// 	this.isBanner = true;
		// }
		if (this.RootStore.currentUser.CreditManager || this.RootStore.currentUser.StoreAdmin || this.RootStore.currentUser.HoldingAdmin) {
			this.bannerByteContent3 = yield this.AdminService.GetBannerByContent(3)
			this.isBanner = true;
		}
		if (this.RootStore.currentUser.OnlinePickup || this.RootStore.currentUser.StoreAdmin || this.RootStore.currentUser.HoldingAdmin) {
			this.bannerByteContent4 = yield this.AdminService.GetBannerByContent(4)
			this.isBanner = true;
		}
		// console.log('BANNNER:', this.bannerByteContent)
	}

	setPackagesNewPhoneNumberWithoutPrefix() {
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].NewPhoneNumber.length > 1) {
					this.offer.Packages[i].NewPhoneNumberWithoutPrefix = this.offer.Packages[i].NewPhoneNumber.substring(this.offer.Packages[i].NewPhoneNumber.length - 9)
					console.log('this.offer.Packages[i].NewPhoneNumberWithoutPrefix:', this.offer.Packages[i].NewPhoneNumberWithoutPrefix)
				}
				else {
					this.offer.Packages[i].NewPhoneNumberWithoutPrefix = "";
				}
			}
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].CurrentPrepaidPhoneNumber.length > 1) {
					this.offer.Packages[i].CurrentPrepaidPhoneNumberWithourPrefix = this.offer.Packages[i].CurrentPrepaidPhoneNumber.substring(this.offer.Packages[i].CurrentPrepaidPhoneNumber.length - 9)
					console.log('this.offer.Packages[i].CurrentPrepaidPhoneNumberWithourPrefix:', this.offer.Packages[i].CurrentPrepaidPhoneNumberWithourPrefix)
				}
				else {
					this.offer.Packages[i].CurrentPrepaidPhoneNumberWithourPrefix = "";
				}
			}
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].PhoneNumberToBeCarried.length > 1) {
					this.offer.Packages[i].PhoneNumberToBeCarriedWithourPrefix = this.offer.Packages[i].PhoneNumberToBeCarried.substring(this.offer.Packages[i].PhoneNumberToBeCarried.length - 9)
					console.log('this.offer.Packages[i].PhoneNumberToBeCarriedWithourPrefix:', this.offer.Packages[i].PhoneNumberToBeCarriedWithourPrefix)
				}
				else {
					this.offer.Packages[i].PhoneNumberToBeCarriedWithourPrefix = "";
				}
			}
		}

	}

	nullSelectedItemDevice() {
		this.selectedItem.DeviceId = null;
		this.selectedItem.DeviceArticleNumber = null;
		this.selectedItem.DeviceName = null;
		this.selectedItem.DeviceDescription = null;
		this.selectedItem.PictureUrl = null;
		this.selectedItem.DeviceStockQuantity = null;
		this.selectedItem.Manufacturer = null;
		this.selectedItem.Price = null;
		this.selectedItem.TariffPackageDeviceDiscount = null;
		this.selectedItem.IsMergeable = null;
		this.selectedItem.DevicePackageDiscount = null;
		this.selectedItem.PercentageDiscount = null;
		this.selectedItem.PercentageDiscountPrice = null;
		this.selectedItem.FactoryWarrantyDuration = null;
		this.selectedItem.DeviceDiscount = null;
		this.selectedItem.ActualDeviceDiscount = null;
		this.queryText = null;
		this.setOfferPayableAmount();
		//this.setTemporaryOfferPayableAmount();
	}

	nullSelectedItemAccessory() {
		this.selectedAccessoryItem.AccessoryId = null;
		this.selectedAccessoryItem.PackageId = null;
		this.selectedAccessoryItem.AccessoryName = null;
		this.selectedAccessoryItem.AccessoryArticleNumber = null;
		this.selectedAccessoryItem.AccessoryDescription = null;
		this.selectedAccessoryItem.AccessoryPrice = null;
		this.selectedAccessoryItem.AccessoryManufacturer = null;

		this.accessoryQueryText = null;
		this.setOfferPayableAmount();
	}

	*CreateSalesDoc() {
		this.RootStore?.addloading('CreateSalesDoc')
		// try {
		let debug: number = null;
		debug = yield this.OfferService.CreateSalesDoc(this.offer.Id);

		// business logic goes here
		// } catch (error) {
		// 	console.error(error) // from creation or business logic
		// 	this.RootStore?.removeLoading('CreateSalesDoc')
		// 	alert('cicc');
		// 	return;

		// }
		if (debug != 0) {
			this.offer.IsMCCPressed = true;
			yield this.OfferService.SetOfferIsMCCPressed(this.offer.Id);
			this.RootStore?.removeLoading('CreateSalesDoc')
			this.navigate(`/savelandingpage/${this.offer.Id}`)
		} else {
			this.RootStore?.removeLoading('CreateSalesDoc')

			alert('A bizonylat nem hozható létre.');
		}

	}
	*OfferBonding() {
		this.RootStore?.addloading('OfferBonding')
		yield this.OfferService.OfferBonding(this.offer.Id);
		this.RootStore?.removeLoading('OfferBonding')
		this.navigate(`/savelandingpage/${this.offer.Id}`)

	}
	*SendOfferToStart() {
		this.RootStore?.addloading('SendOfferToStart')
		yield this.OfferService.SendOfferToStart(this.offer.Id);
		this.RootStore?.removeLoading('SendOfferToStart')
		this.navigate(`/savelandingpage/${this.offer.Id}`)

	}
	*GetWorkFlowHistoryByOfferId(id: number) {
		this.historyItems = yield this.OfferService.GetWorkFlowHistoryByOfferId(id);
	}
	*GetDocumentByObjectId(id: number, type: number, IsOfferDocument: boolean, IsPolicyDocument: boolean, packageId: number, packageNumber: number) {
		this.OfferService.GetDocumentByObjectId(id, type, IsOfferDocument, IsPolicyDocument, packageId, packageNumber);
	}
	*GetDocumentsOfObject(id: number) {
		this.documents = yield this.OfferService.GetDocumentsOfObject(id);
		this.setDocumentTypeString();
	}
	*GetWfSteps() {
		this.offer.MainWfSteps = yield this.OfferService.GetWfSteps(1000, 1);
		let index = this.offer.MainWfSteps.findIndex(x => x.ToStatusCode == 1200);
		if (this.offer.MainWfSteps[index]) {
			this.selectedWfStep = this.offer.MainWfSteps[index];
		}
		// console.log(toJS('this.offer.MainWfSteps:',  this.offer.MainWfSteps));
	}
	*GetBPWfStepsDownpayment() {
		this.offer.BPBankWfSteps = yield this.OfferService.GetWfSteps(3300, 3)
		// console.log(toJS('this.offer.MainWfSteps:',  this.offer.MainWfSteps));
	}
	*GetInfoVideoAnswers(id: number) {
		this.infoVideoAnswers = yield this.OfferService.GetInfoVideoAnswers(id);
	}
	*RemoveOfferDocumentByTypeAndOfferId(type: number) {
		yield this.OfferService.RemoveOfferDocumentByTypeAndOfferId(type, this.offer.Id)
		this.GetDocumentsOfObject(this.offer.Id);
	}

	updateSelectedInformationObligation(value: any) {
		this.offer.InformationObligationId = value;
		console.log(this.offer.InformationObligationId)
		this.selectedInformationObligation = this.informationObligationItems.find(x => x.Id == this.offer.InformationObligationId);
		console.log(this.selectedInformationObligation)
		this.offer.InformationObligationMM = this.selectedInformationObligation.InformationObligationMM;
		this.setInformationObligationMM();
	}

	onEditorStateChangeInformationObligationMM(editorState: any) {
		this.editorStateInformationObligationMM = editorState;
		let value = draftToHtml(convertToRaw(this.editorStateInformationObligationMM.getCurrentContent()))
		this.offer.InformationObligationMM = value;
	};

	setInformationObligationMM() {
		if (this.offer.InformationObligationMM) {
			const blocksFromHtmlInformationObligationMM = htmlToDraft(this.offer.InformationObligationMM);
			const { contentBlocks, entityMap } = blocksFromHtmlInformationObligationMM;
			const contentStateInformationObligationMM = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorStateInformationObligationMM = EditorState.createWithContent(contentStateInformationObligationMM);
			this.onEditorStateChangeInformationObligationMM(editorStateInformationObligationMM);
		}
	}

	setDocumentTypeString() {
		for (let i = 0; i < this.documents.length; i++) {
			var doc = this.documents[i];
			if (doc.Type === 2) {
				doc.TypeString = "Előfizetői szerződés";
			}
			if (doc.Type === 3) {
				doc.TypeString = "Egyéb dokumentum belső";
			}
			if (doc.Type === 4) {
				doc.TypeString = "One szerződés kiegészítés";
			}
			if (doc.Type === 5) {
				doc.TypeString = "ÁSZF";
			}

			if ((doc.Type === 1) || (doc.Type > 5)) {
				doc.TypeString = "";
			}
			if (doc.Type === 6) {
				doc.TypeString = "Banki hitel összesítő";
			}
			if (doc.Type === 7) {
				doc.TypeString = "Egyéb dokumentum ügyfélnek";
			}
			if (doc.Type === 8) {
				doc.TypeString = "Hozzájárulási nyilatkozat";
			}
			if (doc.Type === 11) {
				doc.TypeString = "Bizonylat";
			}
		}
	}

	setNewOfferPackage() {
		const _offer = { ...this.offer }

		_offer.Packages.push(
			{
				Id: 0,
				PackageId: 0,
				DurationId: 1,
				MyProperty: 0,
				YouOption: 0,
				Family: 0,
				NumberPortability: 0,
				VFCSCustomer: 0,
				NewPhoneNumber: '',
				SimNumber: '',
				IMEINumber: '',
				DeviceId: 0,
				DeviceArticleNumber: 0,
				IsSelected: true,
				DeviceName: '',
				Manufacturer: '',
				Price: 0,
				DiscountPrice: 0,
				DeviceDescription: '',
				PictureUrl: '',
				FactoryWarrantyDuration: null,
				UseManufacturerWarrantyExtension: 0,
				ManufacturerWarrantyExtensionDuration: 6,
				HasScreenProtector: 0,
				ScreenProtectorId: 0,
				WarrantyPackageId: 0,
				WarrantyPackageName: '',
				WarrantyPackageOneTimeFee: 0,
				WarrantyPackageMonthlyInstallment: 0,
				WarrantyPackageArticleNumber: 0,
				ScreenProtectorArticleNumber: 0,
				ScreenProtectorName: '',
				ScreenProtectorManufacturer: '',
				ScreenProtectorPrice: 0,
				ScreenProtectorDiscountPrice: 0,
				Accessories: [],
				DeviceDiscount: 0,
				ManufacturerWarranties: [],
				WarrantyPackageItems: [],
				SimNumberOP: '',
				PackageArticleNumber: null,
				PackageAdministrativeName: null,
				WarrantyPackageTypeString: null,
				PackageAdministrationFee: 0,
				ActualDeviceDiscount: 0,
				DeviceStockQuantity: null,
				NewPhoneNumberWithoutPrefix: '',
				PackagePrice: null,
				ContractIdentifier: '',
				PreToPost: 0,
				CurrentPrepaidPhoneNumber: '',
				CurrentPrepaidPhoneNumberWithourPrefix: '',
				PhoneNumberToBeCarried: '',
				PhoneNumberToBeCarriedWithourPrefix: '',
				DevicePackageDiscount: null,
				TariffPackageDeviceDiscount: null,
				IsMergeable: null,
				IsMothlyFeeGPlus: null,
				PercentageDiscountPrice: null,
				PercentageDiscount: null
			}
		)

		this.offer = _offer

		console.log('setNewOfferPackage lefutott', this.offer.Packages)
	}

	setTemporaryDetailsLikePermanent(bool: boolean) {
		if (bool) {
			this.offer.IsTemporaryAddressSameAsPermanent = true;
			this.hasStreetNamesTemporary = this.hasStreetNamesPerma;
			this.offer.IsTemporaryAdddressNameChanged = this.offer.IsPermaAdddressNameChanged;
			this.offer.TemporaryAddressZipCode = this.offer.PermanentAddressZipCode;
			this.offer.TemporaryAddressCity = this.offer.PermanentAddressCity;
			this.offer.TemporaryAddressName = this.offer.PermanentAddressName;
			this.offer.TemporaryAddressType = this.offer.PermanentAddressType;
			this.offer.TemporaryAddressHouseNumber = this.offer.PermanentAddressHouseNumber;
			this.offer.TemporaryAddressBuilding = this.offer.PermanentAddressBuilding;
			this.offer.TemporaryAddressStaircase = this.offer.PermanentAddressStaircase;
			this.offer.TemporaryAddressStorey = this.offer.PermanentAddressStorey;
			this.offer.TemporaryAddressDoor = this.offer.PermanentAddressDoor;
			this.getStreetNamesAfterAddressZipCodeSelect(false);
		}
		else {
			this.offer.IsTemporaryAddressSameAsPermanent = false;
			this.offer.TemporaryAddressZipCode = null;
			this.temporarySettlementQueryText = null;
			this.offer.TemporaryAddressCity = '';
			this.offer.TemporaryAddressName = '';
			this.offer.TemporaryAddressType = '';
			this.offer.TemporaryAddressHouseNumber = '';
			this.offer.TemporaryAddressBuilding = '';
			this.offer.TemporaryAddressStaircase = '';
			this.offer.TemporaryAddressStorey = null;
			this.offer.TemporaryAddressDoor = '';
		}
	}

	onChangeQueryText = (event: any, data: any) => {
		this.queryText = data.value
		console.log(this.onChangeQueryText)
		this.getDeviceByQueryText();
	}

	onScreenProtectorChangeQueryText = (event: any, data: any) => {
		this.screenProtectorQueryText = data.value
		console.log(this.onScreenProtectorChangeQueryText)
		this.getScreenProtectorByQueryText();
	}

	onAccessoryChangeQueryText = (event: any, data: any) => {
		this.accessoryQueryText = data.value
		console.log(this.onAccessoryChangeQueryText)
		this.getAccessoryByQueryText();
	}

	onAddressZipCodeChangeQueryText = (event: any, data: any) => {
		this.settlementQueryText = data.value
		if (data.value == null || data.value == undefined || data.value == '') {
			this.offer.PermanentAddressZipCode = null;
			this.offer.PermanentAddressCity = String('');
		}
		console.log('this.settlementQueryText', this.settlementQueryText);
		console.log(this.onAddressZipCodeChangeQueryText)
		this.getSettlementNameByQueryText();
	}

	onTemporaryAddressZipCodeChangeQueryText = (event: any, data: any) => {
		this.temporarySettlementQueryText = data.value
		if (data.value == null || data.value == undefined || data.value == '') {
			this.offer.TemporaryAddressZipCode = null;
			this.offer.TemporaryAddressCity = String('');
		}
		console.log('this.temporarySettlementQueryText', this.temporarySettlementQueryText);
		console.log(this.onTemporaryAddressZipCodeChangeQueryText)
		this.getTemporarySettlementNameByQueryText();
	}

	setTariffPackageIsHighest = () => {
		var maxValue = Math.max.apply(Math, this.tariffPackageItems.map(function (a) { return a.DeviceDiscount }))
		var tariffPackageItemsValue = this.tariffPackageItems.filter(x => x.DeviceDiscount === maxValue)
		for (let i = 0; i < tariffPackageItemsValue.length; i++) {
			tariffPackageItemsValue[i].IsHighestPrice = true;
		}
		console.log('highest value:', maxValue)
		this.setTariffPackagesCalculatedMonthyPrices();
	}

	navigateToGDPR = () => {
		var hasWarrantyPackage = false;
		for (let i = 0; i < this.offer.Packages.length; i++) {
			if (this.offer.Packages[i].WarrantyPackageId > 0) {
				hasWarrantyPackage = true;
			}
		}
		if (this.isLoanDemandable == false) {
			this.gdprnumber = 1;
		}
		if ((this.offer.IsLoanDemand == true) && !hasWarrantyPackage) {
			this.gdprnumber = 2;
		}
		if ((this.offer.IsLoanDemand == false) && hasWarrantyPackage) {
			this.gdprnumber = 3;
		}
		if ((this.offer.IsLoanDemand == true) && hasWarrantyPackage) {
			this.gdprnumber = 4;
		}
		window.open(`/#/gdpr/${this.gdprnumber}`, '_blank')
	}

	openDeviceBasePriceEdit = () => {
		this.isDeviceBasePriceOpen = true;
	}

	setIsDeviceBasePriceOpenFalse = () => {
		this.isDeviceBasePriceOpen = false;
		this.selectedItem.ManufacturerWarranties = [];
		this.selectedItem.WarrantyPackageId = null;

		// let discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount;
		let discountedPrice = 0

		if (this.selectedItem.PercentageDiscount) {
			if (this.selectedItem.IsMergeable) {
				this.selectedItem.PercentageDiscountPrice = Math.round(this.selectedItem.Price * (1 - this.selectedItem.PercentageDiscount)) - this.selectedItem.DeviceDiscount;
				discountedPrice = this.selectedItem.PercentageDiscountPrice;
			}
			else {
				this.selectedItem.PercentageDiscountPrice = Math.round(this.selectedItem.Price * (1 - this.selectedItem.PercentageDiscount));
				discountedPrice = this.selectedItem.PercentageDiscountPrice;

			}
		} else



			if (this.selectedItem.DeviceDiscount != 0) {
				discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
			} else {
				discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

			}
		var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
		if (filterdArray) {
			for (let i = 0; i < filterdArray.length; i++) {
				this.selectedItem.ManufacturerWarranties.push(
					{
						value: filterdArray[i].Id,
						text: filterdArray[i].WarrantyPeriod + ' hónap',
					}
				)
			}
			console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
		}
		this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value



		this.setOfferPackageItemWarrantyPackages();
		this.setOfferPayableAmount();

	}
	openDevicePackageDiscountEdit = () => {
		this.isDevicePackageDiscountOpen = true;
	}

	setIsDevicePackageDiscountFalse = () => {
		this.selectedItem.ManufacturerWarranties = [];
		this.selectedItem.WarrantyPackageId = null;

		// let discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount;
		let discountedPrice = 0

		if (this.selectedItem.DeviceDiscount != 0) {
			discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
		} else {
			discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

		}


		var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
		if (filterdArray) {
			for (let i = 0; i < filterdArray.length; i++) {
				this.selectedItem.ManufacturerWarranties.push(
					{
						value: filterdArray[i].Id,
						text: filterdArray[i].WarrantyPeriod + ' hónap',
					}
				)
			}
			console.log('this.selectedItem.ManufacturerWarranties', toJS(this.selectedItem.ManufacturerWarranties));
		}
		this.selectedItem.FactoryWarrantyDuration = this.selectedItem.ManufacturerWarranties[0].value



		this.setOfferPackageItemWarrantyPackages();
		this.isDevicePackageDiscountOpen = false;
	}

	openAccessoryPriceEdit = (index: number) => {
		this.showAccessoryPriceEdit = true;
		for (let i = 0; i < this.selectedItem.Accessories.length; i++) {
			this.selectedItem.Accessories[i].IsActive = false;
		}
		this.selectedItem.Accessories[index].IsActive = true;
	}

	setAccessoryPriceOpenFalse = () => {
		this.showAccessoryPriceEdit = false;
	}


	setOfferPayableAmount = () => {
		this.offer.PayableAmount = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				// var selectedTariffPackageItem = this.tariffPackageItems.find(x => x.Id === this.offer.Packages[i].PackageId)
				// if (selectedTariffPackageItem) {
				// 	this.offer.PayableAmount += selectedTariffPackageItem.PackagePrice;
				// 	if (this.offer.Packages[i].DurationId === 1) {
				// 		this.offer.PayableAmount -= selectedTariffPackageItem.CalculatedMonthlyPriceTwelveSum
				// 	}
				// 	if (this.offer.Packages[i].DurationId === 2) {
				// 		this.offer.PayableAmount -= selectedTariffPackageItem.CalculatedMonthlyPriceTwentyFourSum;
				// 	}
				// }
				if (this.offer.Packages[i].DeviceId) {
					if (this.offer.Packages[i].PercentageDiscountPrice) {
						this.offer.PayableAmount += this.offer.Packages[i].PercentageDiscountPrice;
					} else
						if (this.offer.Packages[i].DevicePackageDiscount) {
							this.offer.PayableAmount += Number(this.offer.Packages[i].DevicePackageDiscount);

						} else {
							this.offer.PayableAmount += Number(this.offer.Packages[i].Price);
							this.offer.PayableAmount -= this.offer.Packages[i].DeviceDiscount;
						}
					// this.offer.PayableAmount += Number(this.offer.Packages[i].Price);
					// this.offer.PayableAmount -= this.offer.Packages[i].DeviceDiscount;
				}
				// var selectedWarrantyPackageItem = this.warrantyPlusItems.find(x => x.Id === this.offer.Packages[i].WarrantyPackageId)
				// if (selectedWarrantyPackageItem) {
				// 	this.offer.PayableAmount += selectedWarrantyPackageItem.Price
				// }
				if (this.offer.Packages[i].ScreenProtectorId > 0) {
					this.offer.PayableAmount += this.offer.Packages[i].ScreenProtectorPrice
				}
				if (this.offer.Packages[i].Accessories.length > 0) {
					for (let j = 0; j < this.offer.Packages[i].Accessories.length; j++) {
						this.offer.PayableAmount += Number(this.offer.Packages[i].Accessories[j].AccessoryPrice)
					}
				}

			}
		}
		if (this.offer.PayableAmount > 0) {
			this.offer.PayableAmount = this.offer.PayableAmount - this.offer.DownPayment
			this.offer.PayableAmountMonthly = Math.round(this.offer.PayableAmount / 22)
		}
		if (this.offer.PayableAmount == 0) {
			this.offer.PayableAmountMonthly = 0;
		}
		this.temporaryPayableAmount = this.offer.PayableAmount
		this.setOfferContractAmount();
		this.setTemporaryOfferPayableAmount();
	}

	setOfferContractAmount = (IsLoanDemand?: boolean) => {
		if (!this.offer.IsLoanDemand) {
			this.offer.ContractAmount = this.offer.PayableAmount
		}
		if (this.offer.IsLoanDemand) {
			this.offer.ContractAmount = this.offer.DownPayment;
		}

		if (IsLoanDemand) {
			this.offer.ContractAmount = this.offer.PayableAmount;
		}


		this.offer.Instalment = Math.round(this.offer.PayableAmount / 22)
	}

	setTemporaryOfferPayableAmount = () => {
		this.temporaryPayableAmount = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				// var selectedTariffPackageItem = this.tariffPackageItems.find(x => x.Id === this.offer.Packages[i].PackageId)
				// if (selectedTariffPackageItem) {
				// 	this.temporaryPayableAmount += selectedTariffPackageItem.PackagePrice;
				// 	if (this.offer.Packages[i].DurationId === 1) {
				// 		this.temporaryPayableAmount -= selectedTariffPackageItem.CalculatedMonthlyPriceTwelveSum
				// 	}
				// 	if (this.offer.Packages[i].DurationId === 2) {
				// 		this.temporaryPayableAmount -= selectedTariffPackageItem.CalculatedMonthlyPriceTwentyFourSum;
				// 	}
				// }
				if (this.offer.Packages[i].DeviceId) {
					if (this.offer.Packages[i].DevicePackageDiscount) {
						this.temporaryPayableAmount += Number(this.offer.Packages[i].DevicePackageDiscount);

					} else {
						this.temporaryPayableAmount += Number(this.offer.Packages[i].Price);
						this.temporaryPayableAmount -= this.offer.Packages[i].DeviceDiscount;
					}

				}
				// var selectedWarrantyPackageItem = this.warrantyPlusItems.find(x => x.Id === this.offer.Packages[i].WarrantyPackageId)
				// if (selectedWarrantyPackageItem) {
				// 	this.temporaryPayableAmount += selectedWarrantyPackageItem.Price
				// }
				if (this.offer.Packages[i].ScreenProtectorId > 0) {
					this.temporaryPayableAmount += this.offer.Packages[i].ScreenProtectorPrice
				}
				if (this.offer.Packages[i].Accessories.length > 0) {
					for (let j = 0; j < this.offer.Packages[i].Accessories.length; j++) {
						this.temporaryPayableAmount += Number(this.offer.Packages[i].Accessories[j].AccessoryPrice)
					}
				}

			}
		}
	}

	setTariffPackagesCalculatedMonthyPrices() {
		if (this.tariffPackageItems) {
			for (let i = 0; i < this.tariffPackageItems.length; i++) {
				var tariffPackageItem = this.tariffPackageItems[i];
				tariffPackageItem.CalculatedMonthlyPriceTwelve = Math.round((tariffPackageItem.PackagePrice - (tariffPackageItem.MonthlyDiscountTwelve * tariffPackageItem.MonthlyDiscountDurationTwelve)) / 12)
				tariffPackageItem.CalculatedMonthlyPriceTwelveSum = tariffPackageItem.MonthlyDiscountTwelve * tariffPackageItem.MonthlyDiscountDurationTwelve
				tariffPackageItem.CalculatedMonthlyPriceTwentyFour = Math.round((tariffPackageItem.PackagePrice - (tariffPackageItem.MonthlyDiscount * tariffPackageItem.MonthlyDiscountDuration)) / 24)
				tariffPackageItem.CalculatedMonthlyPriceTwentyFourSum = tariffPackageItem.MonthlyDiscount * tariffPackageItem.MonthlyDiscountDuration
			}
		}
	}

	setPermanentAddressType = () => {
		if (this.offer.PermanentAddressType) {
			if (this.permanentDefaultAddressTypeOptions.length > 0 && !this.hasStreetNamesPerma) {
				this.offer.PermanentAddressType = this.permanentDefaultAddressTypeOptions.find(x => x.value == this.offer.PermanentAddressType).value;
				console.log('this.offer.PermanentAddressType', this.offer.PermanentAddressType)
			}
		}
	}

	setTemporaryAddressType = () => {
		if (this.offer.TemporaryAddressType) {
			if (this.temporaryDefaultAddressTypeOptions.length > 0 && !this.hasStreetNamesTemporary) {
				this.offer.TemporaryAddressType = this.temporaryDefaultAddressTypeOptions.find(x => x.value == this.offer.TemporaryAddressType).value;
				console.log('this.offer.TemporaryAddressType', this.offer.TemporaryAddressType)
			}
		}
	}

	setOfferPackagesManufacturerWarranties = () => {
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var packageItem = this.offer.Packages[i]
				packageItem.ManufacturerWarranties = [];
				if (packageItem.DeviceId > 0) {
					let discountedPrice = 0
					if (packageItem.DeviceDiscount != 0) {
						discountedPrice = packageItem.DevicePackageDiscount ? packageItem.DevicePackageDiscount - packageItem.PackageAdministrationFee : packageItem.Price - packageItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
					} else {
						discountedPrice = packageItem.DevicePackageDiscount ? packageItem.DevicePackageDiscount - packageItem.PackageAdministrationFee : packageItem.Price - packageItem.ActualDeviceDiscount - this.selectedItem.PackageAdministrationFee;

					}
					var filterdArray = this.manufacturerWarrantyOptions.filter(x => x.DevicePrice >= discountedPrice)
					if (filterdArray) {
						for (let i = 0; i < filterdArray.length; i++) {
							packageItem.ManufacturerWarranties.push(
								{
									value: filterdArray[i].Id,
									text: filterdArray[i].WarrantyPeriod + ' hónap',
								}
							)
						}
					}
					var debug = 0;
				}
			}
		}
	}

	cancelUseManufacturerWarrantyExtensionPopup = () => {
		this.showUseManufacturerWarrantyExtensionPopup = false;
	}

	cancelZeroDurationPopup = () => {
		this.showZeroDurationPopup = false;
		this.selectedItem.DurationId = 1;
	}

	cancelYouOptionPopup = () => {
		this.showYouOptionPopup = false;
	}

	cancelFamilyPopup = () => {
		this.showFamilyPopup = false;
	}
	cancelshowGPlusInfo = () => {
		this.showGPlusInfo = false;
	}
	showGPlusInfoFV = () => {
		this.showGPlusInfo = true;
	}
	cancelNumberPortabilityPopup = () => {
		this.showNumberPortabilityPopup = false;
	}

	cancelPreToPostPopup = () => {
		this.showPreToPostPopup = false;
	}

	cancelShowQuantityPopup = () => {
		this.showQuantityWarning = false;
	}

	setOfferPackageItemWarrantyPackages = (nullScreenProtector?: boolean) => {
		var discountedPrice = this.selectedItem.DevicePackageDiscount ? this.selectedItem.DevicePackageDiscount - this.selectedItem.PackageAdministrationFee : this.selectedItem.Price - this.selectedItem.DeviceDiscount - this.selectedItem.PackageAdministrationFee;
		// var typeId = this.selectedItem.ScreenProtectorId > 0 ? 2 : 1;
		var typeId = this.selectedItem.HasScreenProtector === 1 ? 2 : 1;
		var today = new Date()
		// var res = this.getWarrantyPackageMaxDuration();
		this.selectedItem.WarrantyPackageItems = this.warrantyPlusItems.filter(x => x.ValueFrom <= discountedPrice && x.ValueTo >= discountedPrice && (x.ExtendedWarrantyStartsAfterManufacturersWarranty == false ? true : this.getWarrantyPackageMaxDuration(x.Id)) && new Date(x.ValidFrom) <= today && (x.ValidTo === null ? true : new Date(x.ValidTo) >= today));
		// this.selectedItem.WarrantyPackageItems = this.warrantyPlusItems.filter(x => x.Type === (nullScreenProtector ? 1 : typeId) && x.ValueFrom <= discountedPrice && x.ValueTo >= discountedPrice && (x.ExtendedWarrantyStartsAfterManufacturersWarranty == false ? true : this.getWarrantyPackageMaxDuration(x.Id)) && new Date(x.ValidFrom) <= today && (x.ValidTo === null ? true : new Date(x.ValidTo) >= today));
		console.log('this.selectedItem.WarrantyPackageItems', toJS(this.selectedItem.WarrantyPackageItems))
	}

	getWarrantyPackageMaxDuration(warrantyPackageId: number) { // ha true akkor <= 2 ev es felkinalja a csomagot
		var res = null;
		var warrantyPackage = this.warrantyPlusItems.find(x => x.Id === warrantyPackageId)
		if (this.selectedItem && this.selectedItem.FactoryWarrantyDuration) {
			if (this.selectedItem.FactoryWarrantyDuration === 1 && this.selectedItem.ManufacturerWarrantyExtensionDuration === 6) {
				// factoryWarrantyDurationMonth = 0;
				// manufacturerWarrantyExtensionDurationMonth = 0;	
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 25) {
					res = true;
				}
				else {
					res = false;
				}
			}
			else if (this.selectedItem.FactoryWarrantyDuration === 1 && this.selectedItem.ManufacturerWarrantyExtensionDuration === 1) {
				// factoryWarrantyDurationMonth = 0;
				// manufacturerWarrantyExtensionDurationMonth = 12;
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 13) {
					res = true;
				}
				else {
					res = false;
				}
			}
			else if (this.selectedItem.FactoryWarrantyDuration === 10 && this.selectedItem.ManufacturerWarrantyExtensionDuration === 6) {
				// factoryWarrantyDurationMonth = 12;
				// manufacturerWarrantyExtensionDurationMonth = 0;
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 13) {
					res = true;
				}
				else {
					res = false;
				}
			}
		}
		else {
			res = false;
		}
		console.log('getWarrantyPackageMaxDuration:', res)
		return res;
	}

	getWarrantyPackageMaxDurationForSavedOffer(warrantyPackageId: number, offerPackageId: number) {
		var res = null;
		var warrantyPackage = this.warrantyPlusItems.find(x => x.Id === warrantyPackageId)
		var offerPackageItem = this.offer.Packages.find(x => x.Id === offerPackageId)
		if (offerPackageItem && offerPackageItem.FactoryWarrantyDuration) {
			if (offerPackageItem.FactoryWarrantyDuration === 1 && offerPackageItem.ManufacturerWarrantyExtensionDuration === 6) {
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 25) {
					res = true;
				}
				else {
					res = false;
				}
			}
			else if (offerPackageItem.FactoryWarrantyDuration === 1 && offerPackageItem.ManufacturerWarrantyExtensionDuration === 1) {
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 13) {
					res = true;
				}
				else {
					res = false;
				}
			}
			else if (offerPackageItem.FactoryWarrantyDuration === 10 && offerPackageItem.ManufacturerWarrantyExtensionDuration === 6) {
				if (warrantyPackage.ExtendedWarrantyLengthMonth < 13) {
					res = true;
				}
				else {
					res = false;
				}
			}
		}
		else {
			res = false;
		}
		console.log('getWarrantyPackageMaxDurationForSavedOffer:', res)
		return res;
	}

	nullSelectedItemScreenProtector() {
		this.selectedItem.ScreenProtectorId = 0;
		this.selectedItem.ScreenProtectorArticleNumber = null;
		this.selectedItem.ScreenProtectorName = null;
		this.selectedItem.ScreenProtectorManufacturer = null;
		this.selectedItem.ScreenProtectorPrice = 0;
		this.selectedItem.ScreenProtectorDiscountPrice = 0;

		this.screenProtectorQueryText = null;

		this.selectedItem.WarrantyPackageId = 0;
		this.selectedItem.WarrantyPackageArticleNumber = null;
		this.selectedItem.WarrantyPackageOneTimeFee = null;
		this.selectedItem.WarrantyPackageTypeString = null;

		this.setOfferPayableAmount();

	}

	setOfferPackagesWarrantyPackages = () => {
		if (this.offer.Packages) {
			var today = new Date();
			for (let i = 0; i < this.offer.Packages.length; i++) {
				let item = this.offer.Packages[i]
				if (item.DeviceId) {
					// var discountedPrice = item.Price - item.DeviceDiscount;
					var discountedPrice = item.DevicePackageDiscount ? item.DevicePackageDiscount - item.PackageAdministrationFee : item.Price - item.DeviceDiscount - item.PackageAdministrationFee;
					var typeId = item.ScreenProtectorId > 0 ? 2 : 1;
					// item.WarrantyPackageItems = this.warrantyPlusItems.filter(x => x.Type === typeId && x.ValueFrom <= discountedPrice && x.ValueTo >= discountedPrice && (x.ExtendedWarrantyStartsAfterManufacturersWarranty == false ? true : this.getWarrantyPackageMaxDurationForSavedOffer(x.Id, item.Id)) && new Date(x.ValidFrom) <= today && (x.ValidTo === null ? true : new Date(x.ValidTo) >= today))
					item.WarrantyPackageItems = this.warrantyPlusItems.filter(x => x.ValueFrom <= discountedPrice && x.ValueTo >= discountedPrice && (x.ExtendedWarrantyStartsAfterManufacturersWarranty == false ? true : this.getWarrantyPackageMaxDurationForSavedOffer(x.Id, item.Id)) && new Date(x.ValidFrom) <= today && (x.ValidTo === null ? true : new Date(x.ValidTo) >= today))
				}
				else {
					item.WarrantyPackageItems = [];
				}
			}
		}
	}

	getWarrantyPackageExtendedWarrantyStartsAfterManufacturersWarranty = () => {
		return true;
	}

	getPermanentAddressStreetNameForClipboard = () => {
		if (this.hasStreetNamesPerma && this.offer.PermanentAddressName) {
			var streetName = this.permanentAddressTypeOptions.find(x => x.value == Number(this.offer.PermanentAddressName)).text
			navigator.clipboard.writeText(streetName)
		}
		if (!this.hasStreetNamesPerma) {
			navigator.clipboard.writeText(this.offer.PermanentAddressName)
		}
	}

	getPermanentAddressTypeForClipboard = () => {
		if (!this.hasStreetNamesPerma && this.offer.PermanentAddressType) {
			var streetName = this.permanentDefaultAddressTypeOptions.find(x => x.value == Number(this.offer.PermanentAddressType)).text
			navigator.clipboard.writeText(streetName)
		}
		if (this.hasStreetNamesPerma) {
			navigator.clipboard.writeText(this.offer.PermanentAddressType)
		}
	}

	getPermanentAddressStoreyForClipboard = () => {
		var streetName = this.addressStoreyOptions.find(x => x.value == this.offer.PermanentAddressStorey).text
		navigator.clipboard.writeText(streetName.toString())

	}

	getTemporaryAddressStreetNameForClipboard = () => {
		if (this.hasStreetNamesTemporary && this.offer.TemporaryAddressName) {
			var streetName = this.temporaryAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressName)).text
			navigator.clipboard.writeText(streetName)
		}
		if (!this.hasStreetNamesTemporary) {
			navigator.clipboard.writeText(this.offer.TemporaryAddressName)
		}
	}

	getTemporaryAddressTypeForClipboard = () => {
		if (!this.hasStreetNamesTemporary && this.offer.TemporaryAddressType) {
			var streetName = this.temporaryDefaultAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressType)).text
			navigator.clipboard.writeText(streetName)
		}
		if (this.hasStreetNamesTemporary) {
			navigator.clipboard.writeText(this.offer.TemporaryAddressType)
		}
	}

	getTemporaryAddressStoreyForClipboard = () => {
		var streetName = this.addressStoreyOptions.find(x => x.value == this.offer.TemporaryAddressStorey).text
		navigator.clipboard.writeText(streetName.toString())

	}

	getCustomerTitleForClipboard = () => {
		var customerTitle = this.customerTitleOptions.find(x => x.value === this.offer.CustomerTitle).text

		navigator.clipboard.writeText(customerTitle)
	}

	getCustomerDataMobileNumberForClipborad = () => {
		var mobilePrefix = this.mobileNumberPrefixOptions.find(x => x.value === this.offer.MobileNumberPrefix).text

		navigator.clipboard.writeText(mobilePrefix + this.offer.MobileNumber)
	}

	getCustomerdataBirthDateForClipboard = () => {
		if (this.offer.BirthDate) {
			var dateToCopy = new Date(this.offer.BirthDate);
			navigator.clipboard.writeText(`${dateToCopy.getFullYear()}.${(dateToCopy.getUTCMonth() + 1) > 9 ? (dateToCopy.getUTCMonth() + 1) : '0' + (dateToCopy.getUTCMonth() + 1)}.${dateToCopy.getDate() > 9 ? dateToCopy.getDate() : '0' + dateToCopy.getDate()}.`)
		}
	}

	cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.isUploadConfirmOpen = false;
	}

	setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
		console.log('Kiválasztott file:', this.file)
		if (this.file.name.endsWith(".pdf")) {
			console.log("pdf file")
			this.isFilePdf = true;
		}
		if (!this.file.name.endsWith(".pdf")) {
			console.log("not pdf file")
			this.isFilePdf = false;
		}
	}

	setIsUploadConfirmOpen = () => {
		this.isUploadConfirmOpen = true;
	}

	setHasStreetNamesPermaFalse = () => {
		this.hasStreetNamesPerma = false;
		this.offer.PermanentAddressName = "";
		this.offer.IsPermaAdddressNameChanged = true;
	}

	setHasStreetNamesTemporaryFalse = () => {
		this.hasStreetNamesTemporary = false;
		this.offer.TemporaryAddressName = "";
		this.offer.IsTemporaryAdddressNameChanged = true;
	}

	setIsInformationObligationMMEditable() {
		this.isInformationObligationMMEditable = true;
	}

	setShowInformationObligationPop() {
		this.showInformationObligationPop = true;
	}

	cancelInformationObligationPop(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		this.showInformationObligationPop = false;
	}

	*confirmInformationObligationPop() {
		let result: boolean = yield this.OfferService.SetOfferIsInformationObligationRead(this.offer.Id);
		if (result == true) {
			this.offer.IsInformationObligationRead = true;
			this.showInformationObligationPop = false;
		}
	}

	*cloneOffer() {
		this.offerIdForClone = yield this.OfferService.CloneOffer(this.offer.Id)
		window.open(`/#/offer/${this.offerIdForClone}`, '_blank')
	}

	*getsignedDocument(docId: number) {
		this.RootStore?.addloading('getsignedDocument')

		let result: string = yield this.OfferService.CheckSignedDocument(this.offer.Id, docId);
		if (result == 'success') {
			yield this.OfferService.GetSignedDocument(this.offer.Id, docId);
			this.GetDocumentsOfObject(this.offer.Id);
		} else {
			alert('Nem található aláírt példány a dokumentumból!');
		}


		this.RootStore?.removeLoading('getsignedDocument')
	}
	*sendDocumentToSigning(docId: number) {
		this.RootStore?.addloading('sendDocumentToSigning')

		yield this.OfferService.SendDocumentToSigning(this.offer.Id, docId);
		this.GetDocumentsOfObject(this.offer.Id);

		this.RootStore?.removeLoading('sendDocumentToSigning')

	}
	*saveOffer(navigateToPrintPackages?: boolean) {

		if (this.offer.MainStatus == 1500 && (this.offer.BPBankStatus == 3400 || this.offer.BPBankStatus == 3600) && this.selectedWfStep && this.selectedWfStep.ToStatusCode == 1800) {
			if (this.isBankLoanPopupConfirmed != true) {
				this.bankLoanPopup = true;
				return;
			}

		}

		let signedVersionAlert = false;

		if (this.offer.MainStatus == 1100 && this.selectedWfStep && this.selectedWfStep.ToStatusCode == 1000) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				const element = this.offer.Packages[i];
				let tariffPackage = this.tariffPackageItems.find(a => a.Id == element.PackageId && a.PackagePrice != element.PackagePrice);
				if (tariffPackage) {

					this.RootStore.packagePriceError = true;
				}
			}
			if (!this.RootStore.packagePriceError) {
				console.log(this.saveConfirmIsOpen)
				console.log(this.offer)
			}
		}
		else if (this.selectedWfStep && this.selectedWfStep.ToStatusCode == 2700) {

			let documents: any[] = yield this.OfferService.GetDocumentsOfObject(this.offer.Id);
			for (let i = 0; i < documents.length; i++) {
				const element = documents[i];
				if (!element.HasSignedVersion && element.Type > 1 && element.Type != 3 && element.Type != 7 && element.Type != 11 && element.Type != 6) {
					signedVersionAlert = true;
				}
			}

		}
		let index = 0;
		let indexWarrantyPAckage = 0;
		let gplusAlert = false;
		for (let i = 0; i < this.offer.Packages.length; i++) {
			if (this.offer.Packages[i].IsMothlyFeeGPlus) {
				index++;
			}
			if (this.offer.Packages[i].WarrantyPackageId > 0) {
				indexWarrantyPAckage++;
			}
		}
		if (index > 0 && indexWarrantyPAckage > 0) {
			alert("Az ajánlat nem léptethető tovább, mert egyszeri és havídíjas garancia csomag is kiválasztásra került!");
			return;
		}
		// todo ide kell majd hogy ellenőrizzük hogy van e és töltsék is le az aláírt verziókat
		if (signedVersionAlert) {
			alert("Az ajánlat nem léptethető tovább addig, amíg minden aláírt dokumentumot le nem töltöttek!");
			return;

		}
		if (this.RootStore.packagePriceError) {
			alert("Az ajánlat nem indítható újra, mert leglább egy tarifacsomagnak az ára megváltozott!");
			return;
		}





		if (this.selectedWfStep && this.selectedWfStep.Id !== 0 && this.selectedWfStep.Id !== 4) {
			this.isSaved = true;

			if (this.hasValidationErrors) {
				if (this.selectedWfStep) {
					console.log('this.selectedWfStep', this.selectedWfStep)
				}
				return;
			}

			if (this.offer.Packages) {
				let message = '';
				for (let i = 0; i < this.offer.Packages.length; i++) {
					var offerPackageItem = this.offer.Packages[i];


					if (((this.offer.OnlinePickupEditMode || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.StoreAdmin) && (!offerPackageItem.SimNumberOP || offerPackageItem.SimNumberOP === '')) && this.offer.MainStatus >= 1500 && this.offer.MainStatus != 1700) {
						message += '\n' + `${i + 1}. csomag SIM-kártya száma (Online pickup) kitöltése kötelező!`
					}
				}
				if (message != '') {
					alert(message);
					return;
				}
			}

			if (this.offer.Packages) {
				let message = '';
				for (let i = 0; i < this.offer.Packages.length; i++) {
					var offerPackageItem = this.offer.Packages[i];


					if (!offerPackageItem.PackageArticleNumber && offerPackageItem.DurationId === 2) {
						message += '\n' + `${i + 1}. csomag Adminisztrációs díj cikkszáma hiányzik!`
					}
				}
				if (message != '') {
					alert(message);
					return;
				}
			}



		}


		this.RootStore?.addloading('SaveOffer')
		// if (this.offer.Comment) {
		// 	this.offer.MainStatus = 0;
		// 	this.offer.VodafoneStatus = 0;
		// 	this.offer.BPBankStatus = 0;
		// 	this.offer.AEGONStatus = 0;			
		// }
		let postData = {
			Offer: this.offer,
			SelectedStep: this.selectedWfStep,
			Decision: {},
			DueDate: null as any,
			ResponsibleUsers: [] as any,


		};
		if (this.selectedWfStep) {
			if (this.selectedWfStep.FromWfType == 1) {
				postData.Decision = this.offer.MainDecision;
			}
			if (this.selectedWfStep.FromWfType == 2) {
				postData.Decision = this.offer.VodafoneDecision;
			}
			if (this.selectedWfStep.FromWfType == 3) {
				postData.Decision = this.offer.BpBankDecision;
			}
		}
		if (this.offer.Id == 0) {
			this.offer.StoreSAPCode = this.RootStore.selectedStore;
		}



		this.offer.Id = yield this.OfferService.SendNextWFStep(postData);
		if (this.saveLandingPage) {
			this.RootStore?.removeLoading('SaveOffer')
			this.navigate(`/savelandingpage/${this.offer.Id}`)
		} else if (navigateToPrintPackages) {
			this.RootStore?.removeLoading('SaveOffer')
			// this.navigate(`/print-package/${this.offer.Id}`)
			window.open(`/#/print-package/${this.offer.Id}`, '_blank')
		} else {
			// this.offer.Id = yield this.OfferService.SaveOffer(this.offer);
			console.log('this.offer.Id', this.offer.Id)
			this.offer = yield this.OfferService.GetOfferById(this.offer.Id)
			this.offer.Comment = String('');
			if (this.offer.PermanentAddressZipCode) {
				this.getStreetNamesAfterAddressZipCodeSelect(true);
			}
			if (this.offer.TemporaryAddressZipCode) {
				this.getStreetNamesAfterAddressZipCodeSelect(false);
			}
			this.setPermanentAddressType();
			this.setTemporaryAddressType();
			this.setOfferPackagesManufacturerWarranties();
			this.setOfferPackagesWarrantyPackages();
			if (this.offer.Packages) {
				this.selectedItem = this.offer.Packages[0];
				this.selectedItem.IsSelected = true;
			}

			this.saveConfirmIsOpen = false;
			this.RootStore?.removeLoading('SaveOffer')
		}



	}

	* getDeviceByQueryText() {
		this.devicesByQueryText = yield this.OfferService.GetDeviceByQueryText(this.queryText, this.RootStore.selectedStore)
	}

	* getScreenProtectorByQueryText() {
		this.screenProtectorsByQueryText = yield this.OfferService.GetScreenProtectorsByQueryText(this.screenProtectorQueryText, this.RootStore.selectedStore)
	}

	* getAccessoryByQueryText() {
		this.accessoriesByQueryText = yield this.OfferService.GetAccessoriesByQueryText(this.accessoryQueryText, this.RootStore.selectedStore)
	}

	* getSettlementNameByQueryText() {
		this.settlementsByQueryText = yield this.OfferService.GetSettlementsByZipCodeQuery(this.settlementQueryText)
	}

	* getTemporarySettlementNameByQueryText() {
		this.temporarySettlementsByQueryText = yield this.OfferService.GetSettlementsByZipCodeQuery(this.temporarySettlementQueryText)
	}

	* getTariffPackages() {
		this.tariffPackageItems = yield this.AdminService.GetTariffPackages();
		this.setTariffPackageIsHighest();


	}

	* getWarrantyPlusPackeges() {
		this.warrantyPlusItems = yield this.AdminService.GetWarrantyPlusPackegesOffer(false);
	}

	* getManufacturerWarranties() {
		this.manufacturerWarrantyOptions = yield this.AdminService.GetManufacturerWarranties();
		this.setOfferPackagesManufacturerWarranties();
	}

	*getInformationObligationItems() {
		this.informationObligationItems = yield this.AdminService.GetInformationObligations(true);
		for (let i = 0; i < this.informationObligationItems.length; i++) {
			if (!this.informationObligationItemOptions.some(io => io.value === this.informationObligationItems[i].Id)) {
				this.informationObligationItemOptions.push(
					{
						value: this.informationObligationItems[i].Id,
						text: this.informationObligationItems[i].InformationType,
					}
				)
			}
		}
		console.log('this.informationObligationItemOptions.informationObligationItemOptions.informationObligationItemOptions', this.informationObligationItemOptions)
	}

	*getInformationObligationItemById() {
		yield this.getInformationObligationItems();
		console.log('-----------informationObligationItems-----------informationObligationItems', this.informationObligationItems)
		if (this.offer.InformationObligationId) {
			let item: InformationObligation = yield this.AdminService.GetInformationObligationById(this.offer.InformationObligationId);
			if (item) {
				//this.selectedInformationObligationId = item.Id;		
				if (item.IsDeleted && !this.informationObligationItemOptions.some(io => io.value === item.Id)) {
					this.informationObligationItemOptions.push(
						{
							value: item.Id,
							text: item.InformationType,
						}
					)
				}
				console.log('this.selectedInformationObligationIdthis.selectedInformationObligationIdthis.selectedInformationObligationIdthis.selectedInformationObligationId', this.offer.InformationObligationId);
				console.log('this.informationObligationItemOptionsthis.informationObligationItemOptionsthis.informationObligationItemOptionsthis.informationObligationItemOptions', toJS(this.informationObligationItemOptions));
			}
		}
	}

	* getDeviceDiscountForOfferPackageDevice() {
		var tariffPackageId = this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).Id
		this.selectedItem.DeviceDiscount = yield this.AdminService.GetDeviceDiscountForOfferPackageDevice(tariffPackageId, this.selectedItem.DeviceArticleNumber)
		console.log(this.selectedItem.DeviceDiscount)
		this.setOfferPayableAmount();
		this.setOfferPackageItemWarrantyPackages();
	}

	* getHighestDeviceDiscount() {
		var tariffPackageId = this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).Id
		this.selectedItem.ActualDeviceDiscount = yield this.AdminService.GetHighestDeviceDiscount(tariffPackageId, this.selectedItem.DeviceArticleNumber)
		console.log(this.selectedItem.DeviceDiscount)
		// this.setOfferPayableAmount();
	}
	*getPackageDiscount() {
		var tariffPackageId = this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).Id;
		let retVal: DeviceDiscount = null;
		retVal = yield this.AdminService.GetPackageDiscount(tariffPackageId, this.selectedItem.DeviceArticleNumber);
		return retVal;

	}
	*getPercentegaDiscount() {
		var tariffPackageId = this.tariffPackageItems.find(x => x.Id == this.selectedItem.PackageId).Id;
		let retVal: DeviceDiscount = null;
		retVal = yield this.AdminService.GetPercentegaDiscount(tariffPackageId, this.selectedItem.DeviceArticleNumber);
		return retVal;

	}
	* getStreetNamesAfterAddressZipCodeSelect(perma: boolean, nullAddressname?: boolean) {
		if (this.offer.PermanentAddressZipCode && perma) {
			if ((this.offer.PermanentAddressZipCode >= 1000 && this.offer.PermanentAddressZipCode <= 1239) ||
				(this.offer.PermanentAddressZipCode >= 3500 && this.offer.PermanentAddressZipCode <= 3558) ||
				(this.offer.PermanentAddressZipCode >= 4000 && this.offer.PermanentAddressZipCode <= 4225) ||
				(this.offer.PermanentAddressZipCode >= 6700 && this.offer.PermanentAddressZipCode <= 6791) ||
				(this.offer.PermanentAddressZipCode >= 7600 && this.offer.PermanentAddressZipCode <= 7693) ||
				(this.offer.PermanentAddressZipCode >= 9000 && this.offer.PermanentAddressZipCode <= 9030)) {
				if (nullAddressname == true) {
					this.offer.PermanentAddressName = null;
				}
				if (this.offer.IsPermaAdddressNameChanged == false) {
					this.hasStreetNamesPerma = true;
					this.permanentAddressTypeOptions = yield this.OfferService.GetStreetNamesAfterAddressZipCodeSelect(this.offer.PermanentAddressZipCode)
					if (this.offer.PermanentAddressName) {
						this.offer.PermanentAddressName = this.permanentAddressTypeOptions.find(x => x.value == Number(this.offer.PermanentAddressName)).value
						var debug = 0;
					}
				}

			}
			else {
				this.hasStreetNamesPerma = false;
				if (nullAddressname == true) {
					this.offer.PermanentAddressName = null;
				}
			}
		}
		if (this.offer.TemporaryAddressZipCode && !perma) {
			if ((this.offer.TemporaryAddressZipCode >= 1000 && this.offer.TemporaryAddressZipCode <= 1239) ||
				(this.offer.TemporaryAddressZipCode >= 3500 && this.offer.TemporaryAddressZipCode <= 3558) ||
				(this.offer.TemporaryAddressZipCode >= 4000 && this.offer.TemporaryAddressZipCode <= 4225) ||
				(this.offer.TemporaryAddressZipCode >= 6700 && this.offer.TemporaryAddressZipCode <= 6791) ||
				(this.offer.TemporaryAddressZipCode >= 7600 && this.offer.TemporaryAddressZipCode <= 7693) ||
				(this.offer.TemporaryAddressZipCode >= 9000 && this.offer.TemporaryAddressZipCode <= 9030)) {
				if (nullAddressname == true) {
					this.offer.TemporaryAddressName = null;
				}
				if (this.offer.IsTemporaryAdddressNameChanged == false) {
					this.hasStreetNamesTemporary = true;
					this.temporaryAddressTypeOptions = yield this.OfferService.GetStreetNamesAfterAddressZipCodeSelect(this.offer.TemporaryAddressZipCode)
					if (this.offer.TemporaryAddressName) {
						this.offer.TemporaryAddressName = this.temporaryAddressTypeOptions.find(x => x.value == Number(this.offer.TemporaryAddressName)).value
						var debug = 0;
					}
				}
			}
			else {
				this.hasStreetNamesTemporary = false;
				if (nullAddressname == true) {
					this.offer.TemporaryAddressName = null;
				}
			}
		}
	}

	* setAddressType() {
		var streetName = this.permanentAddressTypeOptions.find(x => x.value == this.offer.PermanentAddressName).text;
		this.offer.PermanentAddressType = yield this.OfferService.GetAddressNameSuffix(streetName, this.offer.PermanentAddressZipCode);
	}

	* setTemporaryAddressTypeSuffix() {
		var streetName = this.temporaryAddressTypeOptions.find(x => x.value == this.offer.TemporaryAddressName).text;
		this.offer.TemporaryAddressType = yield this.OfferService.GetAddressNameSuffix(streetName, this.offer.TemporaryAddressZipCode);
	}

	* setDocumentCheckDate(isSuccessful: boolean) {
		this.OfferService.SetDocumentCheckDate(this.offer.Id, isSuccessful)
	}

	* getTariffPackagesLnumber() {
		this.latestRateCommission = yield this.AdminService.GetLastRateCommission();
		console.log('tarifacsomag L cikkszam:', toJS(this.latestRateCommission));
	}

	* confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		this.isUploadConfirmOpen = false;
		let formData: FormData = new FormData();
		this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
		formData.append('uploadFile', this.file, this.file.name);
		yield this.OfferService.UploadOfferDocument(formData, this.selectedDocumentTypeNumber, this.offer.Id);
		this.selectedDocumentTypeNumber = 0;
		this.GetDocumentsOfObject(this.offer.Id);
		console.log(this.file)
	}

	// get getReadonlyOfferPackage() {
	// 	let readonly: Partial<Record<keyof any, boolean>> = {}

	// 	if (!this.offer.EditMode) {
	// 		const readonlyFields : Partial<keyof any>[]= ["DurationId", "PackageId", "YouOption", "Family", "NumberPortability", "VFCSCustomer", "NewPhoneNumber", "SimNumber", "SimNumberOP", "IMEINumber", 
	// 														 "DeviceId", "Price", "FactoryWarrantyDuration", "UseManufacturerWarrantyExtension", "ManufacturerWarrantyExtensionDuration", "HasScreenProtector", 
	// 														"ScreenProtectorId", "WarrantyPackageId"]
	// 		readonlyFields.forEach(field => {
	// 			readonly[field] = true
	// 		})
	// 	}

	// 	return readonly
	// }

	// get getReadonlyPersonalData() {
	// 	let readonly : Partial<Record<keyof any, boolean>> = {}

	// 	if (!this.offer.EditMode) {
	// 		const readonlyFields : Partial<keyof any>[]= ["CustomerTitle","LastName", "FirstName", "MothersLastName", "MothersFirstName", "BirthPlace", "BirthDate", "BirthLastName", "BirthFirstName", 
	// 														"GenderForVodafone", "Gender", "IdentityNumber", "IdentityType", "MobileNumber", "MobileNumberPrefix", "Email", "Comment"]
	// 		readonlyFields.forEach(field => {
	// 			readonly[field] = true
	// 		})
	// 	}

	// 	return readonly
	// }

	// get getReadonlyAddress() {
	// 	let readonly : Partial<Record<keyof any, boolean>> = {}

	// 	if (!this.offer.EditMode) {
	// 		const readonlyFields : Partial<keyof any>[]= ["PermanentAddressZipCode", "PermanentAddressCity", "PermanentAddressName", "PermanentAddressType", "PermanentAddressHouseNumber", "PermanentAddressBuilding", 
	// 														"PermanentAddressStaircase", "PermanentAddressStorey", "PermanentAddressDoor", "IsHRSZ", "HRSZ", "TemporaryAddressZipCode", 
	// 														"TemporaryAddressCity", "TemporaryAddressName", "TemporaryAddressType", "TemporaryAddressHouseNumber", "TemporaryAddressBuilding", 
	// 														"TemporaryAddressStaircase", "TemporaryAddressStorey", "TemporaryAddressDoor" ]
	// 		readonlyFields.forEach(field => {
	// 			readonly[field] = true
	// 		})
	// 	}

	// 	return readonly
	// }

	// get getReadonly() {
	// 	return { ...this.getReadonlyPersonalData, ...this.getReadonlyAddress }
	// }

	get getIsDisabled() {
		if (this.offer.EditMode || this.offer.OnlinePickupEditMode || this.offer.BPBankEdigMode) {
			return false;
		}
		return true;
	}

	get getValidationOfferPackageErrors() {
		if (!this.isSaved || (this.selectedWfStep && this.selectedWfStep.Id == 1000)) {
			return {}
		}

		let validationErrors: Partial<Record<any, string>> = {}

		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var offerPackageItem = this.offer.Packages[i];

				if (offerPackageItem.PackageId === 0) {
					validationErrors["PackageId" + i] = `${i + 1}. csomag Tarifacsomag választása kötelező!`
				}

				if (this.selectedWfStep && this.selectedWfStep.ToStatusCode === 2300 && (this.RootStore.currentUser.VodafoneBO || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.StoreAdmin) && (!offerPackageItem.NewPhoneNumber || offerPackageItem.NewPhoneNumber === '')) {
					validationErrors["NewPhoneNumber" + i] = `${i + 1}. csomag Új telefonszám kitöltése kötelező!`
				}

				if (this.selectedWfStep && this.selectedWfStep.ToStatusCode === 2300 && (this.RootStore.currentUser.VodafoneBO || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.VodafoneSupervisor) && (!offerPackageItem.ContractIdentifier || offerPackageItem.ContractIdentifier === '')) {
					validationErrors["ContractIdentifier" + i] = `${i + 1}. csomag Szerződés azonosító kitöltése kötelező!`
				}

				if (this.RootStore.currentUser.VodafoneBO && offerPackageItem.NewPhoneNumber && offerPackageItem.NewPhoneNumber.length !== 12) {
					validationErrors["NewPhoneNumberLength" + i] = `${i + 1}. csomag Új telefonszám mezőnek pontosan 9 karakternek kell lennie!`
				}

				if (!offerPackageItem.SimNumber || offerPackageItem.SimNumber === '') {
					validationErrors["SimNumber" + i] = `${i + 1}. csomag SIM-kártya száma (szaktanácsadó) kitöltése kötelező!`
				}

				if (offerPackageItem.SimNumber && offerPackageItem.SimNumber.length != 20) {
					validationErrors["SimNumberCharacterLength" + i] = `${i + 1}. csomag SIM-kártya száma (szaktanácsadó) mezőnek pontosan 20 karakternek kell lennie!`
				}
				if (((this.offer.OnlinePickupEditMode || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.StoreAdmin) && (!offerPackageItem.SimNumberOP || offerPackageItem.SimNumberOP === '')) && this.offer.MainStatus >= 1500 && this.offer.MainStatus != 1700) {
					validationErrors["SimNumber" + i] = `${i + 1}. csomag SIM-kártya száma (Online pickup) kitöltése kötelező!`
				}
				if ((this.offer.OnlinePickupEditMode || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.StoreAdmin) && offerPackageItem.SimNumberOP && offerPackageItem.SimNumber && (offerPackageItem.SimNumberOP !== offerPackageItem.SimNumber) && this.offer.MainStatus === 1500) {
					validationErrors["SimNumberOPDiffers" + i] = `${i + 1}. csomag SIM-kártya száma (szaktanácsadó) mező értéke nem egyezik a SIM-kártya száma (Online pickup) mező értékével!`
				}

				if ((!offerPackageItem.IMEINumber || offerPackageItem.IMEINumber === '') && offerPackageItem.DurationId === 2) {
					validationErrors["IMEINumber" + i] = `${i + 1}. csomag Készülék IMEI-száma kitöltése kötelező!`
				}

				if (offerPackageItem.IMEINumber && offerPackageItem.IMEINumber.length != 15 && offerPackageItem.DurationId === 2) {
					validationErrors["IMEINumberCharacterLength" + i] = `${i + 1}. csomag Készülék IMEI-száma mezőnek pontosan 15 karakternek kell lennie!`
				}

				if (offerPackageItem.NewPhoneNumber && offerPackageItem.NewPhoneNumber.length != 12) {
					validationErrors["NewPhoneNumberCharacterLength" + i] = `${i + 1}. csomag Új telefonszám mezőnek pontosan 9 karakternek kell lennie!`
				}

				if (offerPackageItem.PreToPost === 1 && (!offerPackageItem.CurrentPrepaidPhoneNumber || offerPackageItem.CurrentPrepaidPhoneNumber === '')) {
					validationErrors["CurrentPrepaidPhoneNumber" + i] = `${i + 1}. csomag Jelenlegi feltöltőkártyás telefonszám kitöltése kötelező!`
				}

				if (offerPackageItem.PreToPost === 1 && offerPackageItem.CurrentPrepaidPhoneNumber && offerPackageItem.CurrentPrepaidPhoneNumber.length != 12) {
					validationErrors["CurrentPrepaidPhoneNumberCharacterLength" + i] = `${i + 1}. csomag Jelenlegi feltöltőkártyás telefonszám mezőnek pontosan 9 karakternek kell lennie!`
				}

				if (offerPackageItem.NumberPortability === 1 && (!offerPackageItem.PhoneNumberToBeCarried || offerPackageItem.PhoneNumberToBeCarried === '')) {
					validationErrors["PhoneNumberToBeCarried" + i] = `${i + 1}. csomag Hordozni kívánt telefonszám kitöltése kötelező!`
				}

				if (offerPackageItem.NumberPortability === 1 && offerPackageItem.PhoneNumberToBeCarried && offerPackageItem.PhoneNumberToBeCarried.length != 12) {
					validationErrors["PhoneNumberToBeCarriedCharacterLength" + i] = `${i + 1}. csomag Hordozni kívánt telefonszám mezőnek pontosan 9 karakternek kell lennie!`
				}

				if (offerPackageItem.DurationId === 2 && offerPackageItem.DeviceId === 0) {
					validationErrors["DeviceId" + i] = `${i + 1}. csomag Készülék választása kötelező!`
				}

				if (offerPackageItem.HasScreenProtector && offerPackageItem.ScreenProtectorId === 0) {
					validationErrors["ScreenProtectorId" + i] = `${i + 1}. csomag Kijelzővédő típusának választása kötelező!`
				}
			}
		}


		return validationErrors
	}

	get getValidationPersonalDataErrors() {
		if (!this.isSaved || (this.selectedWfStep && this.selectedWfStep.Id == 1000)) {
			return {}
		}

		let validationErrors: Partial<Record<keyof any, string>> = {}

		if (!this.offer.LastName) {
			validationErrors["LastName"] = "Név / Vezetéknév kitöltése kötelező!"
		}
		if (!this.offer.FirstName) {
			validationErrors["FirstName"] = "Név / Keresztnév kitöltése kötelező!"
		}
		if (!this.offer.MothersLastName) {
			validationErrors["MothersLastName"] = "Anyja neve / Vezetéknév kitöltése kötelező!"
		}
		if (!this.offer.MothersFirstName) {
			validationErrors["MothersFirstName"] = "Anyja neve / Keresztnév kitöltése kötelező!"
		}
		if (!this.offer.BirthPlace) {
			validationErrors["BirthPlace"] = "Születési hely kitöltése kötelező!"
		}
		if (!this.offer.BirthDate) {
			validationErrors["BirthDate"] = "Születési dátum kitöltése kötelező!"
		}
		if (this.offer.BirthDate) {

			const BirthDate = new Date(this.offer.BirthDate)
			if (new Date(BirthDate.getFullYear() + 18, BirthDate.getMonth(), BirthDate.getDate()) >= new Date()) {
				validationErrors["BirthDateUnderEighteen"] = "Legalább 18 éves kor megléte kötelező!"
			}
		}
		if (!this.offer.GenderForVodafone) {
			validationErrors["GenderForVodafone"] = "Neme - Szolgáltatói szerződéshez kitöltése kötelező!"
		}
		if (!this.offer.Gender) {
			validationErrors["Gender"] = "Neme kitöltése kötelező!"
		}
		if (!this.offer.IdentityNumber) {
			validationErrors["IdentityNumber"] = "Azonosító okmány száma kitöltése kötelező!"
		}
		if (!this.offer.IdentityType) {
			validationErrors["IdentityType"] = "Azonosító okmány típusa kitöltése kötelező!"
		}
		if (!this.offer.AddressCardNumber || this.offer.AddressCardNumber === '') {
			validationErrors["AddressCardNumber"] = "Lakcímet igazoló hatósági igazolvány száma kitöltése kötelező!"
		}
		if (this.offer.AddressCardNumber && this.offer.AddressCardNumber.length != 8) {
			validationErrors["AddressCardNumberCharacterLength"] = "Lakcímet igazoló hatósági igazolvány számának pontosan 8 karakternek kell lennie!"
		}
		if (!this.offer.Email) {
			validationErrors["Email"] = "E-mail cím kitöltése kötelező!"
		}

		if (this.offer.Email && this.offer.Email.indexOf('@') === -1) {
			validationErrors["EmailContainsAt"] = "E-mail cím nem valós érték!"
		}
		if (!this.offer.MobileNumber) {
			validationErrors["CustomerDataMobileNumber"] = "Elérhetőségi adatok Mobiltelefonszám mező kötelező!"
		}

		if (this.offer.MobileNumber && this.offer.MobileNumber.length != 7) {
			validationErrors["CustomerDataMobileNumber"] = "Elérhetőségi adatok Mobiltelefonszám mezőnek pontosan 7 karakternek kell lennie!"
		}

		if (this.offer.IsGDPRAccepted !== true) {
			validationErrors["IsGDPRAccepted"] = "Adatvédelmi nyilatkozat elfogadása kötelező!"
		}


		if (!this.offer.VodafoneCustomerSecurityCode) {
			validationErrors["VodafoneCustomerSecurityCode"] = "One ügyfélbiztonsági kód kötelező!"
		} else {
			//4-6
			const regex46 = new RegExp('(^.{4,6})$');
			//ugyanaz
			const regexSame = new RegExp('\\b(\\d)\\1+\\b');
			//19-20
			const regex1920 = new RegExp('(^19)|(^20)');
			//emelkedő, csökkenő
			const regexLtGt = new RegExp('^(0123|1234|2345|3456|4567|5678|6789|01234|12345|23456|34567|45678|56789|012345|123456|234567|345678|456789|9876|8765|7654|6543|5432|4321|3210|98765|87654|76543|65432|54321|43210|987654|876543|765432|654321|543210)$');

			if (!regex46.test(this.offer.VodafoneCustomerSecurityCode.toString())) {
				validationErrors["VodafoneCustomerSecurityCode"] = "One ügyfélbiztonsági kód min. 4 karakter és max 6 karakter lehet!"

			} else if (regexSame.test(this.offer.VodafoneCustomerSecurityCode.toString())) {
				validationErrors["VodafoneCustomerSecurityCode"] = "One ügyfélbiztonsági kód nem tartalmazhat egyező számokat (pl.: 1111, 2222 stb.)!"

			} else if (regex1920.test(this.offer.VodafoneCustomerSecurityCode.toString())) {
				validationErrors["VodafoneCustomerSecurityCode"] = "One ügyfélbiztonsági kód nem kezdődhet 19 és 20 számokkal!"

			} else if (regexLtGt.test(this.offer.VodafoneCustomerSecurityCode.toString())) {
				validationErrors["VodafoneCustomerSecurityCode"] = "One ügyfélbiztonsági kód nem állhat növekvő vagy csökkenő számsorozatból (pl.: 1234, 4321 stb.)!"

			}


		}


		if (this.selectedWfStep && this.selectedWfStep.ToStatusCode === 2300) {
			var docItem2 = this.documents.find(x => x.Type === 2)
			if (docItem2 == null || docItem2 == undefined) {
				validationErrors["DocItem2"] = "Előfizetői szerződés feltöltése kötelező!"
			}


			// var docItem3 = this.documents.find(x => x.Type === 3) 
			// if (docItem3 == null || docItem3 == undefined) {
			// 	validationErrors["DocItem3"] = "Egyéb dokumentum feltöltése kötelező!"
			// }
			// var docItem4 = this.documents.find(x => x.Type === 4) 
			// if (docItem4 == null || docItem4 == undefined) {
			// 	validationErrors["DocItem4"] = "Egyedi előfizetői szerződés feltöltése kötelező!"
			// }
			// var docItem5 = this.documents.find(x => x.Type === 5) 
			// if (docItem5 == null || docItem5 == undefined) {
			// 	validationErrors["DocItem5"] = "ÁSZF feltöltése kötelező!"
			// }	
		}

		if (this.selectedWfStep && this.selectedWfStep.ToStatusCode === 1800) {
			var docItem2 = this.documents.find(x => !x.ScriveId && x.Type > 1 && x.Type != 3 && x.Type != 11 && x.Type != 7 && x.Type != 6)
			if (!(docItem2 == null || docItem2 == undefined)) {
				validationErrors["DocSigning"] = "Minden feltöltött dokumentum aláírása kötelező!"
			}


			// var docItem3 = this.documents.find(x => x.Type === 3) 
			// if (docItem3 == null || docItem3 == undefined) {
			// 	validationErrors["DocItem3"] = "Egyéb dokumentum feltöltése kötelező!"
			// }
			// var docItem4 = this.documents.find(x => x.Type === 4) 
			// if (docItem4 == null || docItem4 == undefined) {
			// 	validationErrors["DocItem4"] = "Egyedi előfizetői szerződés feltöltése kötelező!"
			// }
			// var docItem5 = this.documents.find(x => x.Type === 5) 
			// if (docItem5 == null || docItem5 == undefined) {
			// 	validationErrors["DocItem5"] = "ÁSZF feltöltése kötelező!"
			// }	
		}

		if (this.selectedWfStep && this.selectedWfStep.ToStatusCode === 2300 && (this.RootStore.currentUser.VodafoneBO || this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.VodafoneSupervisor) && (!this.offer.SubscriberContractNumber || this.offer.SubscriberContractNumber === '')) {
			validationErrors["SubscriberContractNumber"] = "Előfizetői szerződésszám kitöltése kötelező!"
		}
		if (this.selectedWfStep && (this.selectedWfStep.ToStatusCode === 3400 || this.selectedWfStep.ToStatusCode === 3600) && !this.offer.MKBTransactionNumber) {
			validationErrors["MKBTransactionNumber"] = "Banki tranzakciószám/Cenzúraszám kitöltése kötelező!"
		}
		return validationErrors
	}

	get getValidationAddressErrors() {
		if (!this.isSaved || (this.selectedWfStep && this.selectedWfStep.Id == 1000)) {
			return {}
		}

		let validationErrors: Partial<Record<keyof any, string>> = {}

		if (!this.offer.PermanentAddressZipCode) {
			validationErrors["PermanentAddressZipCode"] = "Állandó lakcím / Irányítószám kitöltése kötelező!"
		}
		// if (!this.offer.PermanentAddressZipCode && this.settlementQueryText?.length != 4) {
		// 	validationErrors["PermanentAddressZipCodeFourCharacterLength"] = "Állandó lakcím / Irányítószám mezőnek pontosan 4 számjegyűnek kell lennie!"
		// }
		// if ((this.settlementQueryText?.length === 4) && (!this.offer.PermanentAddressZipCode)) {
		// 	validationErrors["PermanentAddressZipCodeNotValid"] = "Állandó lakcím / Irányítószám nem valós érték!"
		// }
		if (!this.offer.PermanentAddressCity) {
			validationErrors["PermanentAddressCity"] = "Állandó lakcím / Város kitöltése kötelező!"
		}
		if (!this.offer.PermanentAddressName && !this.offer.IsHRSZ) {
			validationErrors["PermanentAddressName"] = "Állandó lakcím / Közterület neve kitöltése kötelező!"
		}
		if (!this.offer.PermanentAddressType && !this.offer.IsHRSZ) {
			validationErrors["PermanentAddressType"] = "Állandó lakcím / Közterület jellege kitöltése kötelező!"
		}
		if (!this.offer.PermanentAddressHouseNumber && !this.offer.IsHRSZ) {
			validationErrors["PermanentAddressHouseNumber"] = "Állandó lakcím / Házszám kitöltése kötelező!"
		}
		if (this.offer.IsHRSZ === 1 && !this.offer.HRSZ) {
			validationErrors["HRSZ"] = "Állandó lakcím helyrajzi szám kitöltése kötelező!"
		}
		if (this.offer.IsTemporaryHRSZ === 1 && !this.offer.TemporaryHRSZ) {
			validationErrors["TemporaryHRSZ"] = "Tartózkodási lakcím helyrajzi szám kitöltése kötelező!"
		}
		if (!this.offer.TemporaryAddressZipCode) {
			validationErrors["TemporaryAddressZipCode"] = "Tartózkodási lakcím / Irányítószám kitöltése kötelező!"
		}
		// if (!this.offer.TemporaryAddressZipCode && this.temporarySettlementQueryText?.length != 4) {
		// 	validationErrors["TemporaryAddressZipCodeFourCharacterLength"] = "Tartózkodási lakcím / Irányítószám mezőnek pontosan 4 számjegyűnek kell lennie!"
		// }
		// if ((this.temporarySettlementQueryText?.length === 4) && (!this.offer.TemporaryAddressZipCode)) {
		// 	validationErrors["TemporaryAddressZipCodeNotValid"] = "Tartózkodási lakcím / Irányítószám nem valós érték!"
		// }
		if (!this.offer.TemporaryAddressCity) {
			validationErrors["TemporaryAddressCity"] = "Tartózkodási lakcím / Város kitöltése kötelező!"
		}
		if (!this.offer.TemporaryAddressName && !this.offer.IsTemporaryHRSZ) {
			validationErrors["TemporaryAddressName"] = "Tartózkodási lakcím / Közterület neve kitöltése kötelező!"
		}
		if (!this.offer.TemporaryAddressType && !this.offer.IsTemporaryHRSZ) {
			validationErrors["TemporaryAddressType"] = "Tartózkodási lakcím / Közterület jellege kitöltése kötelező!"
		}
		if (!this.offer.TemporaryAddressHouseNumber && !this.offer.IsTemporaryHRSZ) {
			validationErrors["TemporaryAddressHouseNumber"] = "Tartózkodási lakcím / Házszám kitöltése kötelező!"
		}
		if (this.selectedWfStep && (this.selectedWfStep.ToStatusCode === 2500) && (!this.offer.Comment || this.offer.Comment == "")) {
			validationErrors["Comment"] = "Megjegyzés kitöltése kötelező!"
		}
		if (this.simNumberChangedByOnlinePickup && (!this.offer.Comment || this.offer.Comment == "")) {
			validationErrors["CommentAfterSimChange"] = "Megjegyzés kitöltése kötelező!"
		}

		return validationErrors
	}

	get getValidationErrors() {
		if (!this.isSaved || (this.selectedWfStep && this.selectedWfStep.Id == 1000)) {
			return {}
		}

		const allValidationErrors: Partial<Record<keyof any, string>> = { ...this.getValidationPersonalDataErrors, ...this.getValidationAddressErrors }
		// const valdiationErrors : Partial<Record<keyof any, string>> = {}

		// const keys  = Object.keys(allValidationErrors) as Partial<keyof any>[]
		// keys.forEach((key) => {
		// 	if (!this.getReadonly[key]) {
		// 		valdiationErrors[key] = allValidationErrors[key]
		// 	}
		// })

		return allValidationErrors
	}

	get getValidationErrorsOfferPackage() {
		if (!this.isSaved || (this.selectedWfStep && this.selectedWfStep.Id == 1000)) {
			return {}
		}

		const allValidationErrors: Partial<Record<keyof any, string>> = { ...this.getValidationOfferPackageErrors }
		// const valdiationErrors : Partial<Record<keyof any, string>> = {}

		// const keys  = Object.keys(allValidationErrors) as Partial<keyof any>[]
		// keys.forEach((key) => {
		// 	if (!this.getReadonlyOfferPackage[key]) {
		// 		valdiationErrors[key] = allValidationErrors[key]
		// 	}
		// })

		return allValidationErrors
	}

	get hasValidationErrors() {
		if (Object.keys(this.getValidationErrors).length === 0 && Object.keys(this.getValidationErrorsOfferPackage).length === 0) {
			return false;
		}

		return true
	}

	get userByQueryTextDDL() {
		return this.devicesByQueryText.map((device, index) => {
			return { title: device.Name + " - " + device.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft", item: device, key: index };
		})

	}

	get screenProtectorByQueryTextDDL() {
		return this.screenProtectorsByQueryText.map(screenProtector => {
			return { title: screenProtector.Name + " (" + screenProtector.ArticleNumber + ")", item: screenProtector };
		})

	}

	get accessoryByQueryTextDDL() {
		return this.accessoriesByQueryText.map((accessory, index) => {
			return { title: accessory.Name + " - " + accessory.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft", item: accessory, key: index };
		})

	}

	get settlementByQueryTextDDL() {
		return this.settlementsByQueryText.map((settlement, index) => {
			return { title: settlement.Name + " (" + settlement.ZipCode + ")", item: settlement, key: index };
		})

	}

	get temporarySettlementByQueryTextDDL() {
		return this.temporarySettlementsByQueryText.map((settlement, index) => {
			return { title: settlement.Name + " (" + settlement.ZipCode + ")", item: settlement, key: index };
		})

	}

	get leftPanelWidth() {
		if (this.activeTab === 'keszulek') {
			return this.showFullCart ? 1 : 12;
		}

		return 16;
	}

	get isLoanDemandable() {
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].DurationId === 2) {
					console.log('isLoanDemandable : true')
					return true
				}
			}
		}
		console.log('isLoanDemandable : false')
		return false
	}

	get allPackageTariffpackagePrice() {
		let result = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var tariffPackage = this.tariffPackageItems.find(x => x.Id == this.offer.Packages[i].PackageId)
				if (tariffPackage) {
					result += tariffPackage.PackagePrice;
				}
			}
		}

		return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	get allPackageAdministrationFeeSum() {
		let result = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var tariffPackage = this.tariffPackageItems.find(x => x.Id == this.offer.Packages[i].PackageId)
				if (tariffPackage && this.offer.Packages[i].DurationId === 2) {
					result += Math.round(tariffPackage.AdminsitrationFee / 22);
				}
			}
		}

		// var resSeparated = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';

		return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	get allPackageDevicePriceSum() {
		let result = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].DeviceId) {
					result += this.offer.Packages[i].Price
				}
			}
		}

		return result;
	}

	get allPackageDeviceDiscountSum() {
		let result = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				if (this.offer.Packages[i].DeviceId) {
					if (this.offer.Packages[i].DevicePackageDiscount) {
						result += (this.offer.Packages[i].Price - this.offer.Packages[i].DevicePackageDiscount)

					} else {
						result += this.offer.Packages[i].DeviceDiscount

					}
				}
			}
		}

		return result;
	}

	get allPackageAdministrationFeeForDeviceSum() {
		let result = 0;
		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var tariffPackage = this.tariffPackageItems.find(x => x.Id == this.offer.Packages[i].PackageId)
				if (tariffPackage && this.offer.Packages[i].DeviceName) {
					result += tariffPackage.AdminsitrationFee;
				}
			}
		}

		return result;
	}

	get deviceInstallment() {
		return Math.round(((this.allPackageDevicePriceSum - this.allPackageDeviceDiscountSum) - this.allPackageAdministrationFeeForDeviceSum) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	get allPackageWarrantyAndScreenProtectorSum() {
		let result = 0;

		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				var warrantyPackage = this.warrantyPlusItems.find(x => x.Id == this.offer.Packages[i].WarrantyPackageId)
				if (warrantyPackage) {
					result += warrantyPackage.Price;
				}
				if (this.offer.Packages[i].ScreenProtectorId > 0) {
					result += this.offer.Packages[i].ScreenProtectorPrice;
				}
			}
		}

		return result;
	}

	get allPackageWarrantyAndScreenProtectorSumMonthly() {
		return Math.round(this.allPackageWarrantyAndScreenProtectorSum / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	get accessoriesSum() {
		let result = 0;

		if (this.offer.Packages) {
			for (let i = 0; i < this.offer.Packages.length; i++) {
				let offerItem = this.offer.Packages[i]
				if (offerItem.Accessories.length > 0) {
					for (let j = 0; j < offerItem.Accessories.length; j++) {
						result += Number(offerItem.Accessories[j].AccessoryPrice)
					}
				}
			}
		}

		return result;
	}

	get accessoriesSumMonthly() {
		return Math.round(this.accessoriesSum / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
	}

	get getBirthDateValue() {
		if (this.offer.BirthDate) {
			return new Date(this.offer.BirthDate);
		}
		return null;
		// this.props.OfferStore.offer.BirthDate?new Date(this.props.OfferStore.offer.BirthDate):null
	}

	get getInformationTypeDisabled() {
		if (this.offer.VodafoneStatus == 2200 && (this.RootStore.currentUser.HoldingAdmin || this.RootStore.currentUser.VodafoneBO || this.RootStore.currentUser.VodafoneSupervisor)) {
			return false;
		}
		return true;
	}

	get getTariffPackageLNumberArticleNumber() {
		if (this.latestRateCommission.Lnumber === 1) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL1.toString()
		}
		if (this.latestRateCommission.Lnumber === 2) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL2.toString()
		}
		if (this.latestRateCommission.Lnumber === 3) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL3.toString()
		}
		if (this.latestRateCommission.Lnumber === 4) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL4.toString()
		}
		if (this.latestRateCommission.Lnumber === 5) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL5.toString()
		}
		if (this.latestRateCommission.Lnumber === 6) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL6.toString()
		}
		if (this.latestRateCommission.Lnumber === 7) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL7.toString()
		}
		if (this.latestRateCommission.Lnumber === 8) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL8.toString()
		}
		if (this.latestRateCommission.Lnumber === 9) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL9.toString()
		}
		if (this.latestRateCommission.Lnumber === 10) {
			return this.tariffPackageItems.find(x => x.Id === this.selectedItem?.PackageId)?.ArticleNumberL10.toString()
		}
		return 0;
	}
}