import { inject, observer } from "mobx-react";
import React from "react";
import { Button, Checkbox, Confirm, Dropdown, Grid, Header, Icon, Image, Input, Label, Radio, Search, Table, TableHeader, TableHeaderCell, TextArea } from "semantic-ui-react";
import { OfferStore } from "../pages/offer/OfferStore";
import { CustomHeader } from "./CustomHeader";
import '../index.css'
import NumberFormat from 'react-number-format';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import '../../../index.css'

interface IProps {
  OfferStore?: OfferStore;
}

class PhoneDetailts extends React.Component<IProps> {

  public render() {
    const { OfferStore } = this.props;

    return (
      <div className="left-container">
        <CustomHeader>Tarifacsomag</CustomHeader>
        <div style={{ width: "95%" }}>
          <Grid>
            <Grid.Column width={8}>
              <div>
                <label>Hűségidő</label>
              </div>
              <Dropdown
                disabled={!OfferStore.offer.EditMode}
                selection
                value={OfferStore.selectedItem?.DurationId}
                options={OfferStore.durationOptions}
                onChange={(event, value) => {
                  OfferStore.handleHusegidoIdChange(event, value)
                }}
              />
            </Grid.Column>
          </Grid>
          <Confirm open={OfferStore.showZeroDurationPopup}
            closeOnEscape={false} //disables closing the window by pressing ESC	
            closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
            onCancel={(event) => { OfferStore.cancelZeroDurationPopup(); }}
            cancelButton="Mégse"
            confirmButton={false}
            content={
              <div style={{ padding: 30 }}>
                <p>
                  Kérlek tájékoztasd az Ügyfelet, hogy határozatlan idejű szerződés esetén a SIM kártyát postai úton fogja megkapni, amennyiben itt adja le a rendelést. Ha a SIM kártyát szeretné azonnal megkapni, akkor fáradjon be egy Vodafone üzletbe.
                </p>
                <Button color='green'>
                  <a style={{ color: 'black' }} href="https://www.vodafone.hu/shop/tarifak/mobil/red">Rendelés leadása</a>
                </Button>
              </div>
            }
          ></Confirm>
          <div style={{ width: "80%", marginTop: "15px", marginLeft: "auto", marginRight: "auto" }}>
            <Table compact definition celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Tarifacsomag neve</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Havidíj {`${OfferStore.selectedItem?.DurationId === 1 ? '(12 hó)' : '(24 hó)'}`}</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Havidíjkedvezmény</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Havidíjkedvezmény időtartama</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Tarifa utáni készülékkedvezmény</Table.HeaderCell>

                </Table.Row>
              </Table.Header>

              <Table.Body>
                {OfferStore.tariffPackageItems.map((item, index) => {
                  return <Table.Row key={index} active={item.Id === OfferStore.selectedItem?.PackageId} onClick={() => { OfferStore.setTarifaItemActive(index) }} style={{ cursor: 'pointer' }}>
                    <Radio checked={item.Id === OfferStore.selectedItem?.PackageId} style={{ paddingLeft: 5, paddingTop: 9, width: '20px' }} disabled={!OfferStore.offer.EditMode} />
                    <Table.Cell>{item.Name}</Table.Cell>
                    <Table.Cell textAlign="center">{item.PackagePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>
                    <Table.Cell textAlign="center" style={{ color: (OfferStore.selectedItem?.DurationId === 1 && item.MonthlyDiscountTwelve > 0) || (OfferStore.selectedItem?.DurationId === 2 && item.MonthlyDiscount > 0) ? 'red' : 'black' }}>{OfferStore.selectedItem?.DurationId === 1 ? (item.MonthlyDiscountTwelve > 0 ? "-" + item.MonthlyDiscountTwelve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft/hó" : "-") : (item.MonthlyDiscount > 0 ? "-" + item.MonthlyDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft/hó" : "-")}</Table.Cell>
                    <Table.Cell textAlign="center" style={{ color: (OfferStore.selectedItem?.DurationId === 1 && item.MonthlyDiscountDurationTwelve > 0) || (OfferStore.selectedItem?.DurationId === 2 && item.MonthlyDiscountDuration > 0) ? 'red' : 'black' }}>{OfferStore.selectedItem?.DurationId === 1 ? (item.MonthlyDiscountDurationTwelve > 0 ? item.MonthlyDiscountDurationTwelve.toString() + " hó" : "-") : (item.MonthlyDiscountDuration > 0 ? item.MonthlyDiscountDuration.toString() + " hó" : "-")}</Table.Cell>
                    <Table.Cell textAlign="center" style={{ color: (item.Id === OfferStore.selectedItem?.PackageId) ? (item.IsActive && item.IsHighestPrice ? 'green' : 'red') : 'black' }}>{item.DeviceDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
                  </Table.Row>

                })}
              </Table.Body>
            </Table>
          </div>

          <div style={{ marginTop: 24 }}>
            <Grid>
              <Grid.Column width={8}>
                <Header as="h3">Tarifacsomag részletei</Header>
                {OfferStore.selectedItem?.PackageId === 0 && (
                  <div>Nincs tarifacsomag kiválasztva.</div>
                )}
                {OfferStore.selectedItem?.PackageId !== 0 && (
                  <div>
                    <div>
                      {OfferStore.tariffPackageItems.filter(x => x.IsActivePackageItem && x.Logo).map((item) => {
                        return <Image src={`data:image/jpg;base64,${item.Logo}`} />
                      })}
                    </div>
                    <div style={{ marginLeft: 18 }}>
                      {OfferStore.tariffPackageItems.filter(x => x.IsActivePackageItem).map((item) => {
                        return <div dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                      })}
                    </div>
                  </div>
                )}
              </Grid.Column>

              <Grid.Column width={7} floated="right">
                <Header as="h3">Pre to Post</Header>
                <div>(meglévő One feltöltőkártyás előfizetés váltása)</div>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Pre to Post</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Checkbox checked={OfferStore.selectedItem?.PreToPost === 1} value={OfferStore.selectedItem?.PreToPost} disabled={!OfferStore.offer.EditMode}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('PreToPost', data.value) }} />
                      </Table.Cell>
                    </Table.Row>
                    <Confirm open={OfferStore.showPreToPostPopup} style={{ width: 'auto' }}
                      closeOnEscape={false} //disables closing the window by pressing ESC	
                      closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                      onCancel={(event) => { OfferStore.cancelPreToPostPopup(); }}
                      cancelButton="OK"
                      confirmButton={false}
                      content={
                        <div style={{ padding: 30 }}>
                          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>Pre to Post</div>
                          <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '15px' }}>Kérlek egyeztesd az Ügyféllel, hogy erre csak abban az esetben van lehetőség, ha: </div>
                          <div style={{ textAlign: 'center' }}>
                            <ul style={{ textAlign: 'left', display: 'inline-block', paddingLeft: '75px' }}>
                              <li>meglévő <span style={{ fontWeight: 'bold', color: 'red', marginBottom: '3px' }}>aktív Vodafone feltöltőkártyás</span> telefonszámról van szó (tehát nem került korábban elhordozásra, mert abban az esetben számhordozásról beszélünk!)</li>
                              <li>az <span style={{ fontWeight: 'bold', color: 'red', marginBottom: '3px' }}>adatoknak egyeznie kell</span>; azon a néven legyen a telefonszám, akit éppen beazonosítottál</li>
                              <li>amennyiben a feltöltőkártyás telefonszámon <span style={{ fontWeight: 'bold', marginBottom: '3px' }}>van felhasználható egyenleg</span>, az később <span style={{ fontWeight: 'bold', color: 'red', marginBottom: '3px' }}>jóváírásra kerül</span> az Ügyfél számláján</li>
                            </ul>
                          </div>
                        </div>
                      }
                    ></Confirm>
                    <Table.Row>
                      <Table.Cell>Jelenlegi feltöltőkártyás telefonszám</Table.Cell>
                      <Table.Cell textAlign="right" style={{ minWidth: '255px' }}>
                        <Input label="+36" size="small" placeholder="pl. (201234567)" type="number" value={OfferStore.selectedItem?.CurrentPrepaidPhoneNumberWithourPrefix} onChange={(event, data) => { OfferStore.updateOfferPackageItem('CurrentPrepaidPhoneNumberWithourPrefix', data.value) }} disabled={!(OfferStore.offer.EditMode || OfferStore.RootStore.currentUser.HoldingAdmin)}
                          error={(!OfferStore.selectedItem?.CurrentPrepaidPhoneNumber || (OfferStore.selectedItem?.CurrentPrepaidPhoneNumber && OfferStore.selectedItem?.CurrentPrepaidPhoneNumber?.length != 12)) && OfferStore.selectedItem?.PreToPost === 1 && OfferStore.isSaved && (OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingAdmin)} />
                        <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.CurrentPrepaidPhoneNumber) }}></Icon>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Header as="h3">Opciók</Header>
                <Table basic="very">
                  <Table.Body>
                    {/* <Table.Row>
                      <Table.Cell>YOU opció</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Checkbox checked={OfferStore.selectedItem?.YouOption === 1} value={OfferStore.selectedItem?.YouOption} disabled={!OfferStore.offer.EditMode}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('YouOption', data.value) }} />
                      </Table.Cell>
                    </Table.Row> */}
                    <Confirm open={OfferStore.showYouOptionPopup} style={{ width: 'auto' }}
                      closeOnEscape={false} //disables closing the window by pressing ESC	
                      closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                      onCancel={(event) => { OfferStore.cancelYouOptionPopup(); }}
                      cancelButton="OK"
                      confirmButton={false}
                      content={
                        <div style={{ padding: 30 }}>
                          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>YOU opció</div>
                          <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '15px' }}>be nem töltött 25. életévű szerződőnek / felhasználónak: </div>
                          <div style={{ textAlign: 'center' }}>
                            <ul style={{ textAlign: 'left', display: 'inline-block', paddingLeft: '75px' }}>
                              <li><span style={{ fontWeight: 'bold', color: 'red', marginBottom: '3px' }}>EGYBEFÜGGŐ</span> 30 napos, díjmentes <span style={{ fontWeight: 'bold' }}>Világ Napijegy</span></li>
                              <li><span style={{ fontWeight: 'bold', marginBottom: '3px' }}>2 GB</span> kiegészítő adatkeret belföldön és EU-ban</li>
                              <li><span style={{ fontWeight: 'bold', marginBottom: '3px' }}>díjmentes hívás</span> YOU felhasználók között belföldön és EU-ban</li>
                            </ul>
                          </div>
                        </div>
                      }
                    ></Confirm>
                    <Table.Row>
                      <Table.Cell>Családi csomag</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Checkbox checked={OfferStore.selectedItem?.Family === 1} value={OfferStore.selectedItem?.Family} disabled={!OfferStore.offer.EditMode}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('Family', data.value) }} />
                      </Table.Cell>
                    </Table.Row>
                    <Confirm open={OfferStore.showFamilyPopup}
                      closeOnEscape={false} //disables closing the window by pressing ESC	
                      closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                      onCancel={(event) => { OfferStore.cancelFamilyPopup(); }}
                      cancelButton="OK"
                      confirmButton={false}
                      content={
                        <div style={{ padding: 30 }}>
                          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}>Családi mobilcsomag – Sávos kedvezmények!</div>
                          <div style={{ textAlign: 'center' }}>
                            <div>
                              Az <b>egy ügyfélszámon</b> legalább 2 előfizetéssel rendelkező előfizetők
                              <b> kedvezményes családi mobilcsomagra</b> lesznek jogosultak az alábbi előnyökkel:

                            </div>

                            <ul style={{ textAlign: 'left', display: 'inline-block', paddingLeft: '75px' }}>
                              <li><span style={{ fontWeight: 'bold' }}>díjmentes beszélgetés </span>a családon (saját mobil előfizetéseken) belül belföldön és az EU-ban</li>
                              <li><span style={{ fontWeight: 'bold' }}>egy számla, egyszerű befizetés</span> </li>
                              <li><span style={{ fontWeight: 'bold' }}>SÁVOS KEDVEZMÉNYEK:</span> kedvezményes havidíj MINDEN tarifacsomagból
                                <ul>
                                  <li style={{ 'marginLeft': '20px', 'color': 'rgb(255,40,0' }}>14 500,- teljes havidíj felett -20%</li>
                                  <li style={{ 'marginLeft': '20px', 'color': 'rgb(255,40,0' }}>40 000,- teljes havidíj felett -30%</li>

                                </ul>
                              </li>
                            </ul>
                            <div style={{ 'marginTop': '10px' }}>
                              Amennyiben az ügyfél legalább egy mobil és egy vezetékes <span style={{ fontWeight: 'bold' }}>publikus</span> szolgáltatással (otthoni internet és TV)
                              rendelkezik egy <span style={{ fontWeight: 'bold' }}>ügyfélszámon</span>, akkor <span style={{ fontWeight: 'bold', 'color': 'rgb(255,40,0' }}>Vodafone One</span> ügyféllé válik és további ügyfélelőnyök illethetik meg.

                            </div>

                          </div>

                        </div>
                        // <div style={{ padding: 30 }}>
                        //   <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}>FAMILY opció</div>
                        //   <div style={{ textAlign: 'center' }}>
                        //     <ul style={{ textAlign: 'left', display: 'inline-block', paddingLeft: '75px' }}>
                        //       <li>zárt csoport az <span style={{ fontWeight: 'bold' }}>egy ügyfélszámon</span> legalább 2 előfizetéssel rendelkező előfizetőknek (max. 6 hang és 2 adat)</li>
                        //       <li><span style={{ fontWeight: 'bold' }}>díjmentes beszélgetés</span> a saját Family csoporton belül</li>
                        //       <li>havidíj szerint csökkenő sorrendbe rendezve az előfizetéseket az <span style={{ fontWeight: 'bold' }}>alábbi kedvezmények</span> érhetőek el:</li>
                        //     </ul>
                        //   </div>
                        //   <div style={{ marginTop: '30px' }}>
                        //     <Table color="red" inverted>
                        //       <Table.Header>
                        //         <Table.Row>
                        //           <Table.HeaderCell></Table.HeaderCell>
                        //           <Table.HeaderCell>1. SIM</Table.HeaderCell>
                        //           <Table.HeaderCell>2. SIM</Table.HeaderCell>
                        //           <Table.HeaderCell>3. SIM</Table.HeaderCell>
                        //           <Table.HeaderCell>4. SIM</Table.HeaderCell>
                        //         </Table.Row>
                        //       </Table.Header>
                        //       <Table.Body>
                        //         <Table.Row>
                        //           <Table.Cell>Go Midi</Table.Cell>
                        //           <Table.Cell>- 0 Ft</Table.Cell>
                        //           <Table.Cell>- 1 000 Ft</Table.Cell>
                        //           <Table.Cell>- 3 000 Ft</Table.Cell>
                        //           <Table.Cell>- 3 000 Ft</Table.Cell>
                        //         </Table.Row>
                        //         <Table.Row>
                        //           <Table.Cell>Go Super</Table.Cell>
                        //           <Table.Cell>- 0 Ft</Table.Cell>
                        //           <Table.Cell>- 1 000 Ft</Table.Cell>
                        //           <Table.Cell>- 4 000 Ft</Table.Cell>
                        //           <Table.Cell>- 4 000 Ft</Table.Cell>
                        //         </Table.Row>
                        //         <Table.Row>
                        //           <Table.Cell>Red Free+, Red Infinity World+</Table.Cell>
                        //           <Table.Cell>- 0 Ft</Table.Cell>
                        //           <Table.Cell>- 2 000 Ft</Table.Cell>
                        //           <Table.Cell>- 6 000 Ft</Table.Cell>
                        //           <Table.Cell>- 6 000 Ft</Table.Cell>
                        //         </Table.Row>
                        //       </Table.Body>
                        //     </Table>
                        //   </div>
                        // </div>
                      }
                    ></Confirm>

                  </Table.Body>
                </Table>
                <Header as="h3">Számhordozás</Header>

                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Számhordozás</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Checkbox checked={OfferStore.selectedItem?.NumberPortability === 1} value={OfferStore.selectedItem?.NumberPortability} disabled={!OfferStore.offer.EditMode}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('NumberPortability', data.value) }} />
                      </Table.Cell>
                    </Table.Row>
                    <Confirm open={OfferStore.showNumberPortabilityPopup} style={{ width: 'auto' }}
                      closeOnEscape={false} //disables closing the window by pressing ESC	
                      closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                      onCancel={(event) => { OfferStore.cancelNumberPortabilityPopup(); }}
                      cancelButton="OK"
                      confirmButton={false}
                      content={
                        <div style={{ padding: 30 }}>
                          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>Számhordozás</div>
                          <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '3px' }}>Számhordozási igény esetén:</div>
                          <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '3px' }}>1. jelöld a jelölőnégyzetben a igényt</div>
                          {/* <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '15px' }}>2. A szerződéskötést követően a vásárlónak is jeleznie kell ezt a <span style={{ fontWeight: 'bold', color: 'red' }}>1270-es Vodafone ügyfélszolgálati telefonszámon</span></div> */}
                          <div style={{ textAlign: 'center' }}>
                            <ul style={{ textAlign: 'left', display: 'inline-block', paddingLeft: '75px' }}>
                              <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '3px', paddingRight: '70px' }}>A számhordozás feltételei:</div>
                              <li><span style={{ fontWeight: 'bold', color: 'red' }}>azonos személyes adatok</span> szerepelnek mindkét szolgáltatónál</li>
                              <li><span style={{ fontWeight: 'bold', color: 'red' }}>aktív telefonszám</span> az Átadó szolgáltatónál (a vásárló <span style={{ fontWeight: 'bold', color: 'red' }}>NE MONDJA FEL</span> a hordozandó szám szerződését)</li>
                              <li>nincs 30 napnál régebbi, <span style={{ fontWeight: 'bold', color: 'red' }}>lejárt tartozása</span> az Átadó szolgáltatónál</li>
                              <li><span style={{ color: 'red', fontWeight: 'bold' }}>lejárt hűségnyilatkozat</span> az Átadó szolgáltatónál (ellenkező esetben KÖTBÉR fizetési kötelezettsége van az ügyfélnek!)</li>
                              <li>ha a számhordozás elindítása <span style={{ fontWeight: 'bold' }}>munkanapon 16 óra előtt</span> kerül rögzítésre, akkor <span style={{ fontWeight: 'bold', color: 'red' }}>2 munkanappal</span><span style={{ fontWeight: 'bold' }}>, 16 óra után</span> pedig <span style={{ fontWeight: 'bold', color: 'red' }}>3 munkanappal</span> később, <span style={{ fontWeight: 'bold', color: 'red' }}>20:00 és 23:59 között</span> történik meg</li>
                            </ul>
                          </div>
                        </div>
                      }
                    ></Confirm>
                    <Table.Row>
                      <Table.Cell>Hordozni kívánt telefonszám</Table.Cell>
                      <Table.Cell textAlign="right" style={{ minWidth: '255px' }}>
                        <Input label="+36" size="small" placeholder="pl. (201234567)" type="number" value={OfferStore.selectedItem?.PhoneNumberToBeCarriedWithourPrefix} onChange={(event, data) => { OfferStore.updateOfferPackageItem('PhoneNumberToBeCarriedWithourPrefix', data.value) }} disabled={!(OfferStore.offer.EditMode || OfferStore.RootStore.currentUser.HoldingAdmin)}
                          error={(!OfferStore.selectedItem?.PhoneNumberToBeCarried || (OfferStore.selectedItem?.PhoneNumberToBeCarried && OfferStore.selectedItem?.PhoneNumberToBeCarried?.length != 12)) && OfferStore.selectedItem?.NumberPortability === 1 && OfferStore.isSaved && (OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingAdmin)} />
                        <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.PhoneNumberToBeCarried) }}></Icon>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>MMCS ügyfél</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Checkbox checked={OfferStore.selectedItem?.VFCSCustomer === 1} value={OfferStore.selectedItem?.VFCSCustomer} disabled={!OfferStore.offer.EditMode}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('VFCSCustomer', data.value) }} />
                      </Table.Cell>
                    </Table.Row>

                  </Table.Body>
                </Table>
                <Header as="h3">További adatok</Header>
                <Table basic="very">
                  <Table.Body>
                    {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                      <Table.Row>
                        <Table.Cell>Új telefonszám</Table.Cell>
                        <Table.Cell textAlign="right" style={{ minWidth: '255px' }}>
                          <Input label="+36" size="small" placeholder="pl. (201234567)" type="number" value={OfferStore.selectedItem?.NewPhoneNumberWithoutPrefix} onChange={(event, data) => { OfferStore.updateOfferPackageItem('NewPhoneNumberWithoutPrefix', data.value) }} disabled={!(OfferStore.offer.VodafoneEditMode || OfferStore.RootStore.currentUser.HoldingAdmin)}
                            error={(!OfferStore.selectedItem?.NewPhoneNumber || (OfferStore.selectedItem?.NewPhoneNumber && OfferStore.selectedItem?.NewPhoneNumber?.length != 12)) && OfferStore.isSaved && OfferStore.RootStore.currentUser.VodafoneBO} />
                          <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.NewPhoneNumber) }}></Icon>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                      <Table.Row>
                        <Table.Cell>Szerződés azonosító</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Input size="small" value={OfferStore.selectedItem?.ContractIdentifier} onChange={(event, data) => { OfferStore.updateOfferPackageItem('ContractIdentifier', data.value) }} disabled={!(OfferStore.offer.VodafoneEditMode || OfferStore.RootStore.currentUser.HoldingAdmin)}
                            error={OfferStore.selectedWfStep && OfferStore.selectedWfStep.ToStatusCode === 2300 && OfferStore.isSaved && (!OfferStore.selectedItem?.ContractIdentifier || OfferStore.selectedItem?.ContractIdentifier == '') && (OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.VodafoneSupervisor)} />
                          <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.ContractIdentifier) }}></Icon>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {(OfferStore.selectedItem && (OfferStore.offer.Packages.length > 0) && (OfferStore.offer.Packages.indexOf(OfferStore.selectedItem) == OfferStore.offer.Packages.indexOf(OfferStore.offer.Packages[0]))) && (OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                      <Table.Row>
                        <Table.Cell>Előfizetői szerződésszám</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Input size="small" value={OfferStore.offer?.SubscriberContractNumber} onChange={(event, data) => { OfferStore.updateOfferItem('SubscriberContractNumber', data.value) }} disabled={!(OfferStore.offer.VodafoneEditMode || OfferStore.RootStore.currentUser.HoldingAdmin)}
                            error={OfferStore.selectedWfStep && OfferStore.selectedWfStep.ToStatusCode === 2300 && OfferStore.isSaved && (!OfferStore.offer.SubscriberContractNumber || OfferStore.offer.SubscriberContractNumber == '') && (OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.VodafoneSupervisor)} />
                          <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.offer?.SubscriberContractNumber) }}></Icon>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                      <React.Fragment>
                        {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                          <Table.Row>
                            <Table.Cell>SIM-kártya száma (szaktanácsadó) <span style={{ color: 'red', marginLeft: 2 }}>*</span></Table.Cell>
                            <Table.Cell textAlign="right">
                              <Input size="small" type="number" value={OfferStore.selectedItem?.SimNumber} onChange={(event, data) => { OfferStore.updateOfferPackageItem('SimNumber', data.value) }} disabled={!(OfferStore.offer.EditMode)}
                                // error={!!OfferStore.getValidationOfferPackageErrors.SimNumber}
                                error={(!OfferStore.selectedItem?.SimNumber || OfferStore.selectedItem?.SimNumber?.length != 20) && OfferStore.isSaved}
                              />
                              <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.SimNumber) }}></Icon>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                          <Table.Row>
                            <Table.Cell>SIM-kártya száma (Online pickup)</Table.Cell>
                            <Table.Cell textAlign="right">
                              <Input size="small" type="number" value={OfferStore.selectedItem?.SimNumberOP} onChange={(event, data) => { OfferStore.updateOfferPackageItem('SimNumberOP', data.value) }} disabled={!(OfferStore.offer.OnlinePickupEditMode || OfferStore.offer.EditMode)}
                                error={(OfferStore.selectedItem?.SimNumberOP && OfferStore.selectedItem?.SimNumber && (OfferStore.selectedItem?.SimNumberOP !== OfferStore.selectedItem?.SimNumber && OfferStore.offer.OnlinePickupEditMode)) || ((!OfferStore.selectedItem?.SimNumberOP || OfferStore.selectedItem?.SimNumberOP === '') && OfferStore.offer.OnlinePickupEditMode)} />
                              <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.SimNumberOP) }}></Icon>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {(OfferStore.offer.OnlinePickupEditMode || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin) && OfferStore.selectedItem?.SimNumberOP && OfferStore.selectedItem?.SimNumber && (OfferStore.selectedItem?.SimNumberOP !== OfferStore.selectedItem?.SimNumber) && (
                          <Table.Row>
                            <Table.Cell style={{ color: 'red' }}>A két SIM-kártya száma nem egyezik!</Table.Cell>
                            <Table.Cell><Button color="green" onClick={() => { OfferStore.openShowOverwritingPopup() }}>Felülírás</Button></Table.Cell>
                          </Table.Row>
                        )}
                        <Confirm open={OfferStore.showOverwritingPopup} style={{ width: 'auto' }}
                          closeOnEscape={false} //disables closing the window by pressing ESC	
                          closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                          onCancel={(event) => { OfferStore.cancelOverWritingPopup(); }}
                          onConfirm={(event) => { OfferStore.setSimNumberValueToSimNumberOPValue(); }}
                          cancelButton="Mégse"
                          confirmButton="Jóváhagyás"
                          content={
                            <div style={{ padding: 30 }}>
                              <div style={{ textAlign: 'center', lineHeight: '17px', maxWidth: '625px' }}>
                                <div style={{ fontWeight: 'bold', color: 'red', fontSize: '20px', marginBottom: '15px' }}>FIGYELEM!</div>
                                <div style={{ marginBottom: '15px' }}>Ezzel a művelettel megváltoztatásra kerül a vásárló részére regisztrált <span style={{ fontWeight: 'bold', color: 'red' }}>előfizetői SIM kártya száma!</span></div>
                                <div>A jóváhagyás előtt győződj meg arról, hogy a szaktanácsadó által valóban <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>tévesen került a SIM kártya száma rögzítésre</span> a rendszerben, majd ellenőrizd ismét az általad kiadásra kerülő SIM kártya számát!</div>
                                <div>A mentés előtt <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>jegyezd be a javítás tényét és okát</span>  a „Megjegyzés” rovatba, majd zárd a tranzakciót.</div>
                              </div>
                            </div>
                          }
                        ></Confirm>
                        {(OfferStore.offer.OnlinePickupEditMode || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin) && OfferStore.selectedItem?.SimNumberOP && OfferStore.selectedItem?.SimNumber && (OfferStore.selectedItem?.SimNumberOP === OfferStore.selectedItem?.SimNumber) && (
                          <div style={{ color: 'green' }}>A két SIM-kártya száma egyezik!</div>
                        )}
                      </React.Fragment>
                    )}
                    {OfferStore.selectedItem?.DurationId === 2 &&
                      <Table.Row>
                        <Table.Cell>Készülék IMEI-száma <span style={{ color: 'red', marginLeft: 2 }}>*</span></Table.Cell>
                        <Table.Cell textAlign="right">
                          <Input size="small" type="number" value={OfferStore.selectedItem?.IMEINumber} onChange={(event, data) => { OfferStore.updateOfferPackageItem('IMEINumber', data.value) }} disabled={!OfferStore.offer.EditMode}
                            error={(!OfferStore.selectedItem?.IMEINumber || OfferStore.selectedItem?.IMEINumber?.length != 15) && OfferStore.isSaved && OfferStore.selectedItem?.DurationId === 2} />
                          <Icon name='copy' style={{ cursor: 'pointer', paddingTop: '8px', paddingLeft: '10px' }} onClick={() => { navigator.clipboard.writeText(OfferStore.selectedItem?.IMEINumber) }}></Icon>
                        </Table.Cell>
                      </Table.Row>}
                    {(OfferStore.selectedItem && (OfferStore.offer.Packages.length > 0) && (OfferStore.offer.Packages.indexOf(OfferStore.selectedItem) == OfferStore.offer.Packages.indexOf(OfferStore.offer.Packages[0]))) && (
                      <Table.Row>
                        <Table.Cell>
                          <span style={{ fontWeight: 'bold', color: 'red' }}>Tájékoztatás típusa</span>
                          {OfferStore.offer.VodafoneStatus > 2200 && OfferStore.offer.InformationObligationId > 0 && (
                            <span style={{ cursor: 'pointer', marginLeft: 3 }}>
                              <Icon name="info circle" size="large" color="green" onClick={() => { this.props.OfferStore?.setShowInformationObligationPop() }}></Icon>
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Dropdown
                            placeholder="Kérem, válasszon..."
                            selection disabled={OfferStore.getInformationTypeDisabled}
                            value={OfferStore.offer.InformationObligationId}
                            options={OfferStore.informationObligationItemOptions}
                            onChange={(event, data) => { OfferStore.updateSelectedInformationObligation(data.value) }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Row>
                <Grid.Column width={14}>
                  {OfferStore.offer.VodafoneStatus == 2200 && OfferStore.offer.InformationObligationMM && OfferStore.selectedItem && (OfferStore.offer.Packages.length > 0) && (OfferStore.offer.Packages.indexOf(OfferStore.selectedItem) == OfferStore.offer.Packages.indexOf(OfferStore.offer.Packages[0])) && (
                    <React.Fragment>
                      <div style={{ display: 'flex', marginBottom: 10 }}>
                        <div style={{ marginRight: 20, paddingTop: 5 }}>
                          <span style={{ fontWeight: 'bold', color: 'red' }}>Tájékoztatás leírása</span>
                        </div>
                        <Button compact color={OfferStore.isInformationObligationMMEditable == true ? 'green' : 'yellow'} onClick={() => { OfferStore.setIsInformationObligationMMEditable() }}
                          disabled={!(OfferStore.offer.VodafoneStatus == 2200 && (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor))}
                        >
                          Szerkesztés
                        </Button>
                      </div>
                      <Editor
                        editorState={OfferStore.editorStateInformationObligationMM}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(editorState: any) => {
                          OfferStore.onEditorStateChangeInformationObligationMM(editorState);
                        }}
                        readOnly={!OfferStore.isInformationObligationMMEditable}
                      />
                    </React.Fragment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>

        {(OfferStore.selectedItem?.DurationId === 2 && OfferStore.selectedItem.PackageId > 0) && (
          <div style={{ marginTop: 30 }}>
            <CustomHeader>Készülék</CustomHeader>
            <div style={{ width: "95%" }}>
              <Grid>
                <Grid.Column width={12}>
                  {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                    <Grid>
                      <Grid.Column width={6}>
                        <div>
                          <label>Készülék keresése</label>
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Search placeholder='Keresés...' fluid className={(!OfferStore.selectedItem?.DeviceName && OfferStore.isSaved) ? "searchError" : "search"}
                            onResultSelect={(e, data) => { OfferStore.updateDevice(data.result?.item) }}
                            // categoryLayoutRenderer={<div></div>}
                            onSearchChange={(event, data) => {
                              OfferStore.selectedItem.DeviceId = null; //fvbe kivinni

                              OfferStore.onChangeQueryText(event, data)
                            }}
                            results={OfferStore.userByQueryTextDDL}
                            value={OfferStore.queryText || OfferStore.selectedItem?.DeviceName || ""} disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                            resultRenderer={(device) => {
                              return <div style={{ color: device.item.StockQuantity < 1 ? "lightgray" : "black" }}>{device.title} <Image style={{ maxHeight: '30px', maxWidth: 'fit-content' }} src={'https://assets.mmsrg.com/isr/166325/c1/-/' + device.item.PictureUrl}></Image></div>;
                            }}
                          />
                          {OfferStore.offer.MainStatus < 1200 && (
                            <div style={{ marginLeft: 10, paddingTop: 4 }}>
                              <Button color="red" compact onClick={() => OfferStore.nullSelectedItemDevice()}>X</Button>
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid>
                  )
                  }
                  <Grid>
                    <Grid.Column width={4}>
                      <div>
                        <label>Készülék</label>
                      </div>
                      <div>{OfferStore?.selectedItem?.DeviceName}</div>
                    </Grid.Column>
                    {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                      <React.Fragment>
                        <Grid.Column width={2}>
                          <div>
                            <label>Cikkszám</label>
                          </div>
                          <div>{OfferStore?.selectedItem?.DeviceArticleNumber}</div>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <div>
                            <label>Gyártó</label>
                          </div>
                          <div>{OfferStore?.selectedItem?.Manufacturer}</div>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <div>
                            <label>Készlet</label>
                          </div>
                          <div>{OfferStore?.selectedItem?.DeviceStockQuantity}</div>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <div>
                            <label style={{ display: "flex" }}>
                              Alapár
                              {OfferStore?.selectedItem?.DeviceName && !OfferStore.selectedItem.DevicePackageDiscount && (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                                <div style={{ marginLeft: "6px" }}>
                                  <Icon link name="edit outline" onClick={() => OfferStore?.openDeviceBasePriceEdit()} />
                                </div>
                              )}
                            </label>
                          </div>
                          <div>{OfferStore?.selectedItem?.Price ? OfferStore?.selectedItem?.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} Ft</div>
                          <Confirm open={OfferStore?.isDeviceBasePriceOpen}
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                            onCancel={() => { OfferStore?.setIsDeviceBasePriceOpenFalse(); }}
                            cancelButton="Bezár"
                            confirmButton={false}
                            content={
                              <div style={{ padding: 30 }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <div style={{ marginRight: '70px', height: '50px' }}>
                                    <div>
                                      <label>Készülék</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.DeviceName}
                                    </div>
                                    <div>
                                      <label>Cikkszám</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.DeviceArticleNumber}
                                    </div>
                                    <div>
                                      <label>Gyártó</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.Manufacturer}
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <label>Alapár</label>
                                    </div>
                                    {/* <Input value={OfferStore?.selectedItem?.Price} disabled={!OfferStore.offer.EditMode}
                                              type="number" onChange={(event, data) => {OfferStore?.updateOfferPackageItem('Price', data.value)}}/> */}
                                    <NumberFormat
                                      value={OfferStore?.selectedItem?.Price}
                                      thousandSeparator=" "
                                      suffix=" Ft"
                                      onValueChange={(data) => { OfferStore?.updateOfferPackageItem('Price', data.floatValue) }}
                                      disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                                      customInput={Input}
                                    />
                                  </div>
                                </div>
                              </div>
                            }
                          />
                          <Confirm open={OfferStore?.isDevicePackageDiscountOpen}
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                            onCancel={() => { OfferStore?.setIsDevicePackageDiscountFalse(); }}
                            cancelButton="Bezár"
                            confirmButton={false}
                            content={
                              <div style={{ padding: 30 }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <div style={{ marginRight: '70px', height: '50px' }}>
                                    <div>
                                      <label>Készülék</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.DeviceName}
                                    </div>
                                    <div>
                                      <label>Cikkszám</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.DeviceArticleNumber}
                                    </div>
                                    <div>
                                      <label>Gyártó</label>
                                    </div>
                                    <div>
                                      {OfferStore?.selectedItem?.Manufacturer}
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <label>Készülék kedvezményes csomag ára: </label>
                                    </div>
                                    {/* <Input value={OfferStore?.selectedItem?.Price} disabled={!OfferStore.offer.EditMode}
                                              type="number" onChange={(event, data) => {OfferStore?.updateOfferPackageItem('Price', data.value)}}/> */}
                                    <NumberFormat
                                      value={OfferStore?.selectedItem?.DevicePackageDiscount}
                                      thousandSeparator=" "
                                      suffix=" Ft"
                                      onValueChange={(data) => { OfferStore?.updateOfferPackageItem('DevicePackageDiscount', data.floatValue) }}
                                      disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                                      customInput={Input}
                                    />
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        </Grid.Column>
                        {/* <Grid.Column width={4}>
                      <div>
                        <label>Kedvezményes ár</label>
                      </div>
                      <div>{((OfferStore?.selectedItem?.Price - OfferStore?.selectedItem?.ActualDeviceDiscount) - OfferStore?.selectedItem?.PackageAdministrationFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</div>
                    </Grid.Column> */}
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid>
                    {((OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) &&
                      (
                        (OfferStore.selectedItem.ActualDeviceDiscount != null && OfferStore.selectedItem.ActualDeviceDiscount > 0) ||
                        (OfferStore.selectedItem.DevicePackageDiscount != null && OfferStore.selectedItem.DevicePackageDiscount > 0) ||
                        (OfferStore.selectedItem.PercentageDiscount != null && OfferStore.selectedItem.PercentageDiscount > 0)
                      )
                    ) && (

                        OfferStore.selectedItem.DevicePackageDiscount ?
                          <div style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>
                            <table>
                              <tr>
                                <td>
                                  {`Készülék kedvezményes csomag ára: ${OfferStore.selectedItem.DevicePackageDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft`}

                                </td>
                                <td>
                                  {OfferStore?.selectedItem?.DeviceName && (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                                    <div style={{ marginLeft: "6px" }}>
                                      <Icon link name="edit outline" onClick={() => OfferStore?.openDevicePackageDiscountEdit()} />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </table>


                          </div> :
                          OfferStore.selectedItem.PercentageDiscount ?
                            <div style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>
                              <table>
                                <tr>
                                  <td>
                                    {`Készülék utáni kedvezmény mértéke: ${(OfferStore.selectedItem.PercentageDiscount * 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} %`}

                                  </td>
                                  <td>

                                  </td>
                                </tr>
                              </table>


                            </div> :
                            <div style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>
                              {`Készülék utáni kedvezmény mértéke: ${OfferStore.selectedItem.ActualDeviceDiscount ? OfferStore.selectedItem.ActualDeviceDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} Ft`}
                            </div>





                      )}
                  </Grid>
                </Grid.Column>
                {OfferStore?.selectedItem?.DeviceName && (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                  <Grid.Column width={4}>
                    <Image style={{ maxHeight: '200px' }} src={OfferStore?.selectedItem?.PictureUrl} />
                  </Grid.Column>
                )}
              </Grid>
              {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
                <Grid>
                  <Grid.Column width={16}>
                    <div>
                      <label>Leírás</label>
                    </div>
                    <div style={{ overflow: "auto", width: "100%", height: "300px" }}>
                      <div dangerouslySetInnerHTML={{ __html: OfferStore.selectedItem.DeviceDescription }}>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
              )}
            </div>
          </div>
        )}


        {OfferStore.selectedItem?.DurationId === 2 && OfferStore.selectedItem?.PackageId !== 0 && OfferStore.selectedItem?.DeviceArticleNumber !== 0 &&
          (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) &&
          <div style={{ marginTop: 30 }} >
            <CustomHeader>Garanciakiterjesztés</CustomHeader>
            <div style={{ width: "95%" }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '82%' }}>


                    <Grid>
                      <Grid.Column width={4}>
                        <div style={{ marginBottom: 5 }}>
                          <label>Teljeskörű gyári garancia időtartama</label>
                        </div>
                        <Dropdown
                          placeholder="Kérem, válasszon..."
                          selection disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                          value={OfferStore.selectedItem?.FactoryWarrantyDuration}
                          options={OfferStore.selectedItem?.ManufacturerWarranties}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('FactoryWarrantyDuration', data.value) }}
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <div style={{ marginBottom: 5 }}>
                          <label>A vásárló él a regisztrációhoz kötött gyártói garanciakiterjesztéssel?</label>
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </div>
                        <Dropdown
                          selection disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                          value={OfferStore.selectedItem?.UseManufacturerWarrantyExtension}
                          options={OfferStore.yesNoDatasource}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('UseManufacturerWarrantyExtension', data.value) }}
                        // error={!OfferStore.selectedItem?.UseManufacturerWarrantyExtension}
                        />
                      </Grid.Column>
                      <Confirm open={OfferStore.showUseManufacturerWarrantyExtensionPopup}
                        closeOnEscape={false} //disables closing the window by pressing ESC	
                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                        onCancel={(event) => { OfferStore.cancelUseManufacturerWarrantyExtensionPopup(); }}
                        cancelButton="OK"
                        confirmButton={false}
                        content={
                          <div style={{ padding: 30 }}>
                            <p>
                              Ne felejtsd el tájékoztatni a vásárlót, hogy a regisztráció esetleges elmaradása az ő felelőssége.
                            </p>
                          </div>
                        }
                      ></Confirm>
                      <Confirm open={OfferStore.showQuantityWarning}
                        closeOnEscape={false} //disables closing the window by pressing ESC	
                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                        onCancel={(event) => { OfferStore.cancelShowQuantityPopup(); }}
                        cancelButton="OK"
                        confirmButton={false}
                        content={
                          <div style={{ padding: 30 }}>
                            <p>
                              A termék jelenleg nincsen raktáron!
                            </p>
                          </div>
                        }
                      ></Confirm>
                      {OfferStore.selectedItem?.UseManufacturerWarrantyExtension == 1 &&
                        <Grid.Column width={5}>
                          <div style={{ marginBottom: 5 }}>
                            <label>Regisztrációhoz kötött gyári garanciakiterjesztés időtartama</label>
                          </div>
                          <Dropdown
                            placeholder="Kérem, válasszon..."
                            selection disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                            value={OfferStore.selectedItem?.ManufacturerWarrantyExtensionDuration}
                            options={OfferStore.manufacturerWarrantyExtensionDurationOptions}
                            onChange={(event, data) => { OfferStore.updateOfferPackageItem('ManufacturerWarrantyExtensionDuration', data.value) }}
                          />
                        </Grid.Column>
                      }

                    </Grid>

                    {/* <Grid>
                      <Grid.Column width={4}>
                        <div style={{ marginBottom: 5 }}>
                          <label>Kijelzővédő</label>
                        </div>
                        <Dropdown
                          selection disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                          value={OfferStore.selectedItem?.HasScreenProtector}
                          options={OfferStore.yesNoDatasource}
                          onChange={(event, data) => { OfferStore.updateOfferPackageItem('HasScreenProtector', data.value) }}
                        />
                      </Grid.Column>
                      {OfferStore.selectedItem?.HasScreenProtector == 1 &&
                        <Grid.Column width={9}>
                          <div style={{ marginBottom: 5 }}>
                            <label>Kijelzővédő típusa</label>
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <Search placeholder='Keresés...' fluid className={(OfferStore.selectedItem?.HasScreenProtector && !OfferStore.selectedItem?.ScreenProtectorName && OfferStore.isSaved) ? "searchError" : "search"}
                              onResultSelect={(e, data) => { OfferStore.updateScreenProtector(data.result?.item) }}
                              onSearchChange={(event, data) => {
                                OfferStore.selectedItem.ScreenProtectorId = null;

                                OfferStore.onScreenProtectorChangeQueryText(event, data)
                              }}
                              results={OfferStore.screenProtectorByQueryTextDDL}
                              value={(OfferStore.screenProtectorQueryText || OfferStore.selectedItem?.ScreenProtectorName) || ""} disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                            />
                            {OfferStore.offer.MainStatus < 1200 && (
                              <div style={{ marginLeft: 10, paddingTop: 4 }}>
                                <Button color="red" compact onClick={() => OfferStore.nullSelectedItemScreenProtector()}>X</Button>
                              </div>
                            )}
                          </div>
                        </Grid.Column>
                      }
                    </Grid> */}
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    {/* <table>
                      <tr>
                        <td style={{ paddingRight: '14px' }}>
                          <Checkbox checked={OfferStore.selectedItem?.IsMothlyFeeGPlus === 1} value={OfferStore.selectedItem?.IsMothlyFeeGPlus} disabled={!OfferStore.offer.EditMode}
                            onChange={(event, data) => { OfferStore.updateOfferPackageItem('IsMothlyFeeGPlus', data.value) }} />

                        </td>
                        <td>
                          <span style={{ color: 'rgb(255,40,0)', fontWeight: 'bold' }}>Havidíjas G+ Smart igénylés</span>
                          <br />
                          <span>Kérlek rögzítsd külön a Garancia Plusz rendszerben!</span>
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <Icon name="info circle" size="large" color="green" onClick={() => { this.props.OfferStore?.showGPlusInfoFV() }}></Icon>

                        </td>
                      </tr>

                    </table>
                    <Confirm open={OfferStore.showGPlusInfo}
                      closeOnEscape={false} //disables closing the window by pressing ESC	
                      closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                      onCancel={(event) => { OfferStore.cancelshowGPlusInfo(); }}
                      cancelButton="OK"
                      confirmButton={false}
                      content={
                        <div style={{ padding: 30 }}>
                          Ha az ajánlat több készüléket is tartalmaz és a vásárló a készülékek
                          egy részére egyszeri díjas,  a másik részére havidíjas G+ védelmet szeretne,
                          akkor kérlek csak az igényelt egyszeri díjas G+ csomagokat válaszd ki
                          és a megjegyzésbe írd be a havidíjas G+ érintett csomag számát!
                          <br />
                          <br />
                          Például:
                          G+ Smart: 2. csomag

                        </div>

                      }
                    ></Confirm> */}
                  </td>
                </tr>
              </table>

              <div style={{ marginTop: 24 }}>
                <Table compact definition celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Garanciacsomag neve</Table.HeaderCell>
                      <Table.HeaderCell>Havidíj</Table.HeaderCell>
                      {/* {OfferStore.offer.IsLoanDemand && (
                        <Table.HeaderCell>Díj havi részlet esetén (22 hó)</Table.HeaderCell>
                      )} */}
                      <Table.HeaderCell>Cikkszám</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {OfferStore.selectedItem?.WarrantyPackageItems?.map((item, index) => {
                      return <Table.Row key={index} active={item.Id === OfferStore.selectedItem?.WarrantyPackageId} onClick={() => { OfferStore.setGaranciaItemActive(index) }} style={{ cursor: 'pointer' }}>
                        <Radio checked={item.Id === OfferStore.selectedItem?.WarrantyPackageId} style={{ paddingLeft: 14, paddingTop: 9, width: '20px' }} disabled={!OfferStore.offer.EditMode} />
                        <Table.Cell>{item.Name}</Table.Cell>
                        <Table.Cell textAlign="right">{item.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
                        {/* {OfferStore.offer.IsLoanDemand && (
                          <Table.Cell textAlign="right">{Math.round(item.Price / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>
                        )} */}
                        <Table.Cell textAlign="right">{item.PackageNumber}</Table.Cell>
                      </Table.Row>
                    })}
                  </Table.Body>
                </Table>
              </div>

            </div>
          </div>
        }
        {OfferStore.selectedItem?.DurationId === 2 &&
          (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) &&
          <div style={{ marginTop: 30 }}>
            <CustomHeader>Kiegészítők</CustomHeader>
            <div style={{ width: "95%" }}>
              <Grid>
                <Grid.Column width={5}>
                  <div style={{ marginBottom: 5 }}>
                    <label>Kiegészítő</label>
                  </div>
                  {OfferStore.showAccessorySearch && (
                    <div style={{ display: 'flex' }}>
                      <Search placeholder='Keresés...' fluid className="search"
                        onResultSelect={(e, data) => { OfferStore.updateAccessory(data.result?.item) }}
                        onSearchChange={(event, data) => {
                          OfferStore.onAccessoryChangeQueryText(event, data)
                        }}
                        results={OfferStore.accessoryByQueryTextDDL}
                        value={(OfferStore.accessoryQueryText || OfferStore.selectedAccessoryItem?.AccessoryName) || ""} disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                        resultRenderer={(accessory) => {
                          return <div>{accessory.title} <Image style={{ maxHeight: '30px', maxWidth: 'fit-content' }} src={'https://assets.mmsrg.com/isr/166325/c1/-/' + accessory.item.PictureUrl}></Image></div>;
                        }}
                      />
                      {OfferStore.offer.MainStatus < 1200 && (
                        <div style={{ marginLeft: 10, paddingTop: 4 }}>
                          <Button color="red" compact onClick={() => OfferStore.nullSelectedItemAccessory()}>X</Button>
                        </div>
                      )}
                      <Button style={{ marginLeft: '10px' }} color="green" onClick={() => { OfferStore.addSelectedAccessoryToSelectedItemAccessories() }} disabled={!OfferStore.offer.EditMode || !OfferStore.selectedAccessoryItem?.AccessoryName || OfferStore.offer.MainStatus > 1100}>Hozzáadás</Button>
                    </div>
                  )}

                </Grid.Column>
                <Grid.Row style={{ display: 'block' }}>
                  <div style={{ marginTop: 30, fontSize: 20, color: 'red', marginLeft: 20 }}>
                    Hozzáadott kiegészítők
                  </div>
                  <div>
                    {/* <div id="grid" className="ag-theme-alpine" style={{width: '1002px', height: '519px', marginTop: 10, marginLeft: 20}}>
                        <AgGridReact											
                            rowData={toJS(OfferStore.selectedItem.Accessories)}											
                            columnDefs={OfferStore.accessoriesColumnDef}
                            localeText={AgGridText}
                            frameworkComponents={OfferStore.offer.MainStatus < 1200 ? { btnCellRenderer: DeleteButton } : null}
                            pagination={true}
                            paginationPageSize={10}
                            gridOptions={{
                              paginationNumberFormatter: (params:any) => {
                              return  params.value.toLocaleString() ;
                            },
                          }}>																				
                        </AgGridReact>
                    </div>  */}
                    <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
                      <Grid>
                        <Grid.Column width={16}>
                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Megnevezés</Table.HeaderCell>
                                <Table.HeaderCell>Cikkszám</Table.HeaderCell>
                                <Table.HeaderCell>Ár</Table.HeaderCell>
                                {(OfferStore.offer.MainStatus === 1000 || OfferStore.offer.MainStatus === 1100) && (
                                  <Table.HeaderCell>Eltávolítás</Table.HeaderCell>
                                )}
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {OfferStore.selectedItem?.Accessories?.map((item, index) => {
                                return <Table.Row key={index}>
                                  <Table.Cell>{item.AccessoryName} {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.IsSeniorConsultant) ? <Icon link name="edit outline" style={{ marginLeft: 10 }} onClick={() => OfferStore?.openAccessoryPriceEdit(index)} /> : null} </Table.Cell>
                                  <Table.Cell>{item.AccessoryArticleNumber}</Table.Cell>
                                  <Table.Cell>{item.AccessoryPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
                                  {(OfferStore.offer.MainStatus === 1000 || OfferStore.offer.MainStatus === 1100) && (
                                    <Table.Cell onClick={() => { OfferStore.deleteAccessoryItemById(item.AccessoryId) }}><Icon name="trash" style={{ cursor: 'pointer', color: '#df0000' }}></Icon></Table.Cell>
                                  )}
                                </Table.Row>
                              })}
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      </Grid>
                      <Confirm open={OfferStore?.showAccessoryPriceEdit}
                        closeOnEscape={false} //disables closing the window by pressing ESC	
                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                        onCancel={() => { OfferStore?.setAccessoryPriceOpenFalse(); }}
                        cancelButton="Bezár"
                        confirmButton={false}
                        content={
                          <div style={{ padding: 30 }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                              <div style={{ marginRight: '70px', height: '50px' }}>
                                <div>
                                  <label>Megnevezés</label>
                                </div>
                                <div>
                                  {OfferStore?.selectedItem?.Accessories?.find(x => x.IsActive)?.AccessoryName}
                                </div>
                                <div>
                                  <label>Cikkszám</label>
                                </div>
                                <div>
                                  {OfferStore?.selectedItem?.Accessories?.find(x => x.IsActive)?.AccessoryArticleNumber}
                                </div>
                              </div>
                              <div>
                                <div>
                                  <label>Ár</label>
                                </div>
                                <NumberFormat
                                  value={OfferStore?.selectedItem?.Accessories?.find(x => x.IsActive)?.AccessoryPrice}
                                  thousandSeparator=" "
                                  suffix=" Ft"
                                  onValueChange={(data) => { OfferStore?.updateSelectedItemActiveAccessoryItemPrice(data.floatValue) }}
                                  disabled={!OfferStore.offer.EditMode || OfferStore.offer.MainStatus > 1100}
                                  customInput={Input}
                                />
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        }

        <div style={{ marginTop: 30 }}>
          <CustomHeader>Megjegyzés</CustomHeader>
          <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid>
              <Grid.Column width={16}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ width: '100px' }} textAlign="center">Dátum</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '150px' }} textAlign="center">Rögzítő neve</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '100px' }} textAlign="center">Főstátuszkód</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '161px' }} textAlign="center">Szolgáltatói státuszkód</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '175px' }} textAlign="center">Bank státuszkód</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '140px' }} textAlign="center">G+ státuszkód</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Megjegyzés</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {OfferStore.offer.Comments?.map((item, index) => {
                      return <Table.Row key={index}>
                        <Table.Cell>{item.DateString}</Table.Cell>
                        <Table.Cell textAlign="center">{item.UserName}</Table.Cell>
                        <Table.Cell textAlign="center">{item.MainStatus}</Table.Cell>
                        <Table.Cell textAlign="center">{item.VodafoneStatus}</Table.Cell>
                        <Table.Cell textAlign="center">{item.BPBankStatus}</Table.Cell>
                        <Table.Cell textAlign="center">{item.AEGONStatus}</Table.Cell>
                        <Table.Cell>{item.Comment}</Table.Cell>
                      </Table.Row>
                    })}
                  </Table.Body>
                </Table>
                <div>
                  <TextArea onChange={(event, data) => { OfferStore.setCommentString(event, data) }} value={OfferStore.offer.Comment} rows={5}
                    style={{ resize: 'none', padding: 5, width: '100%', border: '1px solid rgba(34, 36, 38, 0.15)', fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif" }}
                    className={(!!OfferStore.getValidationErrors.Comment || !!OfferStore.getValidationErrors.CommentAfterSimChange) ? "divError" : null}
                    disabled={!(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.VodafoneSupervisor || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant || OfferStore.offer.EditMode || OfferStore.offer.BPBankEdigMode || OfferStore.offer.VodafoneEditMode || OfferStore.offer.OnlinePickupEditMode)} ></TextArea>
                </div>
                {/* <div style={{display: 'flex'}}>
                  <Button color="green" style={{marginLeft: 'auto', width: '150px'}} onClick={() => {OfferStore.saveComment()}} disabled={OfferStore.commentString === null || (/^\s+$/.test(OfferStore.commentString)) || OfferStore.commentString === ""}>Rögzítés</Button>
                </div> */}
              </Grid.Column>
            </Grid>
          </div>
        </div>

        {((OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor || OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin) && OfferStore.offer.MainStatus > 1200 && OfferStore.infoVideoAnswers.length > 0) && (
          <div style={{ marginTop: 30 }}>
            <CustomHeader>Infovideó nyilatkozatok és válaszok</CustomHeader>
            <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid>
                <Grid.Column width={16}>
                  <Table style={{ width: '40%' }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nyilatkozat</Table.HeaderCell>
                        <Table.HeaderCell>Válasz</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {OfferStore.infoVideoAnswers.map((item, index) => {
                        return <Table.Row key={index}>
                          <Table.Cell>{`${item.Step}. ${item.StepName}`}</Table.Cell>
                          <Table.Cell>{item.Answer === 1 ? <Icon name='check circle outline' color='green'></Icon> : <Icon name='times circle outline' color='red'></Icon>}</Table.Cell>
                        </Table.Row>
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        )}

        <div style={{ marginTop: 30 }}>
          <CustomHeader>Napló</CustomHeader>
          <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid>
              <Grid.Column width={16}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Dátum</Table.HeaderCell>
                      <Table.HeaderCell>Módosító neve</Table.HeaderCell>
                      <Table.HeaderCell>Folyamat megnevezése</Table.HeaderCell>
                      <Table.HeaderCell>Előző státusz</Table.HeaderCell>
                      <Table.HeaderCell>Új státusz</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {OfferStore.historyItems.map((item, index) => {
                      return <Table.Row key={index}>
                        <Table.Cell>{item.DateString}</Table.Cell>
                        <Table.Cell>{item.UserName}</Table.Cell>
                        <Table.Cell>{item.WorkFLowTypeName}</Table.Cell>
                        <Table.Cell>{item.PrevStatusName}</Table.Cell>
                        <Table.Cell>{item.CurrentStatusName}</Table.Cell>
                      </Table.Row>
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <CustomHeader>Dokumentumok</CustomHeader>
          <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid>
              <Grid.Column width={16}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Név</Table.HeaderCell>
                      <Table.HeaderCell>Típus</Table.HeaderCell>
                      <Table.HeaderCell>Scrive azonosító</Table.HeaderCell>
                      <Table.HeaderCell>Művelet</Table.HeaderCell>
                      {(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (
                        <Table.HeaderCell>Eltávolítás</Table.HeaderCell>
                      )}
                      {/* <Table.HeaderCell>Előző státusz</Table.HeaderCell>
                      <Table.HeaderCell>Új státusz</Table.HeaderCell> */}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {OfferStore.documents.filter(x => x.Type == 1 || x.Type == 0 || x.Type == 6 ? (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) : true).map((item, index) => {
                      return <Table.Row key={index}>
                        <Table.Cell>
                          <a style={{ cursor: 'pointer' }} onClick={() => OfferStore.GetDocumentByObjectId(item.Id, item.Type, item.IsOfferDocument, item.IsPolicyDocument, item.PackageId, item.PackageNumber)} download>{item.Name}</a></Table.Cell>
                        <Table.Cell>{item.TypeString}</Table.Cell>
                        <Table.Cell>{item.ScriveId}</Table.Cell>
                        <Table.Cell>
                          {item.ScriveId && OfferStore.offer.MainStatus > 1400 && <Button color="orange" onClick={() => { OfferStore.getsignedDocument(item.Id) }}>Aláírt dokumentum letöltése</Button>
                          }
                          {!item.ScriveId && OfferStore.offer.MainStatus == 1500 && (item.Type > 1 && item.Type != 3 && item.Type != 7 &&  item.Type != 11 && item.Type != 6) &&
                            <Button color="orange" onClick={() => { OfferStore.sendDocumentToSigning(item.Id) }}>Aláírásra küldés</Button>
                          }
                        </Table.Cell>
                        {item.Type != 8 && item.Type != 11 && (OfferStore.RootStore.currentUser.HoldingAdmin || ((OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && (item.Type == 2 || item.Type == 3 || item.Type == 4 || item.Type == 7))) && (
                          <Table.Cell onClick={() => { OfferStore.RemoveOfferDocumentByTypeAndOfferId(item.Type) }}><Icon name="trash" style={{ cursor: 'pointer', color: '#df0000' }}></Icon></Table.Cell>
                        )}
                        
                        {
                        /* <Table.Cell>{item.PrevStatus}</Table.Cell>
                              <Table.Cell>{item.NewStatus}</Table.Cell> */}
                      </Table.Row>
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
              {(OfferStore.offer.VodafoneStatus === 2200 && (OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor || OfferStore.RootStore.currentUser.HoldingAdmin)) && (
                <div style={{ marginBottom: 20, marginLeft: 'auto' }}>
                  <Button color="green" onClick={() => { OfferStore.setIsUploadConfirmOpen() }}>Feltöltés</Button>
                </div>
              )}
            </Grid>
          </div>
          <Confirm open={OfferStore.isUploadConfirmOpen}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onConfirm={(event) => { OfferStore.confirmUpload(event); }}
            onCancel={(event) => { OfferStore.cancelUpload(event); }}
            cancelButton="Mégse"
            confirmButton={OfferStore.selectedDocumentTypeNumber !== 0 && OfferStore.file != null && OfferStore.isFilePdf == true ? "Feltöltés" : null}
            content={
              <div style={{ padding: '20px 16px' }}>
                <div style={{ marginBottom: 20, display: 'flex' }}>
                  <label style={{ paddingTop: 10, marginRight: 30 }}>Típus:</label>
                  <Dropdown
                    placeholder="Kérem, válasszon..."
                    selection

                    value={OfferStore.selectedDocumentTypeNumber}
                    options={OfferStore.documentTypeOptions}
                    onChange={(event, data) => { OfferStore.updateDocumentTypeNumber(data.value) }}
                  />
                </div>
                <Label as="label" basic htmlFor="upload" style={{ padding: '20px 10px' }}>
                  <Input hidden id={OfferStore.uploadIdString} onChange={(event) => { OfferStore.setFile(event) }} type="file" style={{ width: '684px' }} />
                </Label>
                {OfferStore.isFilePdf == false && (
                  <div style={{ color: 'red', fontWeight: 'bold', paddingTop: 15, paddingLeft: 5 }}>A kiválasztott fájl formátumának PDF-nek kell lennie!</div>
                )}
              </div>

            }>
          </Confirm>
        </div>
      </div>
    );
  }
}

export default inject("OfferStore")(observer(PhoneDetailts));
