import { flow, makeObservable, toJS } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import React, { Suspense } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Confirm, Dropdown, Grid, Input, Label, Message, Radio, Table } from 'semantic-ui-react';
import CartPanel from '../../components/CartPanel';
import CustomerData from '../../components/CustomerData';
import { CustomMenuItem } from '../../components/LayoutMenu';
import LayoutStep from '../../components/LayoutStep';
import { Loading } from '../../components/Loading';
import PhoneDetailts from '../../components/PhoneDetailts';
import PhoneSwitch from '../../components/PhoneSwitch';
import { Portal } from '../../components/Portal';
import withNavigation from '../../hocs/withNavigation';
import { OfferService } from '../../services/offer.service';
import { RootStore } from '../../stores/RootStore';
import { OfferStore } from './OfferStore';
import withParams from '../../hocs/withParams';
import { throws } from 'assert';
import { Offer } from '../../models/offer';
import { OfferChange } from '../../models/offerchange';
import NumberFormat from 'react-number-format';

interface IStores {
	OfferStore: OfferStore;
}

interface IProps {
	RootStore?: RootStore;
	OfferStore?: OfferStore;
	navigate?: NavigateFunction;
	params?: any;
}

class OfferPage extends React.Component<IProps> {
	private stores: IStores;
	OfferService: OfferService
	changeinterval: any;
	constructor(props: any) {
		super(props);

		this.OfferService = new OfferService();
		this.stores = { OfferStore: new OfferStore(this.props.navigate, this.props.RootStore, this.props.params.id) };

		makeObservable(this, {
			getOfferById: flow,
			getDatasourceItems: flow,
			CheckOfferChange: flow,
		})
	}

	async componentDidMount() {
		await this.stores.OfferStore.getTariffPackages();
		await this.stores.OfferStore.getTariffPackagesLnumber();
		await this.stores.OfferStore.getWarrantyPlusPackeges();
		if (this.stores.OfferStore.offerId > 0) {
			this.getOfferById()

			this.stores.OfferStore.RootStore.getStoreName();
			console.log('this.stores.OfferStore.offerId', this.stores.OfferStore.offerId)
			console.log('nem új elem: ', this.stores.OfferStore.offerId)
			// this.stores.OfferStore.getCommentsByOfferId();				
			this.stores.OfferStore.setOfferPayableAmount();
			this.stores.OfferStore.setTemporaryOfferPayableAmount();
			this.stores.OfferStore.GetDocumentsOfObject(this.stores.OfferStore.offerId);
			this.stores.OfferStore.GetWorkFlowHistoryByOfferId(this.stores.OfferStore.offerId);

		}
		else {			// uj elem
			if (this.props.RootStore.currentUser.IsNewOfferDisabledForSeniorOrConsultant) {
				this.props.navigate('/home');
			}
			if (this.stores.OfferStore.offer.Packages.length === 0) {
				this.stores.OfferStore.setNewOfferPackage();
				this.stores.OfferStore.initializeSelectedItem();
			}
			this.stores.OfferStore.offer.IsTemporaryAddressSameAsPermanent = false;
			this.stores.OfferStore.setDefaultValue();
			console.log('új elem:', this.stores.OfferStore.offerId)
		}
		await this.getDatasourceItems();

		console.log('selectedItem:', this.stores.OfferStore.selectedItem)
		console.log('packages:', this.stores.OfferStore.offer.Packages)
		console.log('packages length:', this.stores.OfferStore.offer.Packages.length)
		this.changeinterval = setInterval(async () => {
			this.CheckOfferChange();
		}, 5000);
		const addEventListener = window.addEventListener as any;
		addEventListener('beforeunload', (e: any) => {
			//e.preventDefault();
			console.log('brdformstore beforeunload');
			clearInterval(this.changeinterval);
		});
	}
	componentWillUnmount(): void {
		clearInterval(this.changeinterval);

	}
	*getOfferById() {
		this.stores.OfferStore.offer = yield this.OfferService.GetOfferById(this.stores.OfferStore.offerId);
		console.log('getOfferById lefutott')
		if (this.stores.OfferStore.offer.MainStatus == 1400) {
			this.stores.OfferStore.pickupPopUp = true;
		}
		if (this.stores.OfferStore.offer.VodafoneStatus > 2200) {
			if (this.stores.OfferStore.offer.InformationObligationId > 0) {
				this.stores.OfferStore.informationObligationItemForPopup = yield this.stores.OfferStore.AdminService.GetInformationObligationById(this.stores.OfferStore.offer.InformationObligationId);
				if (((this.stores.OfferStore.offer.MainStatus == 1500 && (this.stores.OfferStore.RootStore.currentUser.OnlinePickup || this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.StoreAdmin))
					|| (this.stores.OfferStore.offer.BPBankStatus == 3300 && (this.stores.OfferStore.RootStore.currentUser.CreditManager || this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.StoreAdmin))
					|| (this.stores.OfferStore.offer.VodafoneStatus == 2400 && this.stores.OfferStore.offer.BPBankStatus == 3100 && (this.stores.OfferStore.RootStore.currentUser.CreditManager || this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.StoreAdmin))
					|| (this.stores.OfferStore.offer.MainStatus == 1700 && (this.stores.OfferStore.RootStore.currentUser.OnlinePickup || this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.StoreAdmin))) && this.stores.OfferStore.offer.IsInformationObligationRead != true) {
					this.stores.OfferStore.showInformationObligationPop = true;
				}
			}

		}
		// this.stores.OfferStore.pickupPopUp = true;

		yield this.stores.OfferStore.initializeSelectedItem();


		if (this.stores.OfferStore.offer.PermanentAddressZipCode) {
			this.stores.OfferStore.getStreetNamesAfterAddressZipCodeSelect(true);
			var debug = 0;
		}
		if (this.stores.OfferStore.offer.TemporaryAddressZipCode) {
			this.stores.OfferStore.getStreetNamesAfterAddressZipCodeSelect(false);
			var debug = 0;
		}

		if (this.stores.OfferStore.offer.VodafoneStatus == 2300) {
			if (this.stores.OfferStore.offer.BPBankWfSteps) {
				let index = this.stores.OfferStore.offer.BPBankWfSteps.findIndex(a => a.ToStatusCode == 3200);
				if (this.stores.OfferStore.offer.BPBankWfSteps[index]) {
					this.stores.OfferStore.selectedWfStep = this.stores.OfferStore.offer.BPBankWfSteps[index];
				}
			}
		}
		if (this.stores.OfferStore.offer.DocumentCheckDate != null) {
			this.stores.OfferStore.showSuccessfulDocumentValidation = false;
			this.stores.OfferStore.DocumentDateString = new Date(this.stores.OfferStore.offer.DocumentCheckDate).toLocaleString();
		}
		if (this.stores.OfferStore.offer.DocumentCheckDate == null) {
			this.stores.OfferStore.showSuccessfulDocumentValidation = true;
		}
		if (this.stores.OfferStore.offer.MainStatus > 1200) {
			this.stores.OfferStore.GetInfoVideoAnswers(this.stores.OfferStore.offerId);
		}
		this.stores.OfferStore.getByteContent();
		this.stores.OfferStore.setPackagesNewPhoneNumberWithoutPrefix();

		if (this.stores.OfferStore.offer.VodafoneStatus === 2200 && this.stores.OfferStore.offer.IsSeniorConsultantPopupRead != true && this.stores.OfferStore.offer.Packages.some(p => p.NumberPortability === 1) &&
			(this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.StoreAdmin || this.stores.OfferStore.RootStore.currentUser.VodafoneBO || this.stores.OfferStore.RootStore.currentUser.VodafoneSupervisor)) {
			this.stores.OfferStore.showSeniorConsultantPopup = true;
		}
		this.stores.OfferStore.setInformationObligationMM();
		this.stores.OfferStore.getInformationObligationItemById();

	}

	*CheckOfferChange() {
		let offer = {
			OfferId: this.stores.OfferStore.offer.Id,
			MainStatus: this.stores.OfferStore.offer.MainStatus,
			VodafoneStatus: this.stores.OfferStore.offer.VodafoneStatus,
			BPBankStatus: this.stores.OfferStore.offer.BPBankStatus,
			AEGONStatus: this.stores.OfferStore.offer.AEGONStatus
		};
		let offerChange: OfferChange;
		offerChange = yield this.OfferService.CheckOfferChange(offer);
		if (offerChange && offerChange.StatusString != 'Nincs változás') {
			alert(offerChange.StatusString);

			this.stores.OfferStore.offer.MainStatus = offerChange.Offer.MainStatus;
			this.stores.OfferStore.offer.BPBankStatus = offerChange.Offer.BPBankStatus;
			this.stores.OfferStore.offer.AEGONStatus = offerChange.Offer.AEGONStatus;
			this.stores.OfferStore.offer.VodafoneStatus = offerChange.Offer.VodafoneStatus;
			this.stores.OfferStore.offer.MainWfSteps = offerChange.Offer.MainWfSteps;
			this.stores.OfferStore.offer.VodafoneWfSteps = offerChange.Offer.VodafoneWfSteps;
			this.stores.OfferStore.offer.BPBankWfSteps = offerChange.Offer.BPBankWfSteps;
			this.stores.OfferStore.offer.MainDecision = offerChange.Offer.MainDecision;
			this.stores.OfferStore.offer.VodafoneDecision = offerChange.Offer.VodafoneDecision;
			this.stores.OfferStore.offer.BpBankDecision = offerChange.Offer.BpBankDecision;
			this.stores.OfferStore.offer.MainStatusString = offerChange.Offer.MainStatusString;
			this.stores.OfferStore.offer.VodafoneStatusString = offerChange.Offer.VodafoneStatusString;
			this.stores.OfferStore.offer.BPBankStatusString = offerChange.Offer.BPBankStatusString;
			this.stores.OfferStore.offer.AEGONStatusString = offerChange.Offer.AEGONStatusString;

			if (offerChange.ChangeType == 1) {
				this.stores.OfferStore.offer.CustomerTitle = offerChange.Offer.CustomerTitle;
				this.stores.OfferStore.offer.FirstName = offerChange.Offer.FirstName;
				this.stores.OfferStore.offer.LastName = offerChange.Offer.LastName;
				this.stores.OfferStore.offer.BirthFirstName = offerChange.Offer.BirthFirstName;
				this.stores.OfferStore.offer.BirthLastName = offerChange.Offer.BirthLastName;
				this.stores.OfferStore.offer.GenderForVodafone = offerChange.Offer.GenderForVodafone;
				this.stores.OfferStore.offer.GenderForVodafoneString = offerChange.Offer.GenderForVodafoneString;
				this.stores.OfferStore.offer.Gender = offerChange.Offer.Gender;
				this.stores.OfferStore.offer.GenderString = offerChange.Offer.GenderString;
				this.stores.OfferStore.offer.BirthPlace = offerChange.Offer.BirthPlace;
				this.stores.OfferStore.offer.BirthDate = offerChange.Offer.BirthDate;
				this.stores.OfferStore.offer.MothersFirstName = offerChange.Offer.MothersFirstName;
				this.stores.OfferStore.offer.MothersLastName = offerChange.Offer.MothersLastName;
				this.stores.OfferStore.offer.CountryOfNationality = offerChange.Offer.CountryOfNationality;
				this.stores.OfferStore.offer.IdentityNumber = offerChange.Offer.IdentityNumber;
				this.stores.OfferStore.offer.IdentityType = offerChange.Offer.IdentityType;
				this.stores.OfferStore.offer.PermanentAddressZipCode = offerChange.Offer.PermanentAddressZipCode;
				this.stores.OfferStore.offer.PermanentAddressCity = offerChange.Offer.PermanentAddressCity;
				this.stores.OfferStore.offer.PermanentAddressName = offerChange.Offer.PermanentAddressName;
				this.stores.OfferStore.offer.PermanentAddressType = offerChange.Offer.PermanentAddressType;
				this.stores.OfferStore.offer.PermanentAddressHouseNumber = offerChange.Offer.PermanentAddressHouseNumber;
				this.stores.OfferStore.offer.PermanentAddressBuilding = offerChange.Offer.PermanentAddressBuilding;
				this.stores.OfferStore.offer.PermanentAddressStaircase = offerChange.Offer.PermanentAddressStaircase;
				this.stores.OfferStore.offer.PermanentAddressStorey = offerChange.Offer.PermanentAddressStorey;
				this.stores.OfferStore.offer.PermanentAddressDoor = offerChange.Offer.PermanentAddressDoor;
				this.stores.OfferStore.offer.TemporaryAddressZipCode = offerChange.Offer.TemporaryAddressZipCode;
				this.stores.OfferStore.offer.TemporaryAddressCity = offerChange.Offer.TemporaryAddressCity;
				this.stores.OfferStore.offer.TemporaryAddressName = offerChange.Offer.TemporaryAddressName;
				this.stores.OfferStore.offer.TemporaryAddressType = offerChange.Offer.TemporaryAddressType;
				this.stores.OfferStore.offer.TemporaryAddressHouseNumber = offerChange.Offer.TemporaryAddressHouseNumber;
				this.stores.OfferStore.offer.TemporaryAddressBuilding = offerChange.Offer.TemporaryAddressBuilding;
				this.stores.OfferStore.offer.TemporaryAddressStaircase = offerChange.Offer.TemporaryAddressStaircase;
				this.stores.OfferStore.offer.TemporaryAddressStorey = offerChange.Offer.TemporaryAddressStorey;
				this.stores.OfferStore.offer.TemporaryAddressDoor = offerChange.Offer.TemporaryAddressDoor;
				this.stores.OfferStore.offer.HRSZ = offerChange.Offer.HRSZ;
				this.stores.OfferStore.offer.TemporaryHRSZ = offerChange.Offer.TemporaryHRSZ;
				this.stores.OfferStore.offer.MobileNumberPrefix = offerChange.Offer.MobileNumberPrefix;
				this.stores.OfferStore.offer.MobileNumber = offerChange.Offer.MobileNumber;
				this.stores.OfferStore.offer.Email = offerChange.Offer.Email;
				this.stores.OfferStore.offer.SIMNumber = offerChange.Offer.SIMNumber;
				this.stores.OfferStore.offer.IMEINumber = offerChange.Offer.IMEINumber;
				this.stores.OfferStore.offer.IsHRSZ = offerChange.Offer.IsHRSZ;
				this.stores.OfferStore.offer.IsTemporaryHRSZ = offerChange.Offer.IsTemporaryHRSZ;


			} else if (offerChange.ChangeType == 2) {
				for (let i = 0; i < this.stores.OfferStore.offer.Packages.length; i++) {
					this.stores.OfferStore.offer.Packages[i].NewPhoneNumber = offerChange.Offer.Packages[i].NewPhoneNumber;
					this.stores.OfferStore.offer.Packages[i].VFCSCustomer = offerChange.Offer.Packages[i].VFCSCustomer;
				}

			} else if (offerChange.ChangeType == 3) {
				this.stores.OfferStore.offer.CustomerTitle = offerChange.Offer.CustomerTitle;
				this.stores.OfferStore.offer.FirstName = offerChange.Offer.FirstName;
				this.stores.OfferStore.offer.LastName = offerChange.Offer.LastName;
				this.stores.OfferStore.offer.BirthFirstName = offerChange.Offer.BirthFirstName;
				this.stores.OfferStore.offer.BirthLastName = offerChange.Offer.BirthLastName;
				this.stores.OfferStore.offer.GenderForVodafone = offerChange.Offer.GenderForVodafone;
				this.stores.OfferStore.offer.GenderForVodafoneString = offerChange.Offer.GenderForVodafoneString;
				this.stores.OfferStore.offer.Gender = offerChange.Offer.Gender;
				this.stores.OfferStore.offer.GenderString = offerChange.Offer.GenderString;
				this.stores.OfferStore.offer.BirthPlace = offerChange.Offer.BirthPlace;
				this.stores.OfferStore.offer.BirthDate = offerChange.Offer.BirthDate;
				this.stores.OfferStore.offer.MothersFirstName = offerChange.Offer.MothersFirstName;
				this.stores.OfferStore.offer.MothersLastName = offerChange.Offer.MothersLastName;
				this.stores.OfferStore.offer.CountryOfNationality = offerChange.Offer.CountryOfNationality;
				this.stores.OfferStore.offer.IdentityNumber = offerChange.Offer.IdentityNumber;
				this.stores.OfferStore.offer.IdentityType = offerChange.Offer.IdentityType;
				this.stores.OfferStore.offer.PermanentAddressZipCode = offerChange.Offer.PermanentAddressZipCode;
				this.stores.OfferStore.offer.PermanentAddressCity = offerChange.Offer.PermanentAddressCity;
				this.stores.OfferStore.offer.PermanentAddressName = offerChange.Offer.PermanentAddressName;
				this.stores.OfferStore.offer.PermanentAddressType = offerChange.Offer.PermanentAddressType;
				this.stores.OfferStore.offer.PermanentAddressHouseNumber = offerChange.Offer.PermanentAddressHouseNumber;
				this.stores.OfferStore.offer.PermanentAddressBuilding = offerChange.Offer.PermanentAddressBuilding;
				this.stores.OfferStore.offer.PermanentAddressStaircase = offerChange.Offer.PermanentAddressStaircase;
				this.stores.OfferStore.offer.PermanentAddressStorey = offerChange.Offer.PermanentAddressStorey;
				this.stores.OfferStore.offer.PermanentAddressDoor = offerChange.Offer.PermanentAddressDoor;
				this.stores.OfferStore.offer.TemporaryAddressZipCode = offerChange.Offer.TemporaryAddressZipCode;
				this.stores.OfferStore.offer.TemporaryAddressCity = offerChange.Offer.TemporaryAddressCity;
				this.stores.OfferStore.offer.TemporaryAddressName = offerChange.Offer.TemporaryAddressName;
				this.stores.OfferStore.offer.TemporaryAddressType = offerChange.Offer.TemporaryAddressType;
				this.stores.OfferStore.offer.TemporaryAddressHouseNumber = offerChange.Offer.TemporaryAddressHouseNumber;
				this.stores.OfferStore.offer.TemporaryAddressBuilding = offerChange.Offer.TemporaryAddressBuilding;
				this.stores.OfferStore.offer.TemporaryAddressStaircase = offerChange.Offer.TemporaryAddressStaircase;
				this.stores.OfferStore.offer.TemporaryAddressStorey = offerChange.Offer.TemporaryAddressStorey;
				this.stores.OfferStore.offer.TemporaryAddressDoor = offerChange.Offer.TemporaryAddressDoor;
				this.stores.OfferStore.offer.HRSZ = offerChange.Offer.HRSZ;
				this.stores.OfferStore.offer.TemporaryHRSZ = offerChange.Offer.TemporaryHRSZ;
				this.stores.OfferStore.offer.MobileNumberPrefix = offerChange.Offer.MobileNumberPrefix;
				this.stores.OfferStore.offer.MobileNumber = offerChange.Offer.MobileNumber;
				this.stores.OfferStore.offer.Email = offerChange.Offer.Email;
				this.stores.OfferStore.offer.SIMNumber = offerChange.Offer.SIMNumber;
				this.stores.OfferStore.offer.IMEINumber = offerChange.Offer.IMEINumber;
				this.stores.OfferStore.offer.IsHRSZ = offerChange.Offer.IsHRSZ;
				this.stores.OfferStore.offer.IsTemporaryHRSZ = offerChange.Offer.IsTemporaryHRSZ;

			}
		}

	}
	*getDatasourceItems() {
		this.stores.OfferStore.customerTitleOptions = yield this.OfferService.GetCustomerTitlesForDDL();
		this.stores.OfferStore.genderForBBOptions = yield this.OfferService.GetBBGendersForDDL();
		this.stores.OfferStore.genderForVodafoneOptions = yield this.OfferService.GetVodafoneGendersForDDL();
		this.stores.OfferStore.identityTypeOptions = yield this.OfferService.GetIdentityTypesForDDL();
		this.stores.OfferStore.durationOptions = yield this.OfferService.GetOfferDurations();
		if (this.stores.OfferStore.offer.Id === 0) {
			this.stores.OfferStore.durationOptions.unshift({ value: 3, text: "0 hónap" })
		}
		this.stores.OfferStore.manufacturerWarrantyExtensionDurationOptions = yield this.OfferService.GetManufacturerWarrantyExtensionDurations();
		this.stores.OfferStore.mobileNumberPrefixOptions = yield this.OfferService.GetMobilePhonePrefixes();
		this.stores.OfferStore.permanentDefaultAddressTypeOptions = yield this.OfferService.GetDefaultAddressTypes();
		this.stores.OfferStore.temporaryDefaultAddressTypeOptions = yield this.OfferService.GetDefaultAddressTypes();
		yield this.stores.OfferStore.setPermanentAddressType();
		yield this.stores.OfferStore.setTemporaryAddressType();
		this.stores.OfferStore.getManufacturerWarranties();
		this.stores.OfferStore.setOfferPackagesWarrantyPackages();
		this.stores.OfferStore.addressStoreyOptions = yield this.OfferService.GetAddressStoreys();
		if (this.stores.OfferStore.offer?.InformationObligationId == null) {
			this.stores.OfferStore.getInformationObligationItems();
		}
	}

	public render() {
		const { RootStore } = this.props;

		return (
			<div>
				<Provider {...this.stores}>
					{!RootStore.showFullMenu && (
						<Portal id="layout-top-menu-left">
							<div style={{ display: 'flex', minHeight: 62, maxWidth: '1386px' }}>
								<div style={{ maxWidth: '231px' }}>
									<CustomMenuItem title="Ajánlat főstátusza">
										<Label circular empty color="green"></Label>
										<span style={{ marginLeft: 6 }}>
											{/* Ajánlat folyamatban (1300) */}
											{this.stores.OfferStore.offer.MainStatusString}
										</span>
									</CustomMenuItem>
								</div>
								<div style={{ maxWidth: '231px' }}>
									<CustomMenuItem title="Ajánlatszám">{this.stores.OfferStore.offer?.OfferNumber}</CustomMenuItem>
								</div>
								<div style={{ maxWidth: '231px' }}>

									<CustomMenuItem title="Létrehozva">
										{this.stores.OfferStore.offer?.CreatedString}

									</CustomMenuItem>
								</div>
								<div style={{ maxWidth: '231px' }}>

									<CustomMenuItem title="Szolgáltatói státusz">
										<Label circular empty color="green"></Label>
										<span style={{ marginLeft: 6 }}>
											{/* Sikeres vetting (2300) */}
											{this.stores.OfferStore.offer.VodafoneStatusString}

										</span>
									</CustomMenuItem>
								</div>
								{(this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.HoldingGlobalViewer || this.stores.OfferStore.RootStore.currentUser.StoreAdmin || this.stores.OfferStore.RootStore.currentUser.CreditManager || this.stores.OfferStore.RootStore.currentUser.OnlinePickup || this.stores.OfferStore.RootStore.currentUser.IsConsultant || this.stores.OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
									<React.Fragment>
										<div style={{ maxWidth: '231px' }}>
											<CustomMenuItem title="Bank státusz">
												<Label circular empty color="green"></Label>
												<span style={{ marginLeft: 6 }}>
													{this.stores.OfferStore.offer.BPBankStatusString}
												</span>
											</CustomMenuItem>
										</div>
										<div style={{ maxWidth: '231px' }}>
											<CustomMenuItem title="G+ státusz">
												<Label circular empty color="green"></Label>
												<span style={{ marginLeft: 6 }}>
													{this.stores.OfferStore.offer.AEGONStatusString}
												</span>
											</CustomMenuItem>
										</div>
									</React.Fragment>
								)}
							</div>
						</Portal>
					)}

					<Portal id="layout-top-menu-right">
						<Button.Group>
							<Button color="yellow" onClick={() => { this.props.navigate('/home') }}>Vissza</Button>
							<Button.Or text=" " />
							<Button positive onClick={() => { this.stores.OfferStore.openSaveConfirm() }}
								disabled={this.stores.OfferStore.offer.MainStatus == 5200 || ((this.stores.OfferStore.offer.MainStatus == 1500 || this.stores.OfferStore.offer.BPBankStatus == 3300) &&
									this.stores.OfferStore.offer.IsInformationObligationRead != true && this.stores.OfferStore.offer.InformationObligationId > 0)}>Mentés</Button>
						</Button.Group>
					</Portal>

					<Confirm open={this.stores.OfferStore.showSeniorConsultantPopup}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmSeniorConsultantPoup(event); }}
						cancelButton={false}
						confirmButton="OK"
						content={
							<div style={{ padding: 30 }}>
								<div>
									Kérlek ellenőrizd, hogy az Ügyfél igényelt-e számhordozást a szerződés kötéshez! Amennyiben igen, akkor ezt adminisztráld a Vodafone Netcracker rendszerében!
								</div>
							</div>
						}
					></Confirm>

					<Confirm open={this.stores.OfferStore.showSelectStoreConfirm}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmStoreSelection(event); }}
						onCancel={(event) => { this.stores.OfferStore.cancelStoreSelection(event); }}
						cancelButton="Mégse"
						confirmButton="Folytatás"
						content={
							<div style={{ padding: 30 }}>
								<Dropdown
									selection
									value={this.stores.OfferStore.RootStore.selectedStore}
									options={this.stores.OfferStore.RootStore.stores}
									// disabled={this.stores.OfferStore.RootStore.getDisabledStoreDDL()}
									onChange={(event, data) => this.stores.OfferStore.RootStore.onchangeSelectedStore('IsLoanDemand', data.value)}
								/>
							</div>
						}
					></Confirm>

					<Confirm open={this.stores.OfferStore.bankLoanPopup}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmbankLoanPopup(event); }}
						cancelButton={false}
						confirmButton="OK"
						content={
							<div style={{ padding: 30 }}>
								<span style={{ fontWeight: 'bold' }}>Kérlek a </span>
								<span style={{ fontWeight: 'bold', color: 'red' }}>BANKI HITEL </span>
								<span style={{ fontWeight: 'bold' }}>pecséttel jelezd a nyugtán a részletfizetést!</span>
							</div>
						}
					></Confirm>
					{/* <Confirm open={this.stores.OfferStore.pickupPopUp}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmpickupPopUp(event); }}
						cancelButton={false}
						confirmButton="OK"
						content={
							<div style={{ padding: 30 }}>
								<div style={{ marginLeft: '20px', fontStyle: 'italic' }}>
									Üzenet a Media Markt felé:
								</div>
								<div style={{ marginBottom: '20px', fontSize: '21px' }}>


									<span style={{ fontWeight: 'bold', color: 'red' }}>	Kérlek tekintsd át az ajánlat részleteit!</span>
								</div>
								<div style={{ marginLeft: '20px' }}>
									✓	a partnerek részfolyamatai milyen státuszban vannak?
								</div>

								<div style={{ marginLeft: '20px', marginBottom: '20px' }}><span style={{ color: 'red' }}>✓ milyen információk találhatóak a „Megjegyzés” rovatban?</span></div>
								<div>
									Ha a megjegyzés rovatban kedvezménnyel, BA-val vagy konszolidációval kapcsolatos információt találsz,
									közöld a vásárlóval az információs sávban található, megfelelő<span style={{ fontWeight: 'bold', color: 'red' }}> kötelező tájékoztatás</span> szöveg felolvasásával,
									majd kezeld a folyamatot!
								</div>

							</div>
						}
					></Confirm> */}
					<Confirm open={this.stores.OfferStore.showInformationObligationPop}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmInformationObligationPop(); }}
						onCancel={(event) => { this.stores.OfferStore.cancelInformationObligationPop(event); }}
						confirmButton={this.stores.OfferStore.offer.IsInformationObligationRead ? false : "Tájékoztatás megtörtént"}
						cancelButton={this.stores.OfferStore.offer.IsInformationObligationRead ? "Bezárás" : false}
						content={
							<div style={{ padding: 30 }}>
								<label>Tájékoztatási kötelezettség:</label>
								<div dangerouslySetInnerHTML={{ __html: this.stores.OfferStore.offer.InformationObligationMM }} style={{ marginTop: 5, marginBottom: 10 }}></div>
								<label>MM oldali teendők:</label>
								<div dangerouslySetInnerHTML={{ __html: this.stores.OfferStore.informationObligationItemForPopup.TodosMM }} style={{ marginTop: 5 }}></div>
							</div>

						}
					></Confirm>
					<Confirm open={this.stores.OfferStore.showCancelOfferPopup}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmSaveOffer(event); }}
						onCancel={(event) => { this.stores.OfferStore.cancelOfferPopup(event); }}
						cancelButton="Mégse"
						confirmButton="Ajánlat megszakítása vásárló által"
						content={
							<div style={{ padding: 30 }}>
								<p>
									A vásárló általi ajánlat megszakítás megerősítésével az ajánlat végleg lezárul.
								</p>
								<p>
									Biztosan megszakítja az ajánlatot?
								</p>
							</div>
						}
					></Confirm>
					<Confirm open={this.stores.OfferStore.saveConfirmIsOpen}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.OfferStore.confirmSaveOffer(event); }}
						onCancel={(event) => { this.stores.OfferStore.cancelSave(event); }}
						cancelButton="Mégse"
						confirmButton={this.stores.OfferStore.selectedWfStep && !this.stores.OfferStore.gPlusconfirmOpen ? "Mentés" : false}
						content={
							<div style={{ padding: 40 }}>

								{this.stores.OfferStore.hasValidationErrors && (
									<Message style={{ maxHeight: 300, overflow: 'auto' }} negative>
										<Message.Header>Hiba a mentés közben!</Message.Header>
										<span>Validációs hibák:
											<ul style={{ marginLeft: 18 }}>{Object.values(this.stores.OfferStore.getValidationErrors).map((error) => {
												return <li key={error}>{error}</li>
											})}
											</ul>
											<ul style={{ marginLeft: 18 }}>{Object.values(this.stores.OfferStore.getValidationErrorsOfferPackage).map((error) => {
												return <li key={error}>{error}</li>
											})}
											</ul>
										</span>
									</Message>
								)}
								{/* <div style={{marginBottom: 30}}>
								<Button color='grey' onClick={() => this.stores.OfferStore.saveOffer()}>Piszkozat mentése</Button>
							</div>
							<div style={{marginBottom: 10}}>
								<Button color='green'>Ajánlat elfogadva</Button>																
							</div>				
							<div style={{marginBottom: 10}}>
								<Button color='red'>Ajánlat elutasítva</Button>
							</div>			
							<div>
								<Button color='yellow'>Ajánlat mentve későbbre</Button>
							</div>			 */}

								<table>
									<tr>
										<td>
											Fő Státusz:
										</td>
										<td>
											{this.stores.OfferStore.offer.MainWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 1)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Id === 1000 ? 'white' : item.Color, backgroundColor: item.Id === 1000 ? 'red' : 'white', marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</td>
									</tr>
									<tr>
										<td>
										Szolgáltatói státusz:
										</td>
										<td>
											{this.stores.OfferStore.offer.VodafoneWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 2)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Id === 1000 ? 'white' : item.Color, backgroundColor: item.Id === 1000 ? 'red' : 'white', marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</td>
									</tr>
									<tr>
										<td>
											Bank státusz:
										</td>
										<td>
											{this.stores.OfferStore.offer.BPBankWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 3)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Color, marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</td>
									</tr>
								</table>
							{	this.stores.OfferStore.gPlusconfirmOpen && <div style={{'border':'1px solid #dbd8d8','padding':'20px','marginTop':'30px'}}>


									<table >
										<tr>
											<td colSpan={2}>A csomagajánlatban <span style={{ 'fontWeight': 'bold', color: 'red' }}>havidíjas G+ biztosítás(ok)</span> is rögzítésre került(ek).
												Ezek rögzítéséhez kérjük használd a biztosító rendszerét, majd <span style={{ 'fontWeight': 'bold', color: 'red' }}>jelezz vissza az alábbi gombok segítségével!</span>
											</td>
										</tr>
										<tr>
											<td style={{ 'textAlign': 'right', 'paddingTop': '20px' }}>
												<Button positive color='green' onClick={() => { this.stores.OfferStore.saveGplusConfirm() }}
												>Rögzítés sikeres</Button>
											</td>
											<td style={{ 'textAlign': 'right', 'paddingTop': '20px' }}>
												<Button color="red" onClick={() => { this.stores.OfferStore.saveGplusNotConfirm() }}>Mégsem kéri</Button>
											</td>
										</tr>
									</table>
								</div>}
								{/* {this.stores.OfferStore.offer.MainWfSteps &&

									<div style={{ display: 'flex', marginBottom: '10px' }}>
										<div>
											Fő Státusz:
										</div>
										<div>
											{this.stores.OfferStore.offer.MainWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 1)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Color, marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</div>
									</div>
								}
								{this.stores.OfferStore.offer.VodafoneWfSteps &&
									<div style={{ display: 'flex', marginBottom: '10px' }}>
										<div>
											Vodafone Státusz:
										</div>
										<div>
											{this.stores.OfferStore.offer.VodafoneWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 2)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Color, marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</div>
									</div>
								}
								{this.stores.OfferStore.offer.BPBankWfSteps &&

									<div style={{ display: 'flex', marginBottom: '10px' }}>
										<div>
											MKB Bank:
										</div>
										<div>
											{this.stores.OfferStore.offer.BPBankWfSteps?.map((item, index) => {
												return <div key={item.Id} style={{ display: 'flex', marginBottom: '6px' }} onClick={() => {
													this.stores.OfferStore.setSelectedWfStep(index, 3)
												}}>
													<div>
														<Radio checked={item.Id === this.stores.OfferStore.selectedWfStep?.Id}
														/></div>

													<div style={{ color: item.Color, marginLeft: '3px' }}> {item.NameString}</div>
												</div>
											})}
										</div>
									</div>
								} */}
							</div>}
					>
					</Confirm>
					<div style={{ width: '100%', margin: 'auto', paddingTop: 10 }}>
						{/* ajanlatosszeallitasi felulet */}
						<Grid className="margin-top-0" columns={2} divided>
							<Grid.Row style={{ zIndex: 0 }}>
								<Grid.Column width={this.stores.OfferStore.leftPanelWidth} className={this.stores.OfferStore.showFullCart ? 'no-full-cart' : ''}>
									<div style={{ paddingBottom: 12 }}>
										<LayoutStep />

										{this.stores.OfferStore.activeTab === 'keszulek' && <PhoneSwitch />}
									</div>

									<Suspense fallback={<Loading />}>
										{this.stores.OfferStore.activeTab === 'keszulek' && <PhoneDetailts />}

										{this.stores.OfferStore.activeTab === 'ugyfel' && <CustomerData />}
									</Suspense>
								</Grid.Column>

								{this.stores.OfferStore.activeTab === 'keszulek' && (
									<Grid.Column width={this.stores.OfferStore.showFullCart ? 15 : 4} className={this.stores.OfferStore.showFullCart ? 'full-cart' : ''}>
										<CartPanel />
									</Grid.Column>
								)}
							</Grid.Row>

							{this.stores.OfferStore.activeTab === 'keszulek' &&
								(this.stores.OfferStore.RootStore.currentUser.HoldingAdmin || this.stores.OfferStore.RootStore.currentUser.HoldingGlobalViewer || this.stores.OfferStore.RootStore.currentUser.StoreAdmin || this.stores.OfferStore.RootStore.currentUser.CreditManager || this.stores.OfferStore.RootStore.currentUser.OnlinePickup || this.stores.OfferStore.RootStore.currentUser.IsConsultant || this.stores.OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
									<Grid.Row style={{ paddingTop: '0.5rem', zIndex: 1 }}>
										<Grid.Column width={16}>
											<div>
												<Grid className="positive-block">
													<Grid.Row columns={14} className="clean-grid">
														<Grid.Column width={4}>
															<Table celled fullwidth="true" className="height-100">
																<Table.Body verticalalign="top">
																	<Table.Row>
																		<Table.Cell className="">
																			<b>Hiteligénylés esetén finanszírozandó összesen</b>
																			<br />
																			<b>Hiteligénylés esetén finanszírozandó havonta</b>
																		</Table.Cell>

																		<Table.Cell collapsing className="positive-color" textAlign="right">
																			<b>{this.stores.OfferStore.offer.PayableAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</b>
																			<br />
																			<b>{this.stores.OfferStore.offer.PayableAmountMonthly?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</b>
																		</Table.Cell>
																	</Table.Row>
																</Table.Body>
															</Table>
														</Grid.Column>

														<Grid.Column width={2}>
															<Table celled fullwidth="true" className="height-100">
																<Table.Body verticalalign="top">
																	<Table.Row>
																		<Table.Cell className="">
																			<b>Hiteligénylés</b>
																		</Table.Cell>

																		<Table.Cell collapsing className="positive-color" textAlign="right">
																			<div>
																				<Dropdown
																					className="hiteligeny-dropdown"
																					selection
																					value={this.stores.OfferStore.offer.IsLoanDemand}
																					options={this.stores.OfferStore.hitelIgenylesItems}
																					disabled={!this.stores.OfferStore.isLoanDemandable || this.stores.OfferStore.offer.BPBankStatus === 3975 || this.stores.OfferStore.offer.BPBankStatus === 3400 || this.stores.OfferStore.offer.BPBankStatus === 3500 || this.stores.OfferStore.offer.BPBankStatus === 3600 || this.stores.OfferStore.offer.BPBankStatus === 3900 || this.stores.OfferStore.offer.BPBankStatus === 3950}
																					onChange={(event, data) => this.stores.OfferStore.updateOfferItem('IsLoanDemand', data.value)}
																				/>
																			</div>
																		</Table.Cell>
																	</Table.Row>
																</Table.Body>
															</Table>
														</Grid.Column>
														{this.stores.OfferStore.offer.IsLoanDemand &&
															<Grid.Column width={2}>
																<Table celled fullwidth="true" className="height-100">
																	<Table.Body verticalalign="top">
																		<Table.Row>
																			<Table.Cell className="">
																				<b>Önerő</b>
																			</Table.Cell>

																			<Table.Cell collapsing className="positive-color" textAlign="right">
																				<div style={{ width: 130 }}>
																					{/* <Input type="number" value={this.stores.OfferStore.offer.DownPayment} fluid label={{ basic: true, content: 'Ft' }} labelposition="right" disabled={!this.stores.OfferStore.offer.IsLoanDemand}
																					onChange={(event, data) => { this.stores.OfferStore.updateOfferItem('DownPayment', data.value) }} /> */}
																					<NumberFormat
																						value={this.stores.OfferStore.offer.DownPayment}
																						fluid
																						thousandSeparator=" "
																						suffix=" Ft"
																						onValueChange={(data) => { this.stores.OfferStore.updateOfferItem('DownPayment', data.value) }}
																						disabled={!this.stores.OfferStore.offer.IsLoanDemand || this.stores.OfferStore.offer.BPBankStatus === 3975 || this.stores.OfferStore.offer.BPBankStatus === 3400 || this.stores.OfferStore.offer.BPBankStatus === 3500 || this.stores.OfferStore.offer.BPBankStatus === 3600 || this.stores.OfferStore.offer.BPBankStatus === 3900 || this.stores.OfferStore.offer.BPBankStatus === 3950}
																						customInput={Input} />
																				</div>
																			</Table.Cell>
																		</Table.Row>
																	</Table.Body>
																</Table>
															</Grid.Column>
														}
														<Grid.Column width={2}>
															<Table celled fullwidth="true" className="height-100">
																<Table.Body verticalalign="top">
																	<Table.Row>
																		<Table.Cell className="">
																			<b>
																				Szerződéskötéskor <br /> fizetendő
																			</b>
																		</Table.Cell>
																		<Table.Cell collapsing className="positive-color" textAlign="right">
																			<b>{this.stores.OfferStore.offer.ContractAmount ? this.stores.OfferStore.offer.ContractAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} Ft</b>
																		</Table.Cell>
																	</Table.Row>
																</Table.Body>
															</Table>
														</Grid.Column>
														{this.stores.OfferStore.offer.IsLoanDemand &&
															<Grid.Column width={6}>
																<Table celled fullwidth="true" className="height-100">
																	<Table.Body verticalalign="top">
																		<Table.Row>
																			<Table.Cell className="">
																				<div style={{ textAlign: 'left' }}>
																					<b>
																						<div>Részletfizetés</div>
																					</b>
																				</div>
																			</Table.Cell>
																			<Table.Cell className="">
																				<div style={{ textAlign: 'right', width: '80px' }}>
																					<b>
																						1 - 22. hó:
																					</b>
																				</div>
																			</Table.Cell>
																			<Table.Cell collapsing className="positive-color" textAlign="right">
																				<b>{this.stores.OfferStore.offer.Instalment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</b>
																			</Table.Cell>
																			<Table.Cell className="">
																				<b>
																					Banki Tranzakciószám/ <br />Cenzúraszám
																				</b>
																			</Table.Cell>
																			<Table.Cell collapsing className="positive-color" textAlign="right">
																				<div style={{ width: 130 }}>
																					{/* <Input type="number" value={this.stores.OfferStore.offer.DownPayment} fluid label={{ basic: true, content: 'Ft' }} labelposition="right" disabled={!this.stores.OfferStore.offer.IsLoanDemand}
																					onChange={(event, data) => { this.stores.OfferStore.updateOfferItem('DownPayment', data.value) }} /> */}
																					<Input fluid
																						disabled={!this.stores.OfferStore.offer.IsLoanDemand || this.stores.OfferStore.offer.BPBankStatus === 3975 || this.stores.OfferStore.offer.BPBankStatus === 3400 || this.stores.OfferStore.offer.BPBankStatus === 3500 || this.stores.OfferStore.offer.BPBankStatus === 3600 || this.stores.OfferStore.offer.BPBankStatus === 3900 || this.stores.OfferStore.offer.BPBankStatus === 3950}
																						value={this.stores.OfferStore.offer.MKBTransactionNumber}
																						onChange={(event, data) => { this.stores.OfferStore.updateOfferItem('MKBTransactionNumber', data.value) }}
																						error={!!this.stores.OfferStore.getValidationErrors.MKBTransactionNumber}
																					/>

																					{/* error={!!this.props.OfferStore.getValidationErrors.MKBTransactionNumber} */}
																					{/* <NumberFormat
																						value={this.stores.OfferStore.offer.MKBTransactionNumber}
																						fluid

																						onValueChange={(data) => { this.stores.OfferStore.updateOfferItem('MKBTransactionNumber', data.value) }}
																						
																						customInput={Input} /> */}
																				</div>
																			</Table.Cell>
																		</Table.Row>
																	</Table.Body>
																</Table>

															</Grid.Column>}
													</Grid.Row>
												</Grid>
											</div>
										</Grid.Column>
									</Grid.Row>
								)}
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(OfferPage))));
