import { makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';

export class VodafoneBOStore {
    ajanlatbongeszItems: Array<any>;
    ajanlatszamaFilterOptions: Array<any>;
    ajanlatallapotaItems: Array<any>;
    rogzitesidoszakaItems: Array<any>;
    columnDef: Array<ColDef>;

    constructor() {
        makeObservable(this, {
            ajanlatbongeszItems: observable,
            ajanlatszamaFilterOptions: observable,
            columnDef: observable,
            ajanlatallapotaItems: observable,
            rogzitesidoszakaItems: observable,
        })

        this.ajanlatbongeszItems = [
            {ajanlatszam: '111100', ajanlatStatusza: 'Ajánlat folyamatban (1300)', ugyfelNeve: 'Teszt Anna', created: '2022.02.02.', vStatus: 'Sikeres vetting (2300)', bbStatus: 'Bírálat folyamatban (3200)', aStatus: 'Nem igényelt'},
            {ajanlatszam: '111101', ajanlatStatusza: 'Ajánlat folyamatban (1300)', ugyfelNeve: 'Teszt Anna', created: '2022.01.22.', vStatus: 'Sikeres vetting (2300)', bbStatus: 'Bírálat folyamatban (3200)', aStatus: 'Nem igényelt'},
            {ajanlatszam: '111102', ajanlatStatusza: 'Ajánlat folyamatban (1300)', ugyfelNeve: 'Teszt Anna', created: '2022.01.12.', vStatus: 'Sikeres vetting (2300)', bbStatus: 'Bírálat folyamatban (3200)', aStatus: 'Nem igényelt'},
            {ajanlatszam: '111103', ajanlatStatusza: 'Ajánlat folyamatban (1300)', ugyfelNeve: 'Teszt Anna', created: '2022.02.22.', vStatus: 'Sikeres vetting (2300)', bbStatus: 'Bírálat folyamatban (3200)', aStatus: 'Nem igényelt'},
            {ajanlatszam: '111104', ajanlatStatusza: 'Ajánlat folyamatban (1300)', ugyfelNeve: 'Teszt Anna', created: '2022.01.22.', vStatus: 'Sikeres vetting (2300)', bbStatus: 'Bírálat folyamatban (3200)', aStatus: 'Nem igényelt'},
        ]

        this.ajanlatszamaFilterOptions = [
            {value: 0, text: 'asd0'},
            {value: 1, text: 'asd1'},
            {value: 2, text: 'asd2'},
            {value: 3, text: 'asd3'},
        ]

        this.ajanlatallapotaItems = [
            {value: 0, text: 'Folyamatban'},
            {value: 1, text: 'Későbbre mentve'},
            {value: 2, text: 'Sikeresen lezárult'},
            {value: 3, text: 'Törölve'},
            {value: 4, text: 'Mind'},
        ]

        this.rogzitesidoszakaItems = [
            {value: 0, text: '7 napon belül rögzítve'},
            {value: 1, text: '30 napon belül rögzítve'},
            {value: 2, text: '90 napon belül rögzítve'},
            {value: 3, text: '365 napon belül rögzítve'},
        ]

        this.columnDef = [
            { field: 'ajanlatszam', headerName: 'Ajánlat száma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 150,
            cellRenderer: (params) => {                    
                var link = document.createElement('a');                                            
                link.href = '#/offer/'+params.data.Id;
                link.innerText = params.value;
                link.addEventListener('click', (e) => {
                    // e.preventDefault();
                    console.log(params.data.id);
                });
            return link;
            }},
            { field: 'ajanlatStatusza', headerName: 'Ajánlat státusza', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},
            { field: 'ugyfelNeve', headerName: 'Ügyfél neve', filter: 'agSetColumnFilter', sortable: true},
            { field: 'created', headerName: 'Létrehozás dátuma', filter: 'agSetColumnFilter', sortable: true},
            { field: 'vStatus', headerName: 'Szolgáltatói státusz', filter: 'agSetColumnFilter', sortable: true},
            { field: 'bbStatus', headerName: 'Bank státusz', filter: 'agSetColumnFilter', sortable: true},
            { field: 'aStatus', headerName: 'G+ státusz', filter: 'agSetColumnFilter', sortable: true},
        ]
    }
}